import React from "react"
import {string} from "prop-types";

function loadTestimonials() {
    let url = process.env.PUBLIC_URL + '/v1/services/testimonials'
    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class Testimonials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string
        };
        this.loadTestimonials = loadTestimonials.bind(this);
    }

    componentDidMount() {
        this.loadTestimonials()
    }

    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            result = <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            result = <div>Loading...</div>;
        } else {
            result = "Sin resultados"
            if (this.state.items.data) {
                result = this.state.items.data.map((item, index) => (
                    <div key={"category-box-" + item.id}
                         className="category-box col-lg-3 col-md-6 col-sm-6 col-xs-12 icon-box-1"
                         style={{marginBottom: '0px'}}>

                        <h4 className="item-title">
                            {item.full_name}
                        </h4>

                        <span>
                            {item.text}
                        </span>

                        <div className="clearfix"></div>
                    </div>
                ))
            }
        }

        return <>
            <div className="col-12 mt-3">
                <div className="block-heading-two">
                    <h3><span><i className="fa fa-comment"></i> {
                        this.props.title ?
                            this.props.title : "Testimonios"
                    } </span></h3>
                </div>
            </div>
            {result}</>

    }
}

export default Testimonials
