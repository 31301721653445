import React from "react"
import WhatsApp from "../body/directory/whatsapp";
import Home from "../body/directory/gohome";
import PWAPrompt from 'react-ios-pwa-prompt'
import {isIOS} from "react-device-detect";

class Footer extends React.Component {
    render() {
        return (
            <div className="foot">
                <div className="container">
                    <span className="pull-left">laguiamundial.com 2023, todos los derechos reservados | <a href="/terms-conditions" target="_top">Terminos y Condiciones </a>
                        { isIOS == false ? (
                            <> |
                                <a target="_blank"  href="https://play.google.com/store/apps/details?id=com.laguiamundial.ofertas"> Descarga nuestra App para Android</a>
                                <a  target="_blank" href="https://play.google.com/store/apps/details?id=com.laguiamundial.ofertas">
                                    <img alt="disponible en google play" style={{height: "40px"}} src={"https://laguiamundial.com/img/disponible-google-play_50.webp"} />
                                </a>
                            </>
                        ) : ""}
                    </span>
                    <PWAPrompt
                        copyTitle={"Instalar App?"}
                        copyShareButtonLabel={"Pulsa el botón 'Compartir'"}
                        copyAddHomeButtonLabel={"Pulsa en 'Agregar a Inicio'"}
                        promptOnVisit={1} timesToShow={3} copyClosePrompt="Cerrar"   copyBody="Use la aplicación 'laguiamundial.com' en su celular." permanentlyHideOnDismiss={false}/>
                </div>
                <WhatsApp phone={"+593998588595"}/>
                <Home />
            </div>
        )
    }
}

export default Footer