import React, { useState }  from "react"

const qs = require('query-string');

class EmailVerification extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          error: null,
          textResult: "",
          actionTextResult: ''
      };
    }
  
    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let token = parsed["token"]
        let iconResult = "fa fa-check text-danger"
        let textResult = "Error al intentar verificar su e-mail."
        let actionTextResult= '<a href="#">reintentar</a>'

        fetch(process.env.PUBLIC_URL + "/v1/services/email-verification?token="+token)
          .then(res => {
              if (res.status == 200 || res.status == 201) {
                  iconResult = "fa fa-exclamation-triangle text-success"
                  textResult = "Verificación de e-mail satisfactoria."
                  actionTextResult = 'Para continuar inicie sesión con su nueva cuenta de usuario, <a href="/login-user">acceder aquí a mi cuenta</a>'
              }

              this.setState({
                  isLoaded: true,
                  textResult: textResult,
                  iconResult: iconResult,
                  actionTextResult: actionTextResult
              });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
                isLoaded: true,
                textResult: error.toString(),
                iconResult: "fa fa-exclamation-triangle text-danger"
            });
          }
        )
    }
  
    render() {
      const { error, isLoaded, textResult, iconResult, actionTextResult } = this.state;
      if (error) {
        return <div>Error al intentar veificar el e-mail</div>;
      } else if (!isLoaded) {
        return <div>Cargando...</div>;
      } else {
        return (
            <div className="container">
       
            <div className="row mb-5">
              <div className="col-lg-12 text-center">
                <h1 className="mt-5">Verificación de e-mail</h1>
              </div>
            </div>
            <div className="row">
                <div className="col-lg">
                    <h3><i className={iconResult} aria-hidden="true"></i> {textResult}</h3>
                    <div className="post__content" dangerouslySetInnerHTML={{__html: actionTextResult}}></div>
                </div>
            </div>
          </div>
        );
      }
    }
  }

export default EmailVerification