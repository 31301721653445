import React from "react"
import {string} from "prop-types";
import Truncate from 'react-truncate';

const qs = require('query-string');

function loadOffersVisited() {
    let url = process.env.PUBLIC_URL + '/v1/services/user/offers/'

    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class OffersVisited extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
        };

        this.loadOffersVisited = loadOffersVisited.bind(this);
    }

    componentDidMount() {
        this.loadOffersVisited()
    }

    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;

        } else if (!isLoaded) {
            return <div>Loading...</div>;

        } else {
            let result = ""
            if (this.state.items.data) {
                result = this.state.items.data.map(item => (
                    <div key={"offer-box-" + item.id} className="prise-box col-lg-2 col-md-3 col-sm-4 col-xs-6 m-2"
                         style={{padding: '5px'}}>
                        <a href={"offer-detail?id=" + item.id}>
                            <div className={"offer-image-container vertical-center"}>
                                <ImageThumbnail value={item}/>
                            </div>

                            <div className="info-this">
                                <div className={"offer-text-container"}>
                                    <div className={"offer-text-content"}>
                                        <div className="left-box" style={{width: !item.save_up ? "100%" : "80%"}}>
                                            <h5 style={{width: !item.save_up ? "100%" : "80%"}}>
                                                {item.name}
                                            </h5>

                                            <div className="text-secondary text-truncate"><i
                                                className="fa fa-tag mr-1"/>
                                                <Truncate lines={1}>
                                                    {item.category_name}
                                                </Truncate>
                                            </div>
                                            <div className="text-secondary text-truncate"><i
                                                className="fas fa-building mr-1"/>
                                                <Truncate lines={1}>
                                                    {item.enterprise_name}
                                                </Truncate>
                                            </div>
                                            <div className="text-secondary text-truncate"><i
                                                className="fa fa-city mr-1"/>
                                                <Truncate lines={1}>
                                                    {item.city_name}
                                                </Truncate>
                                            </div>

                                            <Price value={item.price} currency={item.currency} />
                                        </div>
                                        <SaveUp value={item.save_up}/>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>))
            }

            return <>
                {(this.state.items.data) && (
                    <>
                        <div className="col-12">
                            <div className="block-heading-two">
                                <h3><span><i className="fas fa-history"></i> Ofertas Visitadas</span></h3>
                            </div>
                        </div>
                        {result}
                    </>
                )}
            </>
        }
    }
}

function SaveUp(props) {
    const data = props;
    return (
        data.value ? (
            <div className="right-box-up">
                <div className="blue-box">
                    <span>Ahorro</span>
                    <h2>{data.value}</h2>
                </div>
            </div>
        ) : ""
    )
}

function Price(props) {
    const data = props;
    var priceFormated = ""
    if (data.value){
        if (Math.round(data.value)*100!=data.value*100){
            priceFormated = data.value.toFixed(2);
        }else{
            priceFormated = data.value
        }
    }
    return (
        priceFormated ? (
            <h3 style={{textAlign: "center"}}>{data.currency} {priceFormated}</h3>
        ) : ""
    )
}

function ImageThumbnail(props) {
    const data = props;
    return (
        data.value.image_1_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_1_thumbnail} alt={data.value.image_1_thumbnail}/>
        ) : data.value.image_2_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_2_thumbnail} alt={data.value.image_2_thumbnail}/>
        ) : data.value.image_3_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_3_thumbnail} alt={data.value.image_3_thumbnail}/>
        ) : data.value.image_4_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_4_thumbnail} alt={data.value.image_4_thumbnail}/>
        ) : data.value.image_5_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_5_thumbnail} alt={data.value.image_5_thumbnail}/>
        ) : data.value.image_6_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_6_thumbnail} alt={data.value.image_6_thumbnail}/>
        ) : ""
    )
}

export default OffersVisited