import React, {useState} from "react"
import Carousel from 'react-bootstrap/Carousel'
import {string} from "prop-types"
import GoogleMapReact from 'google-map-react'
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Mailto from 'react-protected-mailto'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Offers from './offers'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import YouTube from '@u-wave/react-youtube';

const qs = require('query-string');

class OfferDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            offerId: null,
            offerName: null,

            showQualificationModal: false,
            rateSelected: 0,
            qualificationComment: "",
            qualificationCommentTitle: "",
            userLogged: false,
            userCompany: true,
            qualificationSended: false,
            listQualifications: null,

            isFavorite: false,
            copied: false,
        };

        this.rateOnSetRate = this.rateOnSetRate.bind(this)
        this.qualificationModalShow = this.qualificationModalShow.bind(this)
        this.qualificationModalHide = this.qualificationModalHide.bind(this)
        this.handleChangeQualificationCommand = this.handleChangeQualificationCommand.bind(this)
        this.handleChangeQualificationCommandTitle = this.handleChangeQualificationCommandTitle.bind(this)
        this.sendRate = this.sendRate.bind(this)
        this.pressSendComment = this.pressSendComment.bind(this)
        this.loadQualifications = this.loadQualifications.bind(this)
        this.favoriteEvent = this.favoriteEvent.bind(this)
        this.getFavorite = this.getFavorite.bind(this)
        this.sendFavorite = this.sendFavorite.bind(this)
    }

    favoriteEvent() {
        this.sendFavorite()
    }

    rateOnSetRate({rating}) {
        this.setState({rateSelected: rating}, () => this.sendRate(true));

    }

    pressSendComment() {
        this.sendRate(false)
        this.setState({qualificationSended: true});
    }

    sendRate(showModalShow) {
        let offerId = this.state.offerId
        let rateSelected = this.state.rateSelected
        let qualificationComment = this.state.qualificationComment
        let qualificationCommentTitle = this.state.qualificationCommentTitle
        fetch(process.env.PUBLIC_URL + '/v1/services/qualification', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify({
                "rate": rateSelected,
                "entity_id": offerId * 1,
                "type": "ofr",
                "comment": qualificationComment,
                "comment_title": qualificationCommentTitle
            }, null, 4),
        }).then(response => {
            if (response.status < 400 && showModalShow) {
                this.qualificationModalShow(true, false)
            }
            return
        })
    }

    qualificationModalShow(showQualificationModal, qualificationSended) {
        this.setState({qualificationSended: qualificationSended});
        this.setState({showQualificationModal: showQualificationModal});
    }

    qualificationModalHide() {
        this.qualificationModalShow(false, true)
    }

    handleChangeQualificationCommand(event) {
        let fleldVal = event.target.value;
        this.setState({qualificationComment: fleldVal})
    }

    handleChangeQualificationCommandTitle(event) {
        let fleldVal = event.target.value;
        this.setState({qualificationCommentTitle: fleldVal})
    }

    sendFavorite() {
        let offerId = this.state.offerId
        let offerName = this.state.items.name

        fetch(process.env.PUBLIC_URL + '/v1/services/favorite', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify({
                "entity_id": offerId * 1,
                "type": "ofr",
                "name": offerName,
            }, null, 4),
        })
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                return responseData;
            })
            .then(data => {
                this.setState(
                    {"isFavorite": data.data[0].state == 1},
                );
            })
            .catch(err => {
                console.log("fetch error" + err);
            });
    }

    getFavorite(offerId) {
        fetch(process.env.PUBLIC_URL + `/v1/services/favorite?entity_id=${offerId}&type=ofr`)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                return responseData;
            })
            .then(data => {
                this.setState(
                    {"isFavorite": data.data[0].state == 1},
                );
            })
            .catch(err => {
                console.log("fetch error" + err);
            });
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let offer = parsed["id"]
        this.setState({offerId: offer})
        this.loadQualifications(offer)
        this.getFavorite(offer)
        fetch(process.env.PUBLIC_URL + `/v1/services/offer-detail?offer=${offer}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(process.env.PUBLIC_URL + '/v1/services/check-user', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                return responseData;
            })
            .then(data => {
                this.setState(
                    {"userLogged": data.is_logged == true, "userCompany": data.is_company == true},
                );
            })
            .catch(err => {
                console.log("fetch error" + err);
            });


    }

    loadQualifications(offerid) {
        let url = process.env.PUBLIC_URL + '/v1/services/qualification?offerid=' + offerid
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        listQualifications: result
                    });
                }
            )
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm text-center">
                        <CarouselOfferDetail offerDetail={this.state.items}/>
                    </div>
                    <div className="col-sm">
                        <OfferDetailData offerDetail={this.state.items} offerID={this.state.offerId}/>


                    </div>
                    <div className="row w-100 100vh" style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                        <div className="col-sm">
                            <GoogleMap __enterprise_map_lat={this.state.items.enterprise_map_lat}
                                       __enterprise_map_lng={this.state.items.enterprise_map_lng}/>
                        </div>
                    </div>
                </div>

                {this.state.userLogged == true && this.state.userCompany == false ? (
                    <>

                        <div className="row w-100 100vh"
                             style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                            <div className="col-sm">
                                <p className={"rateSet"}>
                                    <div className="float-right mr-2">
                                        <div className={"float-right mr-5"}>

                                            {this.state.isFavorite == false ? (
                                                    <>Agregar a mis favoritos:
                                                        <button onClick={this.favoriteEvent} type="button"
                                                                className="btn btn-light btn-sm ml-1">
                                                            <span className="far fa-heart"></span>
                                                        </button></>
                                                ) :
                                                <>Quitar de mis favoritos:
                                                    <button onClick={this.favoriteEvent} type="button"
                                                            className="btn btn-light btn-sm ml-1">
                                                        <span className="fas fa-heart"></span>
                                                    </button></>
                                            }

                                        </div>
                                        <div className={"float-right mr-5"}>Calificar Oferta:
                                            <Rater onRate={this.rateOnSetRate} rating={0} total={5} interactive={true}/>
                                        </div>
                                        <Modal show={this.state.showQualificationModal}
                                               onHide={this.qualificationModalHide}
                                               aria-labelledby="contained-modal-title-vcenter"
                                               dialogClassName={"modal-sm"}>
                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <h6>Calificación</h6>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="show-grid">
                                                {this.state.qualificationSended == false ? (
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-sm text-center rateSet-big">
                                                                    <div className={"react-rater"}>
                                                                        <Rater onRate={this.rateOnSetRate}
                                                                               rating={this.state.rateSelected} total={5}
                                                                               interactive={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm text-center">
                                                                    {this.state.rateSelected == 1 ? (
                                                                            <div>
                                                                                Califcación muy pobre
                                                                            </div>
                                                                        ) :
                                                                        this.state.rateSelected == 2 ? (
                                                                                <div>
                                                                                    Califcación pobre
                                                                                </div>
                                                                            ) :
                                                                            this.state.rateSelected == 3 ? (
                                                                                    <div>
                                                                                        Califcación aceptable
                                                                                    </div>
                                                                                ) :
                                                                                this.state.rateSelected == 4 ? (
                                                                                    <div>
                                                                                        Califcación buena
                                                                                    </div>
                                                                                ) : this.state.rateSelected == 5 ? (
                                                                                    <div>
                                                                                        Excelente
                                                                                    </div>
                                                                                ) : ""

                                                                    }


                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm">
                                                                    <Form>
                                                                        Título:
                                                                        <Form.Control type="text" maxLength={50}
                                                                                      onChange={this.handleChangeQualificationCommandTitle}/>
                                                                        Comentario:
                                                                        <Form.Control as="textarea" rows={3} maxLength={100}
                                                                                      onChange={this.handleChangeQualificationCommand}/>
                                                                    </Form>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2">
                                                                <div className="col-sm text-center">
                                                                    <Button onClick={this.pressSendComment}>Enviar</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                Gracias por ser parte del servicio, valoramos su
                                                                calificación.
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </>
                ) : ""}

                <div className="container">
                    {this.state.listQualifications != null && this.state.listQualifications.data != null ?
                        (
                            <div className="row">
                                <div className="col-12">
                                    <strong>Los usuarios califican:</strong>
                                </div>
                            </div>) : ""
                    }


                    {this.state.listQualifications != null &&
                    this.state.listQualifications.data != null &&
                    this.state.listQualifications.data.map(qualification => (
                        <div className="row">
                            <div className="col-2">
                                <Rater onRate={this.rateOnSetRate} rating={qualification.rate} total={5}
                                       interactive={true}/>
                            </div>
                            <div className="col-2">
                                {getParsedDateShort(qualification.date)}
                            </div>
                            <div className="col-2">
                                {qualification.user_name}
                            </div>
                            <div className="col-2">
                                {qualification.comment_title}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row">
                    {this.state.items.category != undefined && this.state.items.city != undefined &&
                    <Offers category={this.state.items.category} city={this.state.items.city} type={3}/>}
                </div>
            </div>
        )
    }
}

export default OfferDetail

function CarouselOfferDetail(props) {
    const data = props;

    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setDirection(e.direction);
    };

    return (
        <Carousel interval={data.offerDetail.video_1 || data.offerDetail.video_2 ? 500000 : 10000} activeIndex={index}
                  direction={direction} onSelect={handleSelect} pauseOnHover={false}
                  prevLabel={"Prev"}>
            {data.offerDetail.video_1 &&
            <Carousel.Item>
                <div className="embed-responsive embed-responsive-4by3">
                    <YouTube
                        id={"video1"}
                        className={"embed-responsive-item"}
                        video={getVideoID(data.offerDetail.video_1)}
                        autoplay
                        allowFullscreen={true}
                        width="100%"
                        height="100%"
                    />
                </div>
            </Carousel.Item>
            }
            {data.offerDetail.video_2 &&
            <Carousel.Item>
                <div className="embed-responsive embed-responsive-4by3">
                    <YouTube
                        className={"embed-responsive-item"}
                        video={data.offerDetail.video_2}
                        autoplay
                        allowFullscreen={true}
                        width="100%"
                        height="100%"
                    />
                </div>
            </Carousel.Item>
            }
            {data.offerDetail.image_1 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_1} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }
            {data.offerDetail.image_2 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_2} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }
            {data.offerDetail.image_3 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_3} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }
            {data.offerDetail.image_4 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_4} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }

            {data.offerDetail.image_5 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_5} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }

            {data.offerDetail.image_6 &&
            <Carousel.Item>
                <img className="w-100 d-block" src={data.offerDetail.image_6} alt={data.offerDetail.name}/>
            </Carousel.Item>
            }
        </Carousel>
    )
}

function OfferDetailData(props)
{
    const data = props;
    return (
        <div className="info-this-detail">
            <div className="left-box w-100">
                <SaveUp value={data.offerDetail.save_up}/>
                <h4>
                    {data.offerDetail.name}
                </h4>
                <OfferDetailDecription value={data.offerDetail.description}/>

                <Price currency={data.offerDetail.currency} value={data.offerDetail.price}/>

                <PreviousPrice currency={data.offerDetail.currency} value={data.offerDetail.previous_price}/>

                <OfferDetailDataItem value={data.offerDetail.category_name} title="Categoría: " icon="fa fa-tag"/>
                <OfferDetailDataItem value={"OFR-" + data.offerDetail.id} title="Código: " icon="fas fa-barcode-alt"/>
                <Company value={data.offerDetail.enterprise_name} id={data.offerDetail.enterprise} />
                <EnterpriseDescription value={data.offerDetail.enterprise_description}/>
                <OfferDetailDataItem value={data.offerDetail.city_name} title="Ciudad: " icon="fas fa-city"/>
                <OfferDetailDataItem value={data.offerDetail.enterprise_address_street_1} title="Dirección: "
                                     icon="fa fa-map-marker"/>
                <OfferDetailDataItem value={data.offerDetail.enterprise_street_view}/>
                <Phone value={data.offerDetail.enterprise_phone} packagePremiumEnabled={data.offerDetail.package_premium_enabled}/>
                <Rate value={data.offerDetail.enterprise_rate}/>
                <Email value={data.offerDetail.enterprise_e_mail}/>
                <Web value={data.offerDetail.enterprise_web_site}/>
                <SocialNetworks facebook={data.offerDetail.enterprise_facebook}/>
                <OfferDetailDataItem value={data.offerDetail.enterprise_schedule} title="Horario: " icon="fa fa-clock"/>
                <OfferDetailDataItem value={data.offerDetail.enterprise_comments} title="Comentario: "
                                     icon="fa fa-comment"/>
                <OfferDetailDataItem value={data.offerDetail.on_line_offer}/>
                <Date value={data.offerDetail.public_from_date} title={"Publicado desde: "}/>

                <CopyLink value={process.env.PUBLIC_URL + "/offer-detail?id=" + data.offerDetail.id}/>

                <OfferDetailDataItem value={data.offerDetail.public_by_user} title="Publicado por: "/>
                <Date value={data.offerDetail.public_to_date} title="Publicado hasta: "/>
                <GetOffer value={data.offerDetail.external_reference} title="Obtener Oferta"/>
                <div className="fb-share-button"
                     data-href={process.env.PUBLIC_URL + "/offer-detail?id=" + data.offerID}
                     data-layout="button_count">
                </div>
            </div>
        </div>
    )
}

function OfferDetailDecription(props)
{
    const data = props;
    return (
        data.value ? (
            <div>{data.value}</div>
        ) : ""
    )
}

function Company(props)
{
    const data = props;
    return (
        data.value ? (
            <div><i className="fas fa-building mr-2"/><strong>Empresa</strong>
                <div className="float-right mr-2">
                    <a href={"/enterprise-detail?id="+props.id} target="_top">{props.value}</a>
                </div>
            </div>
        ) : ""
    )
}

function CopyLink(props)
{
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa-copy mr-2"/><strong>Compartir enlace</strong>
                <div className="float-right mr-2">
                    <CopyToClipboard text={props.value}>
                        <a href="#">Copiar</a>
                    </CopyToClipboard>
                </div>
            </div>
        ) : ""
    )
}

function OfferDetailDataItem(props)
{
    const data = props;
    return (
        data.value ? (
            <div><i className={data.icon + " mr-2"}/><strong>{data.title}</strong>
                <div className="float-right mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}

function getVideoID(path){
    if (path==undefined || path==null){
        return ""
    }
    var sp = path.split("v=")
    if (sp.length == 1) {
        return sp[0]
    }
    sp = sp[1].split("&")
    return sp[0]

}

function Price(props)
{
    const data = props;
    const currency = data.currency != undefined ? data.currency : "USD "
    return (

        data.value ? (
            <div className="clearfix" style={{marginTop: "30px"}}>
                <div className="float-right mr-4"><h3>{currency + data.value}</h3></div>
            </div>
        ) : ""
    )
}

function PreviousPrice(props)
{
    const data = props;
    return (
        data.value ? (
            <div className="clearfix">
                <div className="float-right mr-1">
                    <del><h6>{data.currency} {data.value}</h6></del>
                </div>
            </div>
        ) : ""
    )
}

function SaveUp(props)
{
    const data = props;
    return (
        data.value ? (
            <div className="right-box-up" style={{maxWidth: "50px"}}>
                <div className="blue-box" style={{padding: "5px", marginTop: "-14px"}}>
                    <span>Ahorro</span>
                    <h2>{data.value}</h2>
                </div>
            </div>
        ) : ""
    )
}

function GoogleMap(props)
{
    const data = props;
    const [open, setOpen] = useState(false);
    return (
        data.enterprise_map_lat && data.enterprise_map_lng ? (
            <>
                <Button
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}><i class="fas fa-map-marked-alt"></i> ver mapa</Button>
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        <div style={{height: '100vh', width: '100%'}}>
                            {data.enterprise_lat} {data.enterprise_lng}
                            {<GoogleMapReact
                                bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY}}
                                defaultCenter={{lat: data.enterprise_map_lat, lng: data.enterprise_map_lng}}
                                center={{lat: data.enterprise_map_lat, lng: data.enterprise_map_lng}}
                                defaultZoom={process.env.REACT_APP_MAPS_ZOOM}/>}
                        </div>
                    </div>
                </Collapse>


            </>
        ) : ""
    )
}

function Rate(props)
{
    const data = props;
    return (
        data.value ? (
            <p><i className="fab fa-angellist mr-2"></i><strong>Puntaje: </strong>
                <div className="float-right mr-2"><Rater rating={data.value} total={5} interactive={false}/></div>
            </p>
        ) : ""
    )
}

function Email(props)
{
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa fa-envelope mr-2"/><strong>E-mail: </strong>
                <div className="float-right mr-2"><Mailto email={data.value}
                                                          headers={{subject: 'Question from laguiamundial.com'}}/></div>
            </div>
        ) : ""
    )
}

function Phone(props) {
    const data = props;
    return (
        data.value && data.value.startsWith("+5939") && data.value.length==13 && data.packagePremiumEnabled ? (
            <div><i className="fa fa-phone mr-2"></i><strong>Teléfono:</strong>
                <div className="float-right mr-2"><a  target="_blank" href={"https://api.whatsapp.com/send?phone="+data.value+"&text=hola%20la%20laguiamundial.com"}>{data.value}</a></div>
            </div>
        ) : data.value ? (
            <div><i className="fa fa-phone mr-2"></i><strong>Teléfono:</strong>
                <div className="float-right mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}

function EnterpriseDescription(props)
{
    const data = props;
    return (
        data.value ? (
            <div>
                <div className="mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}

function Web(props)
{
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa-link mr-2"/><strong>Web: </strong>
                <div className="float-right mr-2"><a href={data.value} target="_blank">{data.value}</a></div>
            </div>
        ) : ""
    )
}

function SocialNetworks(props)
{
    const data = props;
    return (
        data.facebook ? (
            <div><i className="fa fa-users mr-2"/><strong>Redes sociales: </strong>
                <div className="float-right mr-2"><a className="facebook" target="_blank" href={data.facebook}><i
                    className="fab fa-facebook-f"></i></a></div>
            </div>
        ) : ""
    )
}

function GetOffer(props)
{
    const data = props;

    function handleGetOfferClick(e) {
        e.preventDefault();
        window.open(e.target.getAttribute('href'), "_blank")
        fetch(process.env.PUBLIC_URL + '/v1/services/metrics', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify({
                "entity_id": 10,
                "entity_type": 1
            }, null, 4),
        })
    }

    return (
        data.value ? (
            <div className="text-center w-100"><a onClick={handleGetOfferClick} href={data.value}
                                                  className="btn btn-red m-1 w-75">{data.title}</a></div>
        ) : ""
    )
}

function Date(props)
{
    const data = props;
    return (
        data.value && data.value != '0000-00-00 00:00:00' ? (
            <div>
                <div className="fa fa-calendar mr-2"/>
                <strong>{data.title}</strong>
                <div className="float-right mr-2">{getParsedDate(data.value)}</div>
            </div>
        ) : ""
    )
}

function getParsedDate(date)
{
    date = String(date).split(' ');
    var days = String(date[0]).split('-');
    var hours = String(date[1]).split(':');
    return days[2] + "-" + days[1] + "-" + days[0] + " " + hours[0] + ":" + hours[1] + ":" + hours[2]
}

function getParsedDateShort(date)
{
    date = String(date).split(' ');
    var days = String(date[0]).split('-');
    var hours = String(date[1]).split(':');
    return days[2] + "-" + days[1] + "-" + days[0]
}