import React from "react"
import TagsInputMore from "./tagsInputmore";
import CategoriesFilter from "./categories-filter";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            favorites: null,
            receiveNotifications: false,
            interestTags: null
        }

        this.getUser = this.getUser.bind(this)
        this.getFavorite = this.getFavorite.bind(this)
        this.receiveNotifications = this.receiveNotifications.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this)
        this.removeElement = this.removeElement.bind(this)
    }

    receiveNotifications(event) {
        const checked = event.target.checked;
        this.setState({"receiveNotifications": checked},
            this.saveProfile);
    }

    getFavorite() {
        var successfull = false
        fetch(process.env.PUBLIC_URL + `/v1/services/favorite`)
            .then(response => {
                if (response.status < 400) {
                    successfull = true
                }
                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                return responseData;
            })
            .then(data => {
                if (successfull == true) {
                    this.setState(
                        {"favorites": data},
                    );
                }
            })
            .catch(err => {
                console.log("fetch error" + err);
            });
    }

    getUser() {
        var successfull = false
        fetch(process.env.PUBLIC_URL + `/v1/services/user`)
            .then(response => {
                if (response.status < 400) {
                    successfull = true
                }
                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                return responseData;
            })
            .then(data => {
                if (successfull == true) {
                    this.setState(
                        {
                            "fullName": data.fullName,
                            "receiveNotifications": data.receive_interest_notification != undefined ? data.receive_interest_notification : false,
                            "interestTags": data.interest_tags != undefined ? JSON.parse(data.interest_tags) : [],
                        },
                    );
                }
            })
            .catch(err => {
                console.log("fetch error" + err);
            });
    }

    onChangeCategory(category) {
        let interestTags = this.state.interestTags

        let valX = category.value + " - " + category.label.replace("-----", "")

        if (interestTags.indexOf(valX) > -1) {
            return
        }

        interestTags.push(valX)

        this.setState({
            interestTags: interestTags
        }, this.saveProfile);


        /*this.state.category = categoryID.value
        this.setState({
            categoryNameTmp: categoryID.label.replace("-----", "").replace("Ingrese...", "")
        });*/
    }

    onAddCategory() {
        /*this.state.category = categoryID.value
        this.setState({
            categoryNameTmp: categoryID.label.replace("-----", "").replace("Ingrese...", "")
        });*/
    }

    saveProfile() {
        let fields = {}
        fields.receive_interest_notification = this.state.receiveNotifications
        fields.interest_tags = JSON.stringify(this.state.interestTags, "", 4)
        fetch(process.env.PUBLIC_URL + '/v1/services/user', {
            method: 'PUT',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify(fields, null, 4),
        })
            .then(function (response) {
                if (response.status != 200) {

                }
            })
    }

    selectedCategories = tags => {
        this.setState({
            "interestTags": tags
        }, this.saveProfile);
    };

    componentDidMount() {
        this.getFavorite()
        this.getUser()
    }

    removeElement(indexm) {
        this.setState({
            interestTags: this.state.interestTags.filter((_, index) => index !== indexm)
        }, this.saveProfile);
    }

    render() {
        return (
            <div className="container">
                <div className="row">

                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span><i className="fas fa-address-card"></i> Mis datos</span></h3>
                            <label>Nombre: </label> {this.state.fullName}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span><i className="fas fa-bells"></i> Mis suscripciones</span></h3>
                            <input
                                name="receiveNotificationsEnabled"
                                type="checkbox"
                                checked={this.state.receiveNotifications}
                                onChange={this.receiveNotifications}/> Quiero recibir notificaciones en mi correo
                            cuando su publiquen ofertas en las siguientes categorías:

                            {this.state.interestTags && (
                                <div>
                                    <TagsInputMore enableAdd={false} selectedTags={this.selectedCategories}
                                                   tags={this.state.interestTags} removeElement={this.removeElement}/>
                                </div>
                            )}

                            {this.state.interestTags && this.state.interestTags.length < 5 ? (
                                <CategoriesFilter showAll={true} selectableBase={false}
                                                  title={"Seleccione sus categorias de interés"}
                                                  onChangeCategory={this.onChangeCategory}/>
                            ) : "El lime máximo (5) de categorías de interés fue alcanzado."}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span><i className="fas fa-bells"></i> Mis ofertas favoritas</span></h3>
                            <div className="col-12">
                                {this.state.favorites && this.state.favorites.data ? (
                                    this.state.favorites.data.map(item => (
                                        <div className="row" key={"action" + item.name}>
                                            <div className="col-12">
                                                <a href={process.env.PUBLIC_URL + "/offer-detail?id=" + item.entity_id}>{item.name}, (OFR-{item.entity_id})</a>
                                            </div>
                                        </div>
                                    ))
                                ) : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Profile