import React, {useState} from "react"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Moment from 'react-moment';
import {string} from "prop-types";
import OfferEditor from './offer-editor'

class MyOffers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            counter: 1,
            domElementId: "",
            packageIdNoOffers: 1,
        };
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + `/v1/services/packages/offers`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    clickx = (domElementId) => {

        setTimeout(function () {
            document.getElementById(domElementId).click();
            setTimeout(function () {
                var rec = document.getElementById(domElementId).getBoundingClientRect();
                window.scrollTo(0, rec.top + window.scrollY);
            }, 500);
        }, 500);

    }

    render() {
        let result = ""

        if (this.state.items.data && this.state.counter > 0) {
            result =
                <Accordion defaultActiveKey={this.state.items.data.length == 1 ? "package-" + 0 : ""} className="w-100">
                    <Card className="w-100">
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="dark" className="w-100">
                                <div className="container">
                                    <div className="row bg-secondary text-white">
                                        <div className="col-3 d-block d-sm-block d-md-none d-lg-none">Paq</div>
                                        <div className="col-1 d-none d-sm-none d-md-block d-lg-block">Paquete</div>

                                        <div className="col-4 d-block d-sm-block d-md-none d-lg-none">Tipo</div>
                                        <div className="col-3 d-none d-sm-none d-md-block d-lg-block">Tipo de paquete</div>

                                        <div className="col-2 d-block d-sm-block d-md-none d-lg-none">Ofer Disp</div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Ofertas disponibles</div>

                                        <div className="col-3 d-none d-sm-none d-md-block d-lg-block">Adquirido</div>

                                        <div className="col-3 d-block d-sm-block d-md-none d-lg-none">Caduca</div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Caduca la fecha</div>

                                        <div className="col-1 d-none d-sm-none d-md-block d-lg-block">Estado</div>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>

                    {this.state.items.data.map((item, index) => (
                        <Card className="w-100" key={"car" + Math.random()}>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} variant="light" eventKey={"package-" + index}
                                                  className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-3 d-block d-sm-block d-md-none d-lg-none">PAQ-{item.id}</div>
                                            <div className="col-1 d-none d-sm-none d-md-block d-lg-block">PAQ-{item.id}</div>

                                            <div className="col-4 d-block d-sm-block d-md-none d-lg-none">
                                                <a href="#" className="alert-link">
                                                    {item.name}
                                                </a>
                                            </div>
                                            <div className="col-3 d-none d-sm-none d-md-block d-lg-block">
                                                <a href="#" className="alert-link">
                                                    {item.name}
                                                </a>
                                            </div>

                                            <div className="col-2">{item.available_offers}</div>

                                            <div className="col-3 d-none d-sm-none d-md-block d-lg-block">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.from_date}
                                                </Moment>
                                            </div>



                                            <div className="col-3 d-block d-sm-block d-md-none d-lg-none">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.to_date}
                                                </Moment>
                                            </div>

                                            <div className="col-2 d-none d-sm-none d-md-block d-lg-block">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.to_date}
                                                </Moment>
                                            </div>


                                            <div style={{width: "65px"}}
                                                 className={"col-1 d-none d-sm-none d-md-block d-lg-block " + (item.state == 1 ? "bg-success text-white" : "bg-secondary text-white")}>{item.state == 1 ? "Vigente" : "Caducado"}</div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>


                            {item.limit_offers > 0 ?

                                <Accordion.Collapse eventKey={"package-" + index}>

                                    <Card.Body>


                                        <Accordion defaultActiveKey={item.offers.length == 1 ? 0 : ""}>
                                            <Card>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Card.Header} eventKey={index + "-offer-h"}
                                                                      variant="light">
                                                        <div className="container">
                                                            <div className={"row bg-info text-white"}>
                                                                <div className="col-3 d-block d-sm-block d-md-none d-lg-none"></div>
                                                                <div className="col-3 d-none d-sm-none d-md-block d-lg-block">Oferta</div>



                                                                <div className="col-5">Nombre de la Oferta</div>

                                                                <div className="col-1 d-block d-sm-block d-md-none d-lg-none"></div>
                                                                <div className="col-1 d-none d-sm-none d-md-block d-lg-block">Editar</div>

                                                                <div className="col-3">Aprobación</div>

                                                            </div>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                            </Card>

                                            {item.offers ?
                                                item.offers.map((offer, offe) => (
                                                    <Card key={"ca" + Math.random()}>
                                                        <Card.Header>
                                                            <Accordion.Toggle id={offer.dom_element_id} as={Card.Header}
                                                                              variant="link"
                                                                              eventKey={index + "offer-" + offer.id + "-" + offer.dom_element_id}>
                                                                <div className="container">
                                                                    <div className={"row"}>
                                                                        <div className="col-3">OFR-{offer.id}
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <a href="#" className="alert-link">
                                                                                {offer.name ? offer.name : "Editar datos de esta oferta"}
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-1">
                                                                            <a href="#" className="alert-link">
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <div style={{
                                                                                width: "75px",
                                                                                textAlign: "center"
                                                                            }}
                                                                                 className={"" + (offer.require_moderation == true && offer.approved != true ? "bg-secondary text-white" : "bg-success text-white")}>{offer.require_moderation == true && offer.approved != true ? "Pendiente" : "Aprobada"}</div>
                                                                        </div>
                                                                        <div className="col-1">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse
                                                            eventKey={index + "offer-" + offer.id + "-" + offer.dom_element_id}>
                                                            <Card.Body>
                                                                <OfferEditor packageType={item.name} offerId={offer.id}
                                                                             company_package={item.id} package={item.package}/>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                )) : ""}

                                            <Card key={"car" + Math.random()}>
                                                <Card.Header key={"her" + Math.random()}>
                                                    <Accordion.Toggle as={Card.Header} variant="light"
                                                                      eventKey={index + "offer-add-" + Math.random()}>
                                                        <div className={"row text-dark"}>
                                                            <div className="col-12">
                                                                <div className="d-flex justify-content-center m-2">
                                                                    {item.available_offers > 0 && item.state == 1 ?
                                                                        <Button onClick={() => {
                                                                            var domElementId = "unq" + Math.random() * 1000000
                                                                            this.setState({
                                                                                counter: this.state.counter + 1,
                                                                                domElementId: domElementId,
                                                                            });
                                                                            item.available_offers--

                                                                            var newOffer = {
                                                                                enterprise_package: 0,
                                                                                id: "...",
                                                                                name: "...",
                                                                                public_from_date: "",
                                                                                public_to_date: "",
                                                                                dom_element_id: domElementId,
                                                                                company_package: item.id,
                                                                                published: true,
                                                                                package: item.package,
                                                                                require_moderation: true,
                                                                                approved: false,
                                                                            }
                                                                            item.offers.push(newOffer)
                                                                            {
                                                                                this.clickx(domElementId)
                                                                            }

                                                                        }} size="sm" variant="primary"><i
                                                                            className="fas fa-plus-circle"></i> Agregar Oferta</Button>
                                                                        :
                                                                        <span>Este Paquete esta completo o caducado. </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                            </Card>
                                        </Accordion>
                                    </Card.Body>


                                </Accordion.Collapse>
                                : ""}
                        </Card>
                    ))}
                </Accordion>

        }

        return <>
            <div className="col-12">
                <div className="block-heading-two">
                    <h3><span><i className="fa fa-fire"></i> Mis Ofertas</span></h3>
                </div>
            </div>
            <div className="col-12">
                {result}
            </div>
            <div className="col-12 mt-3 ">
                <div className="alert alert-warning" role="alert">
                    Para adquirir un nuevo paquete de publicación de ofertas dirígase <a
                    href={process.env.PUBLIC_URL + "/packages"}>aquí</a>
                </div>

            </div>
        </>
    }
};

export default MyOffers