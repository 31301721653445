import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import FieldsOffer from "./fields-offer.js"
import Collapse from 'react-bootstrap/Collapse'
import Alert from 'react-bootstrap/Alert'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class OfferEditor extends React.Component {
    constructor(props) {
        super(props);
        this.checkUser = this.checkUser.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.redirectToHome = this.redirectToHome.bind(this);
        this.loadData = this.loadData.bind(this);

        this.state = {
            success: false,
            successAlertOpen: false,
            successTitle: "Exito",
            successText: "Su oferta fue guardada correctamente",

            error: false,
            errorAlertOpen: false,
            errorTitle: "Error",
            errorText: "Error al intentar guardar los datos",
            checkValidation: false,
            offerId: props.offerId * 1,
            offerCompanyPackage: props.company_package,
            disabledButton: false,
            offerPackage: props.package,
        }
    }

    async checkUser() {
        await fetch(process.env.PUBLIC_URL + '/v1/services/check-user', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(async res => {
                    if (res.status != 200) {
                        this.redirectToLogin()
                    } else {
                        await this.loadData()
                        this.setState({
                            showUser: true
                        });
                    }
                },
                (error) => {
                    this.redirectToLogin()
                }
            )
    }

    redirectToLogin = () => {
        window.location.replace(process.env.PUBLIC_URL + "/login-user");
    }

    redirectToHome = () => {
        window.location.replace(process.env.PUBLIC_URL + "/");
    }

    processResult = (response) => {
        if (response.ok) {
            this.interval = setInterval(() => this.setState({successAlertOpen: false}), 14000);
            this.setState({successAlertOpen: true})

            response.text().then(async res => {
                var id = await JSON.parse(res)
                if (id * 1 == id) {
                    this.setState({offerId: id})
                }
            })

            this.setState({successAlertOpen: "Los datos de la Oferta fueron guardados correctamente."})
        } else {
            this.interval = setInterval(() => this.setState({errorAlertOpen: false}), 14000);
            this.setState({errorAlertOpen: true})
        }
        this.setState({
            disabledButton: false,
        })
        return response;
    }


    async loadData() {
        console.log("is ok...")
        if (this.state.offerId > 0) {
            await fetch(process.env.PUBLIC_URL + '/v1/services/offer/' + this.state.offerId + "?edition=1", {
                method: 'GET',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(async res => {
                        if (res.status != 200) {
                            this.redirectToLogin()
                        } else {

                            await res.text().then(async res => {
                                var resJson = await JSON.parse(res)
                                console.log("OLD: "+this.state.package)
                                console.log("new: "+resJson.package)
                                this.setState({
                                    offerId: resJson.offer_id,
                                    offerName: resJson.offer_name,
                                    offerDescription: resJson.offer_description,
                                    offerPrice: resJson.offer_price,
                                    offerPreviousPrice: resJson.offer_previous_price,
                                    offerCurrency: resJson.offer_currency,
                                    offerExternalReference: resJson.offer_external_reference,
                                    offerCategory: resJson.offer_category,
                                    offerVideo1: resJson.offer_video_1,
                                    offerImage1: resJson.offer_image_1,
                                    offerImage2: resJson.offer_image_2,
                                    offerImage3: resJson.offer_image_3,
                                    offerPackage: resJson.package,
                                    offerImage4: resJson.offer_image_4,
                                    offerImage5: resJson.offer_image_5,
                                    offerImage6: resJson.offer_image_6,
                                    offerPublished: resJson.offer_published,
                                    offerTags: JSON.parse(resJson.offer_tags),
                                })
                            })
                        }
                    },
                    (error) => {
                        this.redirectToLogin()
                    }
                )
        } else {

        }
    }

    setStateX(state) {
        this.setState(state)
    }

    checkValidation() {

    }

    async componentDidMount() {
        await this.checkUser()
    }

    render() {
        return (
            !this.state.showUser ? (<div>Loading...</div>) :
                (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        offerId: this.state.offerId,
                                        offerName: this.state.offerName,
                                        offerDescription: this.state.offerDescription,
                                        offerPrice: this.state.offerPrice,
                                        offerPreviousPrice: this.state.offerPreviousPrice,
                                        offerCurrency: this.state.offerCurrency,
                                        offerExternalReference: this.state.offerExternalReference,
                                        offerCategory: this.state.offerCategory,
                                        offerVideo1: this.state.offerVideo1,
                                        offerImage1: this.state.offerImage1,
                                        offerImage2: this.state.offerImage2,
                                        offerImage3: this.state.offerImage3,
                                        offerImage4: this.state.offerImage4,
                                        offerImage5: this.state.offerImage5,
                                        offerImage6: this.state.offerImage6,
                                        offerPublished: this.state.offerPublished,
                                        offerCompanyPackage: this.state.offerCompanyPackage,
                                        offerTags: this.state.offerTags,
                                        offerPackage: this.state.offerPackage,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        offerName: Yup.string().required('Nombre de la oferta es requerida.')
                                    })}
                                    render={({errors, touched, setFieldValue, processResult}) => (
                                        <Form encType="multipart/form-data">
                                            {this.success == true ?
                                                <div className="alert alert-success alert-dismissible fade show">
                                                    <strong>Exito</strong> Sus datos fueron guardados correctamente
                                                    <button type="button" className="close"
                                                            data-dismiss="alert">&times;</button>
                                                </div> : ""
                                            }
                                            <FieldsOffer offerPackage={this.state.offerPackage}
                                                         touched={touched} errors={errors}
                                                         setFieldValue={setFieldValue}
                                                         offerVideo1={this.state.offerVideo1}
                                                         offerImage1={this.state.offerImage1}
                                                         offerImage2={this.state.offerImage2}
                                                         offerImage3={this.state.offerImage3}
                                                         offerImage4={this.state.offerImage4}
                                                         offerImage5={this.state.offerImage5}
                                                         offerImage6={this.state.offerImage6}
                                                         offerName={this.state.offerName}
                                                         offerPublished={this.state.offerPublished}
                                                         offerCategory={this.state.offerCategory}
                                                         offerCurrency={this.state.offerCurrency}
                                                         offerDescription={this.state.offerDescription}
                                                         offerExternalReference={this.state.offerExternalReference}
                                                         offerPreviousPrice={this.state.offerPreviousPrice}
                                                         offerPrice={this.state.offerPrice}
                                                         offerCompanyPackage={this.state.offerCompanyPackage}
                                                         offerTags={this.state.offerTags}
                                                         offerId={this.state.offerId}
                                            />

                                            <div className="form-row mt-1">
                                                <div className="col m-1">
                                                    {(errors.offerName) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.offerName}
                                                        </div>
                                                    )}
                                                    {(errors.offerDescription) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerDescription}
                                                    </div>
                                                    )}
                                                    {(errors.offerPrice) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerPrice}
                                                    </div>
                                                    )}
                                                    {(errors.offerPreviousPrice) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerPreviousPrice}
                                                    </div>
                                                    )}
                                                    {(errors.offerCurrency) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerCurrency}
                                                    </div>
                                                    )}
                                                    {(errors.offerExternalReference) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerExternalReference}
                                                    </div>
                                                    )}
                                                    {(errors.offerCategory) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerCategory}
                                                    </div>
                                                    )}
                                                    {(errors.offerVideo1) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerVideo1}
                                                    </div>
                                                    )}
                                                    {(errors.offerImage1) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerImage1}
                                                    </div>
                                                    )}
                                                    {(errors.offerImage2) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerImage2}
                                                    </div>
                                                    )}
                                                    {(errors.offerPublished) && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {errors.offerPublished}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-row mt-1">
                                                <div className="col m-1 text-right">
                                                    <button disabled={this.state.disabledButton} id="actionSend" type="submit" onClick={this.checkValidation}
                                                            className="btn btn-primary">Guardar
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                    onSubmit={fields => {
                                        this.setState({
                                            disabledButton: true,
                                        })
                                        fetch(process.env.PUBLIC_URL + '/v1/services/offer', {
                                            method: this.state.offerId > 0 ? 'PUT' : 'POST',
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            },
                                            body: JSON.stringify(fields, null, 4),
                                        }).then(this.processResult)
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-12">

                                <Modal show={this.state.successAlertOpen}>
                                    <Modal.Header closeButton className={"alert-success"}>
                                        <Modal.Title>
                                            {this.state.successTitle}

                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className={"alert-success"}>
                                        <div className="alert" role="alert">
                                            {this.state.successText}.
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={() => this.setState({successAlertOpen: false})} variant="outline-success">
                                                Cerrar
                                            </Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Collapse in={this.state.errorAlertOpen}>
                                    <div>
                                        <Alert variant="danger">
                                            <Alert.Heading>{this.state.errorTitle}</Alert.Heading>
                                            <p>
                                                {this.state.errorText}
                                            </p>
                                        </Alert>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                )
        )
    }
}

export default OfferEditor