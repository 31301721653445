import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import FieldsEnterprise from "./fields-enterprise.js"
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class Enterprise extends React.Component {
    constructor(props) {
        super(props);
        this.checkUser = this.checkUser.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.redirectToHome = this.redirectToHome.bind(this);

        this.loadData = this.loadData.bind(this);
        this.setStateX = this.setStateX.bind(this);
        this.processResult = this.processResult.bind(this);
        this.checkValidation = this.checkValidation.bind(this);

        this.state = {
            showUser: false,
            city: 0,
            category: 0,
            success: false,
            successAlertOpen: false,
            successTitle: "Exito",
            successText: "Los datos fueron guardados correctamente",

            error: false,
            errorAlertOpen: false,
            errorTitle: "Error",
            errorText: "Error al intentar guardar los datos. Por favor verifique el número de CI / RUC y demás datos ingresados.",
            checkValidation: false,
            packagePremiumEnabled: false,
        }
    }

    async checkUser() {
        await fetch(process.env.PUBLIC_URL + '/v1/services/check-user', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(async res => {
                    if (res.status != 200) {
                        this.redirectToLogin()
                    } else {
                        await this.loadData()
                        this.setState({
                            showUser: true
                        });
                    }
                },
                (error) => {
                    this.redirectToLogin()
                }
            )
    }

    redirectToLogin = () => {
        window.location.replace(process.env.PUBLIC_URL + "/login-user");
    }

    redirectToHome = () => {
        window.location.replace(process.env.PUBLIC_URL + "/");
    }

    processResult = (response) => {
        if (response.ok) {
            this.interval = setInterval(() => this.setState({successAlertOpen: false}), 14000);
            this.setState({successAlertOpen: true})
        } else {
            this.interval = setInterval(() => this.setState({errorAlertOpen: false}), 14000);
            this.setState({errorAlertOpen: true})
            response.text().then(async res => {
                var text = await JSON.parse(res)
                this.setState({errorText: text})
            })
        }
        return response;

    }


    async loadData() {
        await fetch(process.env.PUBLIC_URL + '/v1/services/user/enterprise', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(async res => {
                    if (res.status != 200) {
                        this.redirectToLogin()
                    } else {

                        await res.text().then(async res => {
                            var resJson = await JSON.parse(res)
                            this.setState({
                                id: resJson.id,
                                showUser: true,
                                name: resJson.name,
                                slug: resJson.slug,
                                ruc: resJson.ruc,
                                category: resJson.category,
                                address: resJson.address,
                                city: resJson.city,
                                postal_code: resJson.postal_code,
                                video_1: resJson.video_1,
                                image_1: resJson.image_1,
                                e_mail: resJson.e_mail,
                                phone: resJson.phone,
                                phone2: resJson.phone2,
                                web_site: resJson.web_site,
                                facebook: resJson.facebook,
                                schedule: resJson.schedule,
                                description: resJson.description,
                                map_lng: resJson.map_lng,
                                map_lat: resJson.map_lat,
                                require_moderation: resJson.require_moderation,
                                approved: resJson.approved,
                                tags: resJson.tags,
                                packagePremiumEnabled: resJson.package_premium_enabled,
                            })

                        })
                    }
                },
                (error) => {
                    this.redirectToLogin()
                }
            )
    }

    setStateX(state) {
        this.setState(state)
    }

    checkValidation() {

    }

    async componentDidMount() {
        await this.checkUser()
    }

    render() {

        return (
            !this.state.showUser ? (<div>Loading...</div>) :
                (
                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <Modal show={this.state.successAlertOpen}>
                                    <Modal.Header closeButton className={"alert-success"}>
                                        <Modal.Title>
                                                {this.state.successTitle}

                                            </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className={"alert-success"}>
                                        <div className="alert" role="alert">
                                            {this.state.successText}. <a href="/my-offers">Editar mis Ofertas</a>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={() => this.setState({successAlertOpen: false})} variant="outline-success">
                                                Cerrar
                                            </Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={this.state.errorAlertOpen}>
                                    <Modal.Header closeButton className={"alert-danger"}>
                                        <Modal.Title>
                                            {this.state.errorTitle}

                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className={"alert-danger"}>
                                        <div className="alert" role="alert">
                                            {this.state.errorText}.
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button onClick={() => this.setState({errorAlertOpen: false})} variant="outline-success">
                                                Cerrar
                                            </Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="block-heading-two">
                                    <h3><i className="fa fas fa-building fa-fw"/>
                                        <span>Datos de su empresa (EMP-{this.state.id}).</span></h3>
                                </div>

                                {(this.state.require_moderation == true && this.state.approved != true) && (
                                <div className="block-heading-two">
                                    <Alert variant="success">
                                        <p>
                                            Ya casi esta listo! En breve los moderadores de la plataforma habilitarán su empresa.
                                            <br />
                                            Por consultas diríjase <a href={"/contact"}>aquí</a>
                                        </p>
                                    </Alert>
                                </div>
                                )}

                            </div>
                        </div>
                        <div className="row alignLeft">
                            <div className="col-lg-12">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        name: this.state.name,
                                        slug: this.state.slug,
                                        ruc: this.state.ruc,
                                        category: this.state.category,
                                        city: this.state.city,
                                        address: this.state.address,
                                        postal_code: this.state.postal_code,
                                        image_1: this.state.image_1,
                                        e_mail: this.state.e_mail,
                                        phone: this.state.phone,
                                        phone2: this.state.phone2,
                                        web_site: this.state.web_site,
                                        facebook: this.state.facebook,
                                        schedule: this.state.schedule,
                                        description: this.state.description,
                                        map_lng: this.state.map_lng,
                                        map_lat: this.state.map_lat,
                                        tags: this.state.tags,
                                        packagePremiumEnabled: this.state.packagePremiumEnabled,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string().required('Nombre de empresa es requerido.'),
                                        slug: Yup.string().min(5, 'El Alias debe contener minimo 5 caracteres').max(50, 'El Alias debe contener máximo 50 caracteres').required('El Alias de su empresa es requerido'),
                                        ruc: Yup.string().min(10, 'El número de CI / RUC debe contener hasta 13 caracteres').max(13, 'El número de CI / RUC debe contener hasta 13 caracteres').required('El número de CI / RUC es requerido.'),
                                        tags: Yup.string().max(150, 'Tags posee un límite máxido de 150 caracteres'),
                                        address: Yup.string().required('Dirección de empresa es requerida.'),
                                        phone: Yup.string().matches(/^(\+593)[0-9]{8,9}$/, "Ingrese un teléfono de Ecuador. Debe comenzar con +593 seguidos de 8 a 9 números."),
                                        phone2: Yup.string().matches(/^(\+593)[0-9]{8,9}$/, "Ingrese un teléfono de Ecuador. Debe comenzar con +593 seguidos de 8 a 9 números.")
                                        //city: Yup.string().required('Ciudad de empresa es requerida.'),
                                        // image1:Yup.string().required('Imágen de empresa es requerida.'),
                                    })}
                                    render={({errors, touched, setFieldValue, processResult}) => (
                                        <Form encType="multipart/form-data">
                                            {this.success == true ?
                                                <div className="alert alert-success alert-dismissible fade show">
                                                    <strong>Exito</strong> Sus datos fueron guardados correctamente
                                                    <button type="button" className="close"
                                                            data-dismiss="alert">&times;</button>
                                                </div> : ""
                                            }
                                            <FieldsEnterprise touched={touched} errors={errors}
                                                              setFieldValue={setFieldValue}
                                                              enterpriseID={this.state.id}
                                                              category={this.state.category}
                                                              city={this.state.city}
                                                              video_1={this.state.video_1}
                                                              image1={this.state.image_1}
                                                              map_lng={this.state.map_lng}
                                                              map_lat={this.state.map_lat}
                                                              tags={this.state.tags}
                                                              packagePremiumEnabled={this.state.packagePremiumEnabled}
                                                              checkValidation={this.state.checkValidation}
                                                              slug={this.state.slug}/>

                                            <div className="form-row mt-4">
                                                <div className="col-md-12 mb-3" style={{"text-align": "right"}}>
                                                    {(errors.name) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.name}
                                                        </div>
                                                    )}
                                                    {(errors.slug) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.slug}
                                                        </div>
                                                    )}
                                                    {(errors.ruc) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.ruc}
                                                        </div>
                                                    )}
                                                    {(errors.category) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.category}
                                                        </div>
                                                    )}
                                                    {(errors.city) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.city}
                                                        </div>
                                                    )}
                                                    {(errors.address) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.address}
                                                        </div>
                                                    )}
                                                    {(errors.postal_code) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.postal_code}
                                                        </div>
                                                    )}
                                                    {(errors.image_1) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.image_1}
                                                        </div>
                                                    )}
                                                    {(errors.e_mail) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.e_mail}
                                                        </div>
                                                    )}
                                                    {(errors.phone) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.phone}
                                                        </div>
                                                    )}
                                                    {(errors.phone2) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.phone2}
                                                        </div>
                                                    )}
                                                    {(errors.web_site) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.web_site}
                                                        </div>
                                                    )}
                                                    {(errors.facebook) && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {errors.facebook}
                                                        </div>
                                                    )}
                                                    <button id="actionSend" type="submit" onClick={this.checkValidation}
                                                            className="btn btn-primary mr-2">Guardar
                                                    </button>
                                                    <button id="actionSend" type="submit"
                                                            onClick={this.redirectToHome}
                                                            className="btn btn-link mr-2">Cancelar
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                    onSubmit={fields => {
                                        fetch('/v1/services/user/enterprise', {
                                            method: 'PUT',
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            },
                                            body: JSON.stringify(fields, null, 4),
                                        }).then(this.processResult)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
        )
    }
}

export default Enterprise