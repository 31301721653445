import React from "react"
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Button from 'react-bootstrap/Button'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const qs = require('query-string');

class UserQualification extends React.Component {
    constructor(props) {
        super(props);
        const parsed = qs.parse(window.location.search.substring());

        this.state = {
            error: null,
            cuToken: parsed["cutoken"],
            uToken: parsed["utoken"],
            userData : "",
        };

        this.tokenValidation = this.tokenValidation.bind(this);
        this.rateOnSetRate = this.rateOnSetRate.bind(this);

        this.pressSendComment = this.pressSendComment.bind(this);

        this.sendRate = this.sendRate.bind(this);

        this.qualificationModalShow = this.qualificationModalShow.bind(this);
        this.qualificationModalHide = this.qualificationModalHide.bind(this);
        this.handleChangeQualificationCommand = this.handleChangeQualificationCommand.bind(this);
    }

    tokenValidation() {
        return this.state.uToken.length > 30 && this.state.cuToken.length > 30
    }

    rateOnSetRate({rating}) {
        this.setState({rateSelected: rating}, () => this.sendRate(true));
    }

    pressSendComment() {
        this.sendRate(false)
        this.setState({qualificationSended: true});
    }

    sendRate(showModalShow) {
        let cuToken = this.state.cuToken
        let uToken = this.state.uToken
        let rateSelected = this.state.rateSelected
        let qualificationComment = this.state.qualificationComment
        fetch(process.env.PUBLIC_URL + '/v1/services/qualification/company', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify({
                "rate": rateSelected,
                "cuToken": cuToken,
                "uToken": uToken,
                "comment": qualificationComment
            }, null, 4),
        }).then(response => {
            if (response.status < 400 && showModalShow) {
                this.qualificationModalShow(true, false)
            }
            return
        })
    }

    qualificationModalShow(showQualificationModal, qualificationSended) {
        this.setState({qualificationSended: qualificationSended});
        this.setState({showQualificationModal: showQualificationModal});
    }

    qualificationModalHide() {
        this.qualificationModalShow(false, true)
    }

    handleChangeQualificationCommand(event) {
        let fleldVal = event.target.value;
        this.setState({qualificationComment: fleldVal})
    }

    componentDidMount() {
        let url = process.env.PUBLIC_URL + "/v1/services/user/token/"+this.state.uToken
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        userData: result
                    });
                }
            )
    }

    render() {
        return (

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span>Calificación de usuario</span></h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <p>Gracias por ser parte de nuestra plataforma.</p>
                        <p>El usuario: {this.state.userData.fullName}, calificó a su empresa.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="row" style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                        <div className="col-sm">
                            <p className={"rateSet"}>
                                {this.tokenValidation() ? (

                                    <div className="float-right mr-2">
                                        <div className="row">
                                            <div className="col">
                                                <span> Usted también puede calificar al usuario: </span>
                                                <Rater onRate={this.rateOnSetRate} rating={0} total={5}
                                                       interactive={true}/>
                                            </div>
                                        </div>

                                        <Modal show={this.state.showQualificationModal}
                                               onHide={this.qualificationModalHide}
                                               aria-labelledby="contained-modal-title-vcenter"
                                               dialogClassName={"modal-sm"}>
                                            <Modal.Header closeButton>
                                                <Modal.Title id="contained-modal-title-vcenter">
                                                    <h6>Calificación</h6>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="show-grid">
                                                {this.state.qualificationSended == false ? (
                                                        <div className="container">

                                                            <div className="row">
                                                                <div className="col-sm text-center rateSet-big">
                                                                    <div className={"react-rater"}>
                                                                        <Rater onRate={this.rateOnSetRate}
                                                                               rating={this.state.rateSelected} total={5}
                                                                               interactive={true}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm text-center">
                                                                    {this.state.rateSelected == 1 ? (
                                                                            <div>
                                                                                Califcación muy pobre
                                                                            </div>
                                                                        ) :
                                                                        this.state.rateSelected == 2 ? (
                                                                                <div>
                                                                                    Califcación pobre
                                                                                </div>
                                                                            ) :
                                                                            this.state.rateSelected == 3 ? (
                                                                                    <div>
                                                                                        Califcación aceptable
                                                                                    </div>
                                                                                ) :
                                                                                this.state.rateSelected == 4 ? (
                                                                                    <div>
                                                                                        Califcación buena
                                                                                    </div>
                                                                                ) : this.state.rateSelected == 5 ? (
                                                                                    <div>
                                                                                        Excelente
                                                                                    </div>
                                                                                ) : ""

                                                                    }


                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm">
                                                                    <Form>
                                                                        Comentario opcional:
                                                                        <Form.Control as="textarea" rows={3} maxLength={100}
                                                                                      onChange={this.handleChangeQualificationCommand}/>
                                                                    </Form>
                                                                </div>
                                                            </div>

                                                            <div className="row mt-2">
                                                                <div className="col-sm text-center">
                                                                    <Button onClick={this.pressSendComment}>Enviar
                                                                        Comentario</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                Gracias por ser parte del servicio, valoramos su
                                                                calificación.
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                ) : "invalid link"
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserQualification