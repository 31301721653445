import React from "react"

class Email extends React.Component {
    render() {
        return (
            <React.Fragment>
               <a href="mailto:infoonline@laguiamundial.com">infoonline@laguiamundial.com</a>
            </React.Fragment>
        )
    }
}

export default Email