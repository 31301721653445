import React from "react"
import {string} from "prop-types";

const qs = require('query-string');


function loadCategories() {
    let url = process.env.PUBLIC_URL + '/v1/services/categories'
    let sep = '?'

    if (this.state.text) {
        url += `${sep}text=${this.state.text}`
        sep = "&"
    }
    if (this.state.category) {
        url += `${sep}category=${this.state.category}`
        sep = "&"
    }
    if (this.state.city) {
        url += `${sep}city=${this.state.city}`
    }
    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            text: null,
            category: null,
            city: null,
            categoryName: null
        };


        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.loadCategories = loadCategories.bind(this);
    }

    applyFiltersCategory = (text, category, city) => {
        this.state.text = text
        this.state.category = category
        this.state.city = city
        this.loadCategories()
    }

    onChangeCategory(option) {
        this.state = {
            category: option.value
        }
    }

    onChangeText(value) {
        console.log(value)
    }

    onChangeCity(option) {
        this.state = {
            city: option.value
        }
        console.log(this.state.city)
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let categoryQs = parsed["category"]
        let categoryNameQs = parsed["category_name"]
        if (categoryQs != "") {
            this.state = {
                category: categoryQs,
                categoryName: categoryNameQs
            }
        }

        this.loadCategories()
    }

    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            result = <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            result = <div>Loading...</div>;
        } else {
            result = "Sin resultados"
            if (this.state.items.data) {
                result = this.state.items.data.map(item => (
                    <div key={"category-box-" + item.id} className="category-box col-lg-2 col-md-6 col-sm-6 col-xs-12 "
                         style={{marginBottom: '0px'}}>
                        <header className={"category-header clearfix " + item.color}>
                            <a href={"/offers?category=" + item.id + "&category_name=" + item.name}>
                                <CatIcon icon_image={item.icon_image} icon={item.icon}></CatIcon>
                                <div className="category-title">
                                    <h5>{item.name}</h5>
                                </div>
                            </a>
                        </header>
                    </div>
                ))
            }
        }

        return <>
            <div className="col-12">
                <div className="block-heading-two">
                    <h3><span><i className="fa fa-tag"></i> Categorías:</span></h3>
                </div>
            </div>
            {result}</>

    }
}

export default Categories

function CatIcon(props) {
    const data = props;
    return (
        data.icon_image ? (
            <div className="category-icon"><img className="img-responsive" alt="icon_image" src={data.icon_image}></img>
            </div>
        ) : <div className="category-icon"><i className={data.icon}></i></div>
    )
}