import React from 'react'

const TagsInput = props => {
    const [tags, setTags] = React.useState(props.tags != undefined? props.tags:[]);
    const [enableAdd, setEnableAdd] = React.useState(props.enableAdd != undefined? props.enableAdd:true);
    const removeTags = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)]);
        props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
    };

    const addTags = event => {
        if (event.target.value !== "") {
            setTags([...tags, event.target.value]);
            props.selectedTags([...tags, event.target.value]);

            event.target.value = ""
        }
    };
    return (
        <div className="tags-input">
            <ul id="tags">
                {tags != null && tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon'
                              onClick={() => removeTags(index)}
                        >
                            x
                        </span>
                    </li>
                ))}
            </ul>

            {enableAdd ? (
                <input
                    className={"form-control"}
                    type="text"
                    onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    placeholder="ingrese y presione enter para agregar un nuevo item"
                />
            ) : ""}
        </div>
    );
};
export default TagsInput