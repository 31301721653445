import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Collapse from "react-bootstrap/Collapse";
import Alert from "react-bootstrap/Alert";


class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionSuccessful: null,
            sendEnabled: false,
        }
    }

    enableSend = () => {
        this.setState({sendEnabled: true})
    }

    disableSend = () => {
        this.setState({sendEnabled: true})
    }


    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span><i className="fa fa-envelope"></i> Contacto</span></h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Collapse in={this.state.actionSuccessful!=null}>
                            <div>
                                <Alert
                                    variant={this.state.actionSuccessful == true ? 'success' : this.state.actionSuccessful == false ? 'danger' : ''}>
                                    <Alert.Heading>{this.state.successTitle}</Alert.Heading>
                                    <p>
                                        {this.state.actionResult}
                                    </p>
                                </Alert>
                            </div>
                        </Collapse>
                    </div>
                </div>

                <div className="row alignLeft">
                    <div className="col-lg-12">
                        <Formik
                            initialValues={{
                                email: '',
                                message: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email('Email es inválido')
                                    .required('E-mail es requerido'),
                                message: Yup.string()
                                    .required('Es mensaje es requerido')
                            })}
                            onSubmit={fields => {
                                this.setState({sendEnabled: false})
                                fetch(process.env.PUBLIC_URL + '/v1/services/contact', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    body: JSON.stringify(fields, null, 4),
                                })
                                    .then((response) => {
                                            if (response.status < 300) {
                                                this.setState({
                                                    sendEnabled: true,
                                                    actionSuccessful: true,
                                                    actionResult: "Gracias por enviarnos su consulta, en breve le enviaremos una respuesta."
                                                })
                                            } else {
                                                this.setState({
                                                    sendEnabled: true,
                                                    actionSuccessful: false,
                                                    actionResult: "Se produjo un error al intentar enviar los datos. Por favor intente más tarde."
                                                })
                                            }
                                            response.json()

                                        },

                                        (error) => {
                                            this.setState({
                                                sendEnabled: true,
                                                actionSuccessful: false,
                                                actionResult: "Se produjo un error al intentar enviar los datos. Por favor intente más tarde."
                                            })
                                        }

                                    );

                            }}
                            render={({errors, status, touched, setFieldValue}) => (
                                <Form encType="multipart/form-data">
                                    <div className="form-group">
                                        <label htmlFor="email">E-mail</label>
                                        <Field name="email" type="text"
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Mensaje</label>
                                        <Field component="textarea" id="message" name="message" rows="4" cols="50"
                                               className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="message" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                                            onChange={this.enableSend}/>
                                    </div>
                                    <div className="form-group">
                                        <div id="resultMessage"></div>
                                    </div>
                                    <div className="form-group">
                                        <button id="actionSend" disabled={!this.state.sendEnabled} type="submit"
                                                className="btn btn-primary mr-2">Enviar
                                        </button>
                                    </div>
                                    <ErrorMessage name="custom" render={msg => <div>{msg}</div>}/>
                                </Form>
                            )}
                        />
                    </div>
                </div>
            </div>)
    }
};

export default Contact