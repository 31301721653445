import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "react-bootstrap/Alert";
import MapWithASearchBox from "./map-with-search-box";
import FieldsEnterprise from "./fields-enterprise";
const qs = require('query-string');

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            resultText: null,
            showmap:'no',

            map_lng:-56.2216378,
            map_lat:-34.7330001,
        };

        this.verifyCallback = this.verifyCallback.bind(this);
        this.statusSendButton = this.statusSendButton.bind(this);
        this.notifymaplng = this.notifymaplng.bind(this);
        this.notifymaplat = this.notifymaplat.bind(this);
    }

    notifymaplng = (value) => {
        //this.props.setFieldValue('map_lng', value);
        //console.log(value)
        alert(value)
    }

    notifymaplat = (value) => {
        //this.props.setFieldValue('map_lat', value);
        //console.log(value)
        alert(value)
    }


    verifyCallback = (recaptchaToken) => {
        this.statusSendButton(false)
    }

    statusSendButton(enable) {
        document.getElementById("actionSend").disabled = enable;
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        this.setState({
            showmap: parsed["showmap"],
        })
    }

    render() {
        return <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span>Ingreso de usuario</span></h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email('El nombre de usuario es inválido.').required('Nombre de usuario es requerido.'),
                            })}

                            render={({errors, touched}) => (
                                <Form encType="multipart/form-data">
                                    <div className="form-row mt-3">
                                        <div className="col-md-8">
                                            <hr className="my-4"></hr>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <h5><i className="fas fa-key"></i><span> Recuperación de contraseña</span>
                                            </h5>
                                        </div>
                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="col-md-8">
                                            <label htmlFor="userEmail">Nombre de usuario</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                </div>
                                                <Field type="text" name="email" placeholder="Ingrese su e-mail"
                                                       aria-describedby="inputGroupPrepend"
                                                       className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                                <ErrorMessage name="email" component="div"
                                                              className="invalid-feedback"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row mt-3">
                                        <div className="col-md-8">
                                    <span><strong>Será enviado un e-mail a su casilla conteniendo un link para
                                        restablecer su contraseña.</strong></span>
                                        </div>
                                    </div>

                                    <div className="form-row mt-4">
                                        <div className="col-md-4 mb-3">
                                            <ReCAPTCHA
                                                sitekey="6LcUfdUUAAAAABv_aYx68d3NRxyeOap9tfJQfY_E"
                                                onChange={this.verifyCallback}/>
                                        </div>
                                    </div>
                                    <div className={'form-row ' + (this.state.error == false ? '' : 'd-none')}>
                                        <div className="col-md-8">
                                            <Alert variant="success">
                                                {this.state.resultText}
                                            </Alert>
                                        </div>
                                    </div>
                                    <div className={'form-row ' + (this.state.error == true ? '' : 'd-none')}>
                                        <div className="col-md-8">
                                            <Alert variant="danger">
                                                {this.state.resultText}
                                            </Alert>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <button disabled id="actionSend" type="submit"
                                                    className="btn btn-primary mr-2">Recuperar
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                            onSubmit={
                                fields => {
                                    this.statusSendButton(true)
                                    fetch(process.env.PUBLIC_URL + '/v1/services/forgot-password', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        },
                                        body: JSON.stringify(fields, null, 4),
                                    })
                                        .then(res => {
                                                if (res.status < 400) {
                                                    this.setState({
                                                        error: false,
                                                        resultText: "Fue enviado e-mail con link de recuperación a su " +
                                                            "casilla de correos. Por favor verifique su e-mail incluido el Spam."
                                                    })
                                                } else {
                                                    this.setState({
                                                        error: true,
                                                        resultText: "Se genero un error, por favor verifique los " +
                                                            "datos ingresados."
                                                    })
                                                }
                                            },


                                            (error) => {
                                                this.setState({
                                                    error: true,
                                                    resultText: "Error de conexión"
                                                })
                                                this.statusSendButton(false)
                                            }
                                        )
                                }
                            }
                        />
                    </div>
                </div>

            </div>

        </>
    }
}


export default ForgotPassword