import React from "react"
import {isIOS, isAndroid} from "react-device-detect";
import Email from "../shared/email";
const qs = require('query-string');
class Social extends React.Component {
    render() {
        const parsed = qs.parse(window.location.search.substring(1));
        let fromApp = parsed["app"]

        return (
            <div className="container">
                <div className="row">
                    <div className="col-6 col-lg-4 col-md-4 col-sm-4 col-xm-6 ">
                        <Email value="infoonline@laguiamundial.com"/>
                    </div>
                    <div className="col-6 col-lg-4 col-md-4 col-sm-4 col-xm-6 text-right">
                        <div className="text-right fb-share-button facebook"
                             data-href={process.env.PUBLIC_URL}
                             data-layout="button_count">
                        </div>
                    </div>
                     { isIOS == false && !fromApp ? (
                         <div className="col-3 d-none d-sm-block text-right">
                          <a className={""} target="_blank" href="https://play.google.com/store/apps/details?id=com.laguiamundial.ofertas">
                              <img alt="disponible en google play" style={{height: "40px"}} src={"https://laguiamundial.com/img/disponible-google-play_50.webp"} />
                          </a>
                         </div>
                     ) : ""}
                </div>
            </div>
        );
    }
}

export default Social