import React, {useState} from "react"

class TermsConditions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <p className="font-weight-normal float-right">Última actualización: 29/06/2020</p>
                        <p className="font-weight-bold">TÉRMINOS Y CONDICIONES</p>

                        <p className="font-weight-bold mt-2">Antecedentes</p>
                        <p className="font-weight-normal">
                            LaGuiaMundial, es una plataforma que ha sido desarrollado, con el fin de dar servicios de
                            comunicación e información a los usuarios, utilizando múltiples accesos (Web, Móviles, Redes
                            Sociales, etc.). Con una propuesta de expansión internacional en acuerdo con socios
                            comerciales de cada país.
                            La información que se publicará es recibida en formatos diversos y provistos por varias
                            fuentes, como ser: sitios web, Apis de terceros, usuarios, clientes comerciales, aliados
                            comerciales, fuerza de venta propia y de terceros, entre otras y no en forma limitativa.
                            Un cliente comercial y/o aliado comercial se constituye una persona natural o jurídica que
                            sirve de intermediario entre LaGuiaMundial y los Promotores empresariales
                            LaGuiaMundial y el aliado local, gestionan el servicio multiplataforma y multiaplicaciones
                            comercialmente, por lo que se consideran solidarios en cuanto a las condiciones de uso,
                            política de privacidad, términos y condiciones comerciales.
                            LaGuiaMundial, asegura un 99.5% Uptime del sitio.</p>


                        <p className="font-weight-bold mt-2">Propiedad Intelectual</p>
                        <p className="font-weight-normal">LaGuiaMundial, mantiene la propiedad intelectual de la
                            plataforma y sus componentes. EL MATERIAL SUBIDO, como imágenes, sellos, son de propiedad y
                            de uso exclusivo de las empresas o personas que publican un NEGOCIO Y OFERTAS.</p>

                        <p className="font-weight-bold mt-2">Usuarios</p>
                        <p className="font-weight-normal">LaGuiaMundial, como proveedor de servicios informáticos,
                            establece tres roles o formas de participación.</p>

                        <p className="font-italic mt-2">Promotores empresariales:</p>
                        <p className="font-weight-normal">Son quienes tienen su información en la plataforma y pagan una
                            suscripción por tener las diversas funcionalidades de la plataforma o utilizan el servicio
                            gratuito. Los promotores empresariales se hace responsables de la información que publica en
                            la plataforma, sus beneficios y frente a cualquier reclamo que los usuarios puedan realizar
                            por la publicación de la información</p>

                        <p className="font-italic mt-2">Usuarios:</p>
                        <p className="font-weight-normal">Podrán acceder a consultar y gestionar información, e
                            interactuar con las plataformas. El usuario acepta cumplir con todos los documentos y
                            políticas que forman parte integrante de los presentes Términos y Condiciones y Anexos, al
                            utilizar los servicios y productos publicados por Promotores empresariales. Por lo cual,
                            deberá leer y entender éste documento y sus documentos anexos, y en caso de estar de acuerdo
                            con los mismos aceptarlos al momento del registro como usuario de la plataforma. En caso de
                            no registro por parte del usuario, se considera que el usuario lo ha aceptado por el sólo
                            uso de la plataforma.</p>

                        <p className="font-italic mt-2"> Aliados Comerciales:</p>
                        <p className="font-weight-normal">Los Aliados Comerciales, son empresas, organizaciones o
                            microempresas, que desarrollan las relaciones con la comunidad en su zona de influencia,
                            pueden ser distribuidores a escala local de los servicios disponibles en
                            www.LaGuiaMundial.com</p>

                        <p className="font-weight-bold mt-2">Requisito general de uso</p>
                        <p className="font-weight-normal">Para ser usuario de la presente plataforma, se deberá tener la
                            capacidad legal para contratar, por lo tanto, aquellos que no cumplan con esta condición
                            deberán abstenerse de suministrar información personal para ser incluida en nuestras bases
                            de datos. En caso de requerirlo, la empresa y oferta será dada de baja.</p>

                        <p className="font-weight-bold mt-2">Registro, Usuario (promotores empresariales)</p>
                        <p className="font-weight-normal">Para publicar un a empresa y/o oferta, se deberá completar el
                            formulario de registro, el mismo que contiene la aceptación a este contrato de términos y
                            condiciones. La cuenta es personal, intransferible y única; debiendo mantener la clave de
                            seguridad en estricta confidencialidad. En caso de información errónea de manera voluntaria
                            o involuntaria, el usuario será eliminado, sin derecho a ningún tipo de reparación</p>

                        <p className="font-weight-bold mt-2">Publicación de datos, información, bienes y servicios</p>
                        <p className="font-weight-normal">LaGuiaMundial, permite la publicación de información de
                            empresas, personas naturales y jurídicas, al igual que la publicación de ofertas de Bienes
                            y/o Servicios de una manera segmentadas en categorías y subcategorías. La información
                            proporcionada por el promotor empresarial debe ser clara y precisa. LaGuiaMundial, no se
                            especializa en la venta de los productos promocionados, es decir, EL CLIENTE FINAL del
                            promotor empresarial, debe tener la prolijidad de constatar la calidad, cantidad y otros
                            detalles del Bien o Servicio adquirido.
                            El Promotor empresarial, tiene la obligación de indicar sus datos, en cada publicación.</p>

                        <p className="font-weight-bold mt-2">Obligaciones de los usuarios. Responsabilidad</p>
                        <p className="font-weight-normal">Siendo un servicio gratuito para los usuarios, su uso debe ser
                            en forma adecuada, teniendo siempre en cuenta cualquier conducta violatoria de los usos
                            honestos del Internet, debiéndose el usuario utilizarla con la única finalidad de consultar,
                            interactuar, comprar y/o vender productos y/o servicios. LaGuiaMundial es un nexo entre: I)
                            el comprador y el vendedor, II) el ciudadano y el gestor de información.
                            LaGuiaMundial no tienen ninguna responsabilidad por las compraventas ni por los servicios
                            que perfeccionen a través de su sitio, ni por las informaciones brindadas, debiendo cada uno
                            de los usuarios cumplir con sus respectivas obligaciones en su calidad de vendedor y/ o
                            comprador de un producto y/o servicio o proveedor de información. LaGuiaMundial no responde
                            por ningún tipo de daño (daño emergente, lucro cesante, daño moral) que, por el uso del
                            sitio, se le ocasione a un usuario y/o a otro tercero. El usuario es responsable del
                            contenido que publica en el sitio de LaGuiaMundial, debiendo responder de forma exclusiva si
                            el contenido infringe normas de propiedad intelectual o cualquier otra norma jurídica
                            vigente en el país en que se desarrolla el servicio, exonerando de responsabilidad a
                            LaGuiaMundial.</p>

                        <p className="font-weight-bold mt-2">Sanciones</p>
                        <p className="font-weight-normal">LaGuiaMundial aplicarán sanciones en caso de constatar
                            irregularidades por parte de los usuarios que afectan a otros usuarios y/o terceros. Las
                            sanciones podrán ser de distinto tipo; suspensión temporal de toda publicación realizada por
                            el usuario que no ha cumplido con este acuerdo y condiciones, a través del formato de
                            contacto puede solicitar su activación demostrando el cumplimiento adecuado de este convenio
                            de Término y Condiciones. La decisión tomada por LaGuiaMundial, es inapelable.</p>

                        <p className="font-weight-bold mt-2">Derechos</p>
                        <p className="font-weight-normal">Carlos Cevallos es el propietario de LaGuiaMundial.com
                            contando con el registro de propiedad intelectual. Todo el software ha sido utilizado con
                            las licencias requeridas de cada uno de los proveedores de los servicios requeridos.
                            Ranking. En el presente sitio los usuarios deberán calificar al otro usuario en su calidad
                            de vendedor y/o comprador y/o simplemente como usuario. A través de dichas calificaciones se
                            va estableciendo una reputación y perfil del usuario. El usuario es exclusivo responsable
                            por el contenido de dicha calificación. La opinión que emita deberá ser en términos
                            correctos, siendo exclusivo responsable por la utilización de cualquier tipo de término
                            inapropiado. El usuario tendrá acceso a las calificaciones que se le efectúen, pudiendo,
                            bajo su exclusiva responsabilidad, realizar réplicas a los comentarios efectuados por otro
                            usuario.</p>

                        <p className="font-weight-bold mt-2">Tarifa del Servicio</p>
                        <p className="font-weight-normal">El servicio general es gratuito para los usuarios. Para los
                            usuarios Promotores Empresariales, los valores serán publicados en la pestaña Paquetes. El
                            no pago de los servicios prestados dará lugar a la suspensión del comercio hasta el efectivo
                            pago. Lo anterior, es sin perjuicio de las acciones judiciales y/o extrajudiciales que se
                            efectúen para el cobro de las sumas adeudadas.
                            El promotor empresarial acepta que sea notificado a su correo, la pronta finalización de su
                            paquete adquirido y la manera de renovarlo.
                            Para los paquetes adquiridos en la república del Ecuador, los valores son más el Iva, para
                            emitir la factura correspondiente.
                            Modificaciones. El presente documento de términos y condiciones podrá ser modificado por
                            LaGuiaMundial cuando lo estimen pertinente, quedando comunicado el usuario desde el instante
                            mismo en que se actualice el documento en el sitio. El uso del sitio con posterioridad de la
                            actualización, se interpretará como aceptación tácita de los cambios de los términos y
                            condiciones y las políticas de uso.</p>

                        <p className="font-weight-bold mt-2">Jurisdicción. Ley. Domicilio.</p>
                        <p className="font-weight-normal">LaGuiaMundial, en caso de algún requerimiento legal se somete
                            a los centros de mediación, domiciliados en la ciudad de Quito.</p>
                    </div>
                </div>
            </div>)
    }
};

export default TermsConditions