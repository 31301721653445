import React from "react"
import {Field, ErrorMessage} from 'formik';

import NumberFormat from 'react-number-format';
import Toggle from 'react-toggle'
import TagsInput from "./tagsInput";
import '../../../toggle.css'
import '../../../tagsinput.css'
import FileUploadModal from "./file_upload-modal";
import QRCode from "qrcode.react";
import YouTube from "@u-wave/react-youtube";
import FileUploadPreview from "./file-upload-preview";

class FieldsOffer extends React.Component {

    constructor(props) {
        super(props);
        this.onChangeCategory = this.onChangeCategory.bind(this)
        this.handlePublishedState = this.handlePublishedState.bind(this)
        this.notifyimageuploadresultform = this.notifyimageuploadresultform.bind(this)

        console.log("props.offerPackage: " + props.offerPackage)
        this.state = {
            offerId: props.offerId,
            offerName: props.offerName,
            offerDescription: props.offerDescription,
            offerCategory: props.offerCategory,
            offerPublished: props.offerPublished != false,
            offerVideo1: props.offerVideo1,
            offerVideo2: props.offerVideo2,
            offerImage1: props.offerImage1,
            offerImage2: props.offerImage2,
            offerImage3: props.offerImage3,
            offerImage4: props.offerImage4,
            offerImage5: props.offerImage5,
            offerImage6: props.offerImage6,
            offerExternalReference: props.offerExternalReference,
            offerPrice: props.offerPrice,
            offerPreviousPrice: props.offerPreviousPrice,
            offerCurrency: props.offerCurrency,
            offerCompanyPackage: props.offerCompanyPackage,
            offerTags: props.offerTags,
            offerPackage: props.offerPackage,
        };
    }


    componentDidMount() {
        this.props.setFieldValue('offerPublished', this.state.offerPublished);
        this.props.setFieldValue('offerCompanyPackage', this.state.offerCompanyPackage);
        //this.props.setFieldValue('offerTags', []);

    }

    onChangeCategory(category) {
        this.props.setFieldValue('offerCategory', category.value);
    }

    handlePublishedState(event) {
        this.props.setFieldValue('offerPublished', event.target.checked);
    }

    selectedTags = tags => {
        this.props.setFieldValue('offerTags', tags);
    };

    notifyimageuploadresultform = (imageFormId, value) => {
        this.props.setFieldValue(imageFormId, value);
    }

    handleChange = (e) => {
        this.props.setFieldValue('offerVideo1', e.target.value);
        this.setState({offerVideo1: e.target.value});
    }

    render() {

        return <>
            <div className="form-row mt-3">
                <div className="col-md-6">
                    <div className="form-row">
                        <div className="col-md-12">
                            <label htmlFor="offerName" className="font-weight-bold">Nombre</label>
                            <Field name="offerName" type="text" placeholder=""
                                   className={'form-control' + (this.props.errors.offerName && this.props.touched.offerName ? ' is-invalid' : '')}/>
                            <ErrorMessage name="offerName" component="div" className="invalid-feedback"/>
                        </div>
                    </div>
                    <div className="form-row mt-1">
                        <div className="col-md-6">
                            <label htmlFor="offerPrice" className="font-weight-bold">Precio</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">$</span>
                                </div>
                                <NumberFormat value={this.state.offerPrice} thousandSeparator={true}
                                              className={'form-control'}
                                              onValueChange={(values) => {
                                                  const {formattedValue, value} = values;
                                                  this.setState({offerPrice: value});
                                                  this.props.setFieldValue('offerPrice', value * 1);
                                              }}/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="offerPreviousPrice" className="font-weight-bold">Precio anterior</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">$</span>
                                </div>

                                <NumberFormat value={this.state.offerPreviousPrice} thousandSeparator={true}
                                              className={'form-control'}
                                              onValueChange={(values) => {
                                                  const {formattedValue, value} = values;
                                                  this.setState({offerPreviousPrice: value});
                                                  this.props.setFieldValue('offerPreviousPrice', value * 1);
                                              }}/>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="offerDescription" className="font-weight-bold">Descripción</label>
                    <Field component="textarea" id="offerDescription" name="offerDescription" rows="4" cols="50"
                           className={'form-control' + (this.props.errors.message && this.props.touched.message ? ' is-invalid' : '')}/>
                    <ErrorMessage name="offerDescription" component="div" className="invalid-feedback"/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="offerExternalReference" className="font-weight-bold">Publicar oferta</label>
                    <br/>
                    <Toggle defaultChecked={this.state.offerPublished}
                            icons={true}
                            onChange={this.handlePublishedState}/>

                    <input type="hidden" name="offerPublished"/>
                    <input type="hidden" name="offerCompanyPackage"/>
                </div>
                <div className="col-md-6">
                    <label htmlFor="offerExternalReference" className="font-weight-bold">Link web (referencia
                        externa)</label>
                    <Field type="text" name="offerExternalReference" placeholder="" aria-describedby="inputGroupPrepend"
                           className={'form-control' + (this.props.errors.offerExternalReference && this.props.touched.offerExternalReference ? ' is-invalid' : '')}/>
                    <ErrorMessage name="offerExternalReference" component="div" className="invalid-feedback"/>
                </div>

            </div>

            <div className="form-row mt-3">
                <div className="col-md-6 text-center border">
                    <label htmlFor="companySchedule" className="font-weight-bold">Imagen 1</label>
                    <div className="file-upload-easy-crop-container">

                        <FileUploadPreview notifyimageuploadresultform={this.notifyimageuploadresultform}
                                           imageFormId="offerImage1" imageSrc={this.state.offerImage1}
                                           serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/offer"}/>
                    </div>
                </div>

                {this.state.offerPackage == 3 && (
                    <div className="col-md-6 text-center border">
                        <div className="embed-responsive embed-responsive-4by3">
                            <YouTube
                                className={"embed-responsive-item"}
                                video={getVideoID(this.state.offerVideo1)}
                                allowFullscreen={true}
                                width="100%"
                                height="100%"
                            />
                        </div>
                        <div className=" mt-1">
                            <div className="row">
                                <div className="col-2 mt-2">
                                    Youtube:
                                </div>
                                <div className="col-10">
                                    <Field onChange={this.handleChange}  value={this.state.offerVideo1} name="video_1" type="text" className={'form-control w-100'} style={{display:"inline"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {this.state.offerPackage == 3 && (
                <>
                    <div className="form-row mt-3">
                        <div className="col-md-6 text-center border">
                            <label htmlFor="companySchedule" className="font-weight-bold">Imagen 2</label>
                            <div className="file-upload-easy-crop-container">

                                <FileUploadPreview notifyimageuploadresultform={this.notifyimageuploadresultform}
                                                 imageFormId="offerImage2" imageSrc={this.state.offerImage2}
                                                 serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/offer"}/>
                            </div>
                        </div>
                        <div className="col-md-6 text-center border">
                            <label htmlFor="companySchedule" className="font-weight-bold">Imagen 3</label>
                            <FileUploadPreview notifyimageuploadresultform={this.notifyimageuploadresultform}
                                             imageFormId="offerImage3" imageSrc={this.state.offerImage3}
                                             serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/offer"}/>
                        </div>


                    </div>

                    <div className="form-row mt-3">
                        <div className="col-md-6 text-center border">
                            <label htmlFor="companySchedule" className="font-weight-bold">Imagen 4</label>
                            <FileUploadPreview notifyimageuploadresultform={this.notifyimageuploadresultform}
                                             imageFormId="offerImage4" imageSrc={this.state.offerImage4}
                                             serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/offer"}/>
                        </div>
                        <div className="col-md-6  text-center border">
                            <label htmlFor="companySchedule" className="font-weight-bold">Imagen 5</label>
                            <FileUploadPreview notifyimageuploadresultform={this.notifyimageuploadresultform}
                                             imageFormId="offerImage5" imageSrc={this.state.offerImage5}
                                             serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/offer"}/>
                        </div>
                    </div>
                </>
            )}
            <div className="form-row mt-4">
                {this.props.offerId > 0 && (
                    <div className="col-md-6">
                        <label htmlFor="companySchedule" className="font-weight-bold">Código QR de la Oferta</label>

                        <div className="form-row">
                            <div className="col-md-5">
                                <label htmlFor="qrCode">
                                    Puede descargar e imprimir el siguiente QR en sus publicaciones de revistas, tarjetas personales, folletería, etc.
                                    <br />Los usuarios podrán escanear este código desde sus celulares y acceder directamente a esta oferta.
                                </label>
                            </div>

                            <div className="col-md-7 text-center">
                                <QRCode
                                    id={"oferta_"+this.props.offerId+"_qr"}
                                    value={process.env.PUBLIC_URL+"/offer-detail?id="+this.props.offerId}
                                    size={290}
                                    level={"L"}
                                    includeMargin={true}
                                />
                                <button onClick={() => downloadQR(this.props.offerId)} className="btn btn-primary btn-sm btn btn-primary"><i className="fas fa-download"></i> Descargar QR</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-md-6">
                    <label htmlFor="tagsInput" className="font-weight-bold">Tags de búsqueda</label>
                    <TagsInput selectedTags={this.selectedTags} tags={this.state.offerTags}/>
                </div>



            </div>
        </>
    }
}

const downloadQR = (offerId) => {

    let id = "oferta_"+offerId+"_qr"

    const canvas = document.getElementById(id);
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = id+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

function getVideoID(path){
    if (path==undefined || path==null){
        return ""
    }
    var sp = path.split("v=")
    if (sp.length == 1) {
        return sp[0]
    }
    sp = sp[1].split("&")
    return sp[0]

}

export default FieldsOffer