import React from 'react'

const TagsInputMore = props => {
    /*const [tags, setTags] = React.useState(props.tags != undefined? props.tags:[]);
    const removeTags = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    };*/

    return (
        <div className="tags-input">
            <ul id="tags">
                {props.tags != null && props.tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className='tag-title'>{tag}</span>
                        <span className='tag-close-icon' onClick={() => props.removeElement(index)} >x</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default TagsInputMore