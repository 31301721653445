import React from "react"
import Topbar from "./topbar"

class Outer extends React.Component {
    render() {
        return (
            <div className="outer">
                <Topbar />
            </div>
        );
    }
}

export default Outer