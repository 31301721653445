import React from "react"
import {string} from "prop-types";
import Truncate from 'react-truncate';
import Button from "react-bootstrap/Button";

const qs = require('query-string');

function loadOffers() {
    let url = process.env.PUBLIC_URL + '/v1/services/offers'
    let sep = '?'

    const parsed = qs.parse(window.location.search.substring(1));
    let id = parsed["id"]

    url += `${sep}offset=${this.state.loadOffersCurrentPage * this.state.loadOffersItemsInPage}`
    sep = "&"
    url += `${sep}limit=${this.state.loadOffersItemsInPage}`

    if (id) {
        if (this.state.type == 3) {
            url += `${sep}exclude=${id}`
        } else {
            url += `${sep}enterprise=${id}`
            this.setState({
                type: 1
            });
        }
    }

    if (this.state.text) {
        url += `${sep}text=${this.state.text}`
    }

    if (this.state.category) {
        url += `${sep}category=${this.state.category}`
    }

    if (this.state.city != undefined) {
        url += `${sep}city=${this.state.city}`
    }

    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}



class Offers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            selectedOption: 0,
            category: props.category,
            city: props.city,
            type: props.type,
            text: props.text,

            loadOffersCurrentPage: 0,
            loadOffersItemsInPage: 12,
            loadOffersResult0: null,
            loadOffersResult1: null,
            loadOffersResult2: null,
            loadOffersResult3: null,
            loadOffersResult4: null,
            loadOffersResult5: null,
            loadOffersResult6: null,
            loadOffersResult7: null,
            loadOffersResult8: null,
            loadOffersResult9: null,
            loadOffersHasNext: true
        };

        if (this.state.type == null) {
            this.state.type = 2
        }

        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.loadOffers = loadOffers.bind(this);
    }

    applyFiltersText = (text, category, city) => {
        this.state.text = text
        this.state.category = category
        this.state.city = city
        this.loadOffers()
    }

    applyFiltersOffers = (text, category, city) => {
        this.state.text = text
        this.state.category = category
        this.state.city = city
        this.loadOffers()
    }

    loadMoreOffers = () => {
        this.state.loadOffersCurrentPage = this.state.loadOffersCurrentPage + 1
        this.loadOffers()
    }

    getOffersList = () => {
        let result = ""
        if (this.state.items.data) {
            result = this.state.items.data.map(item => (
                <div key={"offer-box-" + item.id} className="prise-box col-lg-2 col-md-3 col-sm-4 col-xs-6 m-2"
                     style={{padding: '5px'}}>
                    <a href={"offer-detail?id=" + item.id}>
                        <div className={"offer-image-container vertical-center"}>
                            <ImageThumbnail value={item}/>
                        </div>

                        <div className="info-this">
                            <div className={"offer-text-container"}>
                                <div className={"offer-text-content"}>
                                    <div className="left-box" style={{width: !item.save_up ? "100%" : "80%"}}>
                                        <h5 style={{width: !item.save_up ? "100%" : "80%"}}>
                                            {item.name}
                                        </h5>

                                        <div className="text-secondary text-truncate"><i
                                            className="fa fa-tag mr-1"/>
                                            <Truncate lines={1}>
                                                {item.category_name}
                                            </Truncate>
                                        </div>
                                        <div className="text-secondary text-truncate"><i
                                            className="fas fa-building mr-1"/>
                                            <Truncate lines={1}>
                                                {item.enterprise_name}
                                            </Truncate>
                                        </div>
                                        <div className="text-secondary text-truncate"><i
                                            className="fa fa-city mr-1"/>
                                            <Truncate lines={1}>
                                                {item.city_name}
                                            </Truncate>
                                        </div>

                                        <Price value={item.price} currency={item.currency} />
                                    </div>
                                    <SaveUp value={item.save_up}/>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>))
        }else{
            if (this.state.loadOffersResult0 == null) {
                result = "Sin resultdos"
            }
        }

        if (this.state.loadOffersCurrentPage == 0) {
            this.state.loadOffersResult0 = result
        } else if (this.state.loadOffersCurrentPage == 1){
            this.state.loadOffersResult1 = result
        } else if (this.state.loadOffersCurrentPage == 2){
            this.state.loadOffersResult2 = result
        } else if (this.state.loadOffersCurrentPage == 3){
            this.state.loadOffersResult3 = result
        } else if (this.state.loadOffersCurrentPage == 4){
            this.state.loadOffersResult4 = result
        } else if (this.state.loadOffersCurrentPage == 5){
            this.state.loadOffersResult5 = result
        } else if (this.state.loadOffersCurrentPage == 6){
            this.state.loadOffersResult6 = result
        } else if (this.state.loadOffersCurrentPage == 7){
            this.state.loadOffersResult7 = result
        } else if (this.state.loadOffersCurrentPage == 8){
            this.state.loadOffersResult8 = result
        } else if (this.state.loadOffersCurrentPage == 9) {
            this.state.loadOffersResult9 = result
        }

        this.state.loadOffersHasNext = (this.state.loadOffersCurrentPage + 1) * this.state.loadOffersItemsInPage < this.state.items.total
    }

    onChangeCategory(option) {
        this.state = {
            category: option.value
        }
        //console.log(this.state.category)
        //this.loadOffers()
    }

    onChangeCity(option) {
        this.state = {
            city: option.value
        }
        //console.log(this.state.city)
        //this.loadOffers()
    }

    onChangeText() {
        console.log("llamado")
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let category = parsed["category"]
        if (category != undefined) {
            this.state.category = category
        }
        this.loadOffers()
    }


    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            return <div id="loadMoreOffersAreaResult">Error: {error.message}</div>;

        } else if (!isLoaded) {
            return <div id="loadMoreOffersAreaResult"> Loading...</div>;

        } else {
            this.getOffersList()

            if (this.state.type == 2) {
                return <>
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span><i className="fa fa-fire"></i> Ofertas por categoría</span></h3>
                        </div>
                    </div>
                    {this.state.loadOffersResult0}
                    {this.state.loadOffersResult1}
                    {this.state.loadOffersResult2}
                    {this.state.loadOffersResult3}
                    {this.state.loadOffersResult4}
                    {this.state.loadOffersResult5}
                    {this.state.loadOffersResult6}
                    {this.state.loadOffersResult7}
                    {this.state.loadOffersResult8}
                    {this.state.loadOffersResult9}

                    {this.state.loadOffersResult9 == null && this.state.loadOffersHasNext ? (
                        <div className="col-12">
                            <div className="d-flex justify-content-center mt-3">
                                <Button onClick={() => { this.loadMoreOffers() }}
                                        variant="primary"><i className="fas fa-plus"></i> Cargar más Ofertas!</Button>

                            </div>
                        </div>
                    ) : ""}
                    </>
            } else if (this.state.type == 1) {
                if (this.state.items.data != null) {
                    return <>
                        <div className="col-12">
                            <div className="block-heading-two">
                                <h3><span><i className="fa fa-fire"></i> Ofertas de la empresa</span></h3>
                            </div>
                        </div>
                        {this.state.loadOffersResult0}
                        {this.state.loadOffersResult1}
                        {this.state.loadOffersResult2}
                        {this.state.loadOffersResult3}
                        {this.state.loadOffersResult4}
                        {this.state.loadOffersResult5}
                        {this.state.loadOffersResult6}
                        {this.state.loadOffersResult7}
                        {this.state.loadOffersResult8}
                        {this.state.loadOffersResult9}

                        {this.state.loadOffersResult9 == null && this.state.loadOffersHasNext ? (
                            <div className="col-12">
                                <div className="d-flex justify-content-center mt-3">
                                    <Button onClick={() => { this.loadMoreOffers() }}
                                            variant="primary"><i className="fas fa-plus"></i> Cargar más Ofertas!</Button>

                                </div>
                            </div>
                        ) : ""}
                    </>

                } else {
                    return <></>
                }
            } else if (this.state.type == 3) {
                if (this.state.items.data != null) {
                    return <>
                        <div className="col-12">
                            <div className="block-heading-two">
                                <h3><span><i className="fa fa-fire"></i> Ofertas en la misma Ciudad y Categoría</span>
                                </h3>
                            </div>
                        </div>
                        {this.state.loadOffersResult0}
                        {this.state.loadOffersResult1}
                        {this.state.loadOffersResult2}
                        {this.state.loadOffersResult3}
                        {this.state.loadOffersResult4}
                        {this.state.loadOffersResult5}
                        {this.state.loadOffersResult6}
                        {this.state.loadOffersResult7}
                        {this.state.loadOffersResult8}
                        {this.state.loadOffersResult9}

                        {this.state.loadOffersResult9 == null && this.state.loadOffersHasNext ? (
                            <div className="col-12">
                                <div className="d-flex justify-content-center mt-3">
                                    <Button onClick={() => { this.loadMoreOffers() }}
                                            variant="primary"><i className="fas fa-plus"></i> Cargar más Ofertas!</Button>

                                </div>
                            </div>
                        ) : ""}
                    </>
                } else {
                    return <></>
                }

            } else {
                return <></>
            }
        }
    }
}

function SaveUp(props) {
    const data = props;
    return (
        data.value ? (
            <div className="right-box-up">
                <div className="blue-box">
                    <span>Ahorro</span>
                    <h2>{data.value}</h2>
                </div>
            </div>
        ) : ""
    )
}

function Price(props) {
    const data = props;
    var priceFormated = ""
    if (data.value){
        if (Math.round(data.value)*100!=data.value*100){
            priceFormated = data.value.toFixed(2);
        }else{
            priceFormated = data.value
        }
    }
    return (
        priceFormated ? (
            <h3 style={{textAlign: "center"}}>{data.currency} {priceFormated}</h3>
        ) : ""
    )
}

function ImageThumbnail(props) {
    const data = props;
    return (
        data.value.image_1_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_1_thumbnail} alt={data.value.image_1_thumbnail}/>
        ) : data.value.image_2_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_2_thumbnail} alt={data.value.image_2_thumbnail}/>
        ) : data.value.image_3_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_3_thumbnail} alt={data.value.image_3_thumbnail}/>
        ) : data.value.image_4_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_4_thumbnail} alt={data.value.image_4_thumbnail}/>
        ) : data.value.image_5_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_5_thumbnail} alt={data.value.image_5_thumbnail}/>
        ) : data.value.image_6_thumbnail != undefined ? (
            <img className={"offer-image"} src={data.value.image_6_thumbnail} alt={data.value.image_6_thumbnail}/>
        ) : ""
    )
}

export default Offers