import React from 'react';

import './style.css'
import './carousel.css'
import './custom.css'
import './asserts/fonts/awesome/css/all.css'

import Outer from './componentes/header/outer'
import MainContainer from './componentes/body/main-container'
import Footer from './componentes/footer/footer'

document.body.className = "home"

function App() {
    return (
        <div className="App generalContainer">
            <div className="generalContent">
                <Outer/>
                <MainContainer/>
                <Footer/>
            </div>
        </div>
    );
}

export default App;
