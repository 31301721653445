import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import FieldsUser from "./fields-user.js"
import ReCAPTCHA from "react-google-recaptcha";

class RegistryUserLevel extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span>Tipo de Registro</span></h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div key={"category-box-1"} className="category-box" style={{marginBottom:'0px'}}>
                            <header className={"category-header clearfix pakage_standar"} style={{maxWidth:500}}>
                                <a href="#">
                                    <div className="category-icon"><i className="fas fa-user-crown"></i></div>
                                    <div className="category-title text-truncate" style={{paddingTop:'5%'}}>
                                        <h5>Usuario Beneficiario</h5>
                                    </div>
                                </a>
                            </header>
                            <div className="info-this" style={{maxWidth:500, padding: 15}}>
                                Todos los usuarios son invitados y pueden acceder las ofertas de las empresas, ser un usuario registrado agrega más beneficios: <br />
                                + Marque tipos de ofertas sobre las que quiere ser alertado cuando surja una oportunidad.<br />
                                + Guarde sus empresas favoritos.<br />
                                + Agregue calificaciones y comentarios sobre ofertas.<br />

                                <div className={"text-center"}>
                                    <div className={"text-center"}>
                                        <a  className="btn btn-primary mt-2" href="/registry-user" role="button">Registrar Beneficiario</a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div key={"category-box-1"} className="category-box " style={{marginBottom:'0px'}} >
                            <header className={"category-header clearfix pakage_standar"} style={{maxWidth:500}}>
                                <a href="#">
                                    <div className="category-icon"><i className="fas fa-building"></i></div>
                                    <div className="category-title text-truncate" style={{paddingTop:'5%'}}>
                                        <h5>Usuario Empresarial</h5>
                                    </div>
                                </a>
                            </header>
                            <div className="info-this" style={{maxWidth:500, padding: 15}}>
                                Promocione y permita que sus clientes compren sus ofertas.<br />
                                Destaque su negocio productos y servicios.<br />
                                Nuestra misión es promover el negocio y favorecer la industria, por esa razón otorgamos
                                nuestra capa Gratuita! los primeros 6 meses. <br />



                                <br />

                                <div className={"text-center "}>
                                    <a  className="btn btn-primary mt-2" href="/registry-user?level=3" role="button">Registrar Empresa</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default RegistryUserLevel