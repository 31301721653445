import React from 'react'
import axios, {post} from 'axios';
import {ErrorMessage} from "formik";
import Mailto from "react-protected-mailto";

class FileUploadPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serviceFileField: "file",
            imagePath: null,
            serviceUpload: null,
            fieldName: null,
            touched: false,
            required: false,
            checkValidation: false
        }

        this.state.serviceUpload = props.serviceimagedestination
        this.state.imagePath = props.imageSrc
        this.state.fieldName = props.imageFormId
        this.state.required = props.required
        this.state.checkValidation = props.checkValidation

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.fileReset = this.fileReset.bind(this)

        this.handleTouch = () => {
            this.setState({touched: true});
        }
    }

    onFormSubmit(e) {
        e.preventDefault()
        this.handleTouch()
        this.fileUpload(this.state.file).then((response) => {
            console.log(response)
            this.setState({
                imagePath: response.data.imagePath,
            });

            this.props.notifyimageuploadresultform(this.state.fieldName, response.data.imagePath);
        })
    }

    onChange(e) {
        this.setState({file: e.target.files[0]})
        e.preventDefault()
    }

    fileUpload(file) {
        const formData = new FormData();
        formData.append(this.state.serviceFileField, file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return post(this.state.serviceUpload, formData, config)
    }

    fileReset() {
        this.props.notifyimageuploadresultform(this.state.fieldName, "");
        document.getElementById(this.state.fieldName + "_").value = ""
        this.state.imagePath = ""
        this.handleTouch()
    }

    render() {
        return (
            <div className="input-group ">
                {
                    this.state.imagePath == '' || this.state.imagePath == undefined ? "" :
                        <img src={this.state.imagePath} className='img-thumbnail mt-1 mb-1' width="100%"/>
                }

                <form
                    className={'rounded-sm p-1 text-left ' + (this.state.touched && this.state.required &&
                    (this.state.imagePath == '' || this.state.imagePath == undefined)
                        ? ' border-danger' : 'p-1')}>
                    <input type="file" style={{withWidth: "100%"}} onChange={this.onChange}
                           id={this.state.fieldName + "_"}/>
                    <button className="mt-1" onClick={this.onFormSubmit} type="submit">Subir</button>
                    <button className="mt-1 ml-2" type="button" onClick={this.fileReset}>Limpiar</button>
                </form>

                {((this.state.touched || this.state.checkValidation == true) && this.state.required &&
                    (this.state.imagePath == '' || this.state.imagePath == undefined)) ? (
                    <p className="text-danger"><small>Esta imágen es requerida</small></p>
                ) : ""
                }

            </div>
        )
    }
}

export default FileUploadPreview