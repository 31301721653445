import React from "react"
import { string } from "prop-types";

class PackageSuccess extends React.Component {
    render() {
      return <><div className="col-12">
                  <div className="block-heading-two">
                    <h3><span><i className="fa fa-tag"></i> Proceso de compra completo</span></h3>
                    <div>Proceso de compra de paquete exisoto. 
                      Muy pronto no pondremos en contacto. 
                      En caso de necesitar más información visite: <a href="/contact">Contacto</a>.</div>
                  </div>
            </div></>

    }
}

export default PackageSuccess