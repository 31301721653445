import React from "react"
import CategoriesFilter from "./categories-filter"
import CitiesFilter from "./cities-filter"
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

class Filers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textTmp: null,
      text: null,
      category: null,
      categoryName: null,
      categoryNameTmp: null,
      city: null,
      cityName: null,
      cityNameTmp: null
    }

    this.onChangeText = this.onChangeText.bind(this)
    this.onChangeCategory = this.onChangeCategory.bind(this)
    this.onChangeCity = this.onChangeCity.bind(this)
    this.keyPressedText = this.keyPressedText.bind(this)
  }

  onChangeText(event) {
    this.setState({text: event.target.value});
  }

  onChangeCategory(categoryID) {
    this.state.category = categoryID.value
    this.setState({
      categoryNameTmp: categoryID.label.replace("-----", "").replace("Ingrese...", "")
    });
  }

  onChangeCity(city) {
    this.state.city = city.value
    this.setState({
      cityNameTmp: city.label.replace("Ingrese...", "")
    });
  }

  keyPressedText(event) {
    if (event.key === "Enter") {
      this.applyFilter()
    }
  }

  applyFilter(event) {
    this.props.callApplyFiltersOffers(this.state.text, this.state.category, this.state.city)
    this.props.callApplyFiltersCategories(this.state.text, this.state.category, this.state.city)
    this.props.callApplyFiltersEnterprises(this.state.text, this.state.category, this.state.city)
  }

  render() {
    return (
        <>
          <div className="container">
            <div className="row filter-box p-3 ml-1">
              <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6 align-self-start">
                <span className={"d-none d-lg-block"}><strong>¿Que estás buscando?</strong></span>
                <span
                    className={"d-lg-none"}><strong>¿Buscando?</strong></span>
                <input autoFocus className="form-control inputFindByText" type="text"
                       placeholder="Ingrese..." value={this.state.textTmp}
                       onKeyPress={this.keyPressedText}
                       onChange={this.onChangeText}/>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6 align-self-start">
                <strong>Categoría</strong>

                <CategoriesFilter showAll={1} onChangeCategory={this.onChangeCategory}/>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 align-self-start">
                <strong>Ciudad</strong>
                <CitiesFilter onChangeCity={this.onChangeCity}/>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6 align-self-end">
                <div className="d-sm-none" style={{height: "10px"}}></div>
                <Button onClick={() => {

                  /*this.setState({
                    text: this.state.textTmp,
                    categoryName: this.state.categoryNameTmp,
                    cityName: this.state.cityNameTmp
                  });*/

                  this.applyFilter()
                }

                } variant="primary"><i className="fas fa-search"></i> Aplicar filtro</Button>
              </div>
            </div>

          </div>

          {this.state.categoryName || this.state.cityName ? (


              <Breadcrumb>
                <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>


                {this.state.categoryName ? (
                    <Breadcrumb.Item active>{this.state.categoryName}</Breadcrumb.Item>
                ) : ""}

                {this.state.cityName ? (
                    <Breadcrumb.Item active>{this.state.cityName}</Breadcrumb.Item>
                ) : ""}


              </Breadcrumb>) : ""}
        </>
    );
  }
}

export default Filers