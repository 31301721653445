import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import FieldsUser from "./fields-user.js"
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const qs = require('query-string');
class RegistryUser extends React.Component {
    constructor(props) {
        super(props);
        const parsed = qs.parse(window.location.search.substring(1));
        let isCompany = parsed["level"] == 3
        this.state = {
            termsConditionsAccepted: false,
            recaptchaOk: false,
            isCompany: isCompany,
            categoryId:0,
            disabledButton: false,

            successAlertOpen: false,
            successText: "Registro de usuario correcto",

            errorAlertOpen: false,
            errorText: "Error al intentar guardar los datos",
        }
        this.setCategory = this.setCategory.bind(this)
        this.notifySendForm= this.notifySendForm.bind(this)

        this.categoryRefElementNotify = React.createRef()
    }


    processResult = (response) => {
        if (response.ok) {
            this.interval = setInterval(() => this.setState({successAlertOpen: false}), 14000);
            this.setState({successAlertOpen: true})

            response.text().then(async res => {
                var text = await JSON.parse(res)
                this.setState({successText: text})
            })
        } else {
            this.interval = setInterval(() => this.setState({errorAlertOpen: false}), 14000);
            this.setState({errorAlertOpen: true})
            response.text().then(async res => {
                var text = await JSON.parse(res)
                this.setState({errorText: text})
                this.setState({disabledButton: false})
            })

            this.interval = setInterval(() => this.setState({errorAlertOpen: false}), 14000);
        }
        this.setState({disabledButton: false})
        return response;
    }

    setCategory = (obj) => {
        this.setState({categoryId: obj.value})
    }

    setRecaptchaOk = () => {
        this.setState({recaptchaOk: true})
    }

    notifySendForm = (event) => {
        if (this.state.isCompany){
            this.categoryRefElementNotify.current.notifySendForm()
            if (document.getElementById("categorySelectedId").value<=0 ){
                event.preventDefault();
                return
            }
        }
    }

    handleAcceptTermsConditions = event => this.setState({termsConditionsAccepted: event.target.checked})

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading-two">
                            <h3><span>Registro de {this.state.isCompany ?" Empresa":" Beneficiario"}</span></h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <Formik
                            initialValues={{
                                userFullName: '',
                                userEmail: '',
                                userChurch: '',
                                userRuc: '',
                                userPassword: '',
                                userPasswordConfirmation: '',
                                isCompany: this.state.isCompany,
                                categoryId: this.state.categoryId,
                            }}
                            validationSchema={Yup.object().shape({
                                userFullName: Yup.string().required('El nombre completo del usuario es requerido.'),
                                userRuc: Yup.string().when('isCompany', {
                                    is: true,
                                    then: Yup.string().min(10, 'El número de CI / RUT debe contener entre 10 y 13 caracteres').max(13, 'El número de CI / RUT debe contener entre 10 y 13 caracteres').required('El número de RUC / CI es requerido.')
                                }),
                                userPassword: Yup.string().min(6, 'La clave debe tener almenos 6 dígitos').required('Contraseña de usuario es requerida.'),
                                userPasswordConfirmation: Yup.string().oneOf([Yup.ref('userPassword'), null], 'Confirmación y contraseña no son iguales.').required('Confirmación de contraseña es requerida.'),
                            })}

                            render={({errors, touched, setFieldValue, processResult}) => (

                                <Form encType="multipart/form-data">
                                    <FieldsUser
                                            touched={touched}
                                            errors={errors}
                                            isCompany={this.state.isCompany}
                                            setFieldValue={setFieldValue}
                                            ref={this.categoryRefElementNotify}
                                    />

                                    <div className="form-row">
                                        <div className="col mt-4 mb-3">
                                            <div className="form-check mt-1">
                                                <input
                                                    name="terms"
                                                    className="form-check-input checkbox-1x"
                                                    type="checkbox"
                                                    checked={this.state.termsConditionsAccepted}
                                                    onChange={this.handleAcceptTermsConditions}/>
                                                <label className="form-check-label" htmlFor="terms"> Acepto los
                                                    <a href={process.env.PUBLIC_URL + "/terms-conditions"} target="_blank"> Terminos y
                                                        Condiciones</a> de laguiamundial.com [{this.state.error}]</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-row mt-4">
                                        <div className="col-md-5 mb-3">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                                                onChange={this.setRecaptchaOk}/>
                                        </div>
                                    </div>
                                    <div className="form-row mt-4">
                                        <div className="col-md-5 mb-3">
                                            <button
                                                onClick={this.notifySendForm}
                                                id="actionSend" type="submit"
                                                    disabled={!this.state.disabledButton && this.state.recaptchaOk && this.state.termsConditionsAccepted ? "" : "disabled"}
                                                className="btn btn-primary mr-2">Registrar</button>

                                            <button type="reset" className="btn btn-secondary">Reiniciar</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                            onSubmit={fields => {
                                this.setState({
                                    disabledButton: true,
                                })
                                fetch(process.env.PUBLIC_URL + '/v1/services/registry-user', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    body: JSON.stringify(fields, null, 4),
                                }).then(this.processResult)
                            }}
                        />
                    </div>
                </div>

                <Modal show={this.state.successAlertOpen}>
                    <Modal.Header closeButton className={"alert-success"}>
                        <Modal.Title>
                            Usuario registrado con éxito
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"alert-success"}>
                        <div className="alert" role="alert">
                            {this.state.successText}.
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => this.setState({successAlertOpen: false})} variant="outline-success">
                                Cerrar
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.errorAlertOpen}>
                    <Modal.Header closeButton className={"alert-danger"}>
                        <Modal.Title>
                            Error de registro
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"alert-danger"}>
                        <div className="alert" role="alert">
                            {this.state.errorText}.
                        </div>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => this.setState({errorAlertOpen: false})} variant="outline-danger">
                                Cerrar
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )

    }
};

export default RegistryUser