import React from "react"
import Select from 'react-select';
import {string} from "prop-types";

class CategoriesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            selectedOption: props.selectedOption,
            showAll: props.showAll != undefined ? props.showAll : false,
            selectableBase: props.selectableBase,
            title: props.title != undefined ? props.title : "Ingrese...",
            requireMessageError: props.requireMessageError,
            validate: false
        };
    }

    validate = () => {
        this.setState(
            {
                validate: true,
            }
        );
    };

    handleChange = selectedOption => {
        this.setState(
            {selectedOption},
            () => this.props.onChangeCategory(this.state.selectedOption)
        );
    };

    componentDidMount() {
        var showAllParm = ""
        if (this.props.showAll) {
            showAllParm = "&showall=1"
        }
        fetch(process.env.PUBLIC_URL + `/v1/services/categories?expand=1` + showAllParm)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const {error, isLoaded, selectedOption, selectableBase} = this.state;
        const customStyles = {
            container: () => ({
                border: selectedOption && selectedOption.value ? '0px' : this.state.validate ? '1px solid #dc3545' : '0px',
                borderRadius: '4px'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isDisabled ? '#e9ecef' : '',
                color: state.isDisabled ? '#495057' : '#495057',
            })
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            let options

            if (this.state.items.data != undefined) {
                options = this.state.items.data.map(item => (
                    {
                        value: item.id,
                        label: item.name,
                        isDisabled: selectableBase == false && item.name.indexOf("---") == -1
                    }
                ))
                options.unshift({value: null, label: this.state.title})
                console.log(this.state)
                return <><Select placeholder={this.state.title} options={options} onChange={this.handleChange}
                                 styles={customStyles}
                                 value={options.find(option => option.value == selectedOption)}/>

                    {this.state.validate && this.state.requireMessageError != "" && (selectedOption == undefined || !selectedOption.value) ?
                        <div className="invalid-feedback d-block">{this.state.requireMessageError}</div>
                        : <></>}
                </>
            }
            return <><Select placeholder={this.state.title}/></>
        }
    }
}

export default CategoriesFilter