import React, {useState} from "react"
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Moment from 'react-moment';
import {string} from "prop-types";
import OfferEditor from './offer-editor'
import Toggle from "react-toggle";
import Button from "react-bootstrap/Button";

class CouponsAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            counter: 1,
            domElementId: "",
            packageIdNoOffers: 1,
            code: "",
        };
        this.onChangeCodeText = this.onChangeCodeText.bind(this)
        this.loadCoupons  = this.loadCoupons.bind(this)
    }

    componentDidMount() {
        this.loadCoupons()
    }

    loadCoupons() {
        fetch(process.env.PUBLIC_URL + `/v1/services/coupons-book?code=` + this.state.code)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    handleToggleCoupon(event) {
        let code = event.target.value
        if (code == "") {
            return
        }

        let fields = {code: code, used: event.target.checked}
        fetch(process.env.PUBLIC_URL + '/v1/services/coupon', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify(fields, null, 4),
        })
            .then((response) => {
                  /*  if (response.status < 300) {
                        this.setState({
                            sendEnabled: true,
                            actionSuccessful: true,
                            actionResult: "Gracias por enviarnos su consulta, en breve le enviaremos una respuesta."
                        })
                    } else {
                        this.setState({
                            sendEnabled: true,
                            actionSuccessful: false,
                            actionResult: "Se produjo un error al intentar enviar los datos. Por favor intente más tarde."
                        })
                    }*/
                    response.json()

                },

                (error) => {
                    this.setState({
                       /* sendEnabled: true,
                        actionSuccessful: false,
                        actionResult: "Se produjo un error al intentar enviar los datos. Por favor intente más tarde."*/
                    })
                }

            );

    }

    onChangeCodeText(event) {
        console.log(event.target.value)
        this.setState({code: event.target.value});
    }

    onKeyPressCodeText(event) {
        if (event.key === "Enter") {
            this.loadCoupons()
        }
    }

    clickx = (domElementId) => {

        setTimeout(function () {
            document.getElementById(domElementId).click();
            setTimeout(function () {
                var rec = document.getElementById(domElementId).getBoundingClientRect();
                window.scrollTo(0, rec.top + window.scrollY);
            }, 500);
        }, 500);

    }

    render() {
        let result = ""

        if (this.state.items.data && this.state.counter > 0) {
            result =
                <Accordion defaultActiveKey={this.state.items.data.length == 1 ? "package-" + 0 : ""} className="w-100">
                    <Card className="w-100">
                        <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="dark" className="w-100">
                                <div className="container">
                                    <div className="row bg-secondary text-white">

                                        <div className="col-2 d-block d-sm-block d-md-none d-lg-none"></div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Cuponera</div>


                                        <div className="col-3">Nombre</div>

                                        <div className="col-2 d-block d-sm-block d-md-none d-lg-none"></div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Descripción</div>

                                        <div className="col-2 d-block d-sm-block d-md-none d-lg-none">Desde</div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Activa desde</div>


                                        <div className="col-2 d-block d-sm-block d-md-none d-lg-none">Hasta</div>
                                        <div className="col-2 d-none d-sm-none d-md-block d-lg-block">Activa hasta</div>

                                        <div className="col-1 d-none d-sm-none d-md-block d-lg-block">Tamaño</div>

                                    </div>
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>

                    {this.state.items.data.map((item, index) => (
                        <Card className="w-100" key={"car" + Math.random()}>
                            <Card.Header>
                                <Accordion.Toggle as={Card.Header} variant="light" eventKey={"package-" + index}
                                                  className="w-100">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-2">CAMP-{item.id}</div>
                                            <div className="col-3">
                                                    {item.name}
                                            </div>
                                            <div className="col-2">{item.description}</div>
                                            <div className="col-2">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.date_from}
                                                </Moment>
                                            </div>
                                            <div className="col-2">
                                                <Moment format="DD/MM/YYYY">
                                                    {item.date_to}
                                                </Moment>
                                            </div>
                                            <div className="col-1 d-none d-sm-none d-md-block d-lg-block">
                                                    {item.size}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Card.Body>


                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey={index + "-offer-h"}
                                                          variant="light">
                                            <div className="container">
                                                <div className={"row bg-info text-white"}>
                                                    <div className="col-6">Código</div>

                                                    <div className="col-6">Marcar como usado</div>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                </Card>

                            {
                                item.coupons.map(
                                    (coupon, coup) => (

                                        <div className="row border-bottom">
                                            <div className="col-6">{coupon.code}</div>

                                            <div className="col-6">
                                                <Toggle id={"toggle_"+coupon.id} defaultChecked={coupon.used} icons={false}
                                                        onChange={this.handleToggleCoupon}
                                                        value={coupon.code}
                                                />
                                            </div>
                                        </div>
                                    )
                                )
                            }
                            </Card.Body>
                        </Card>
                    ))}
                </Accordion>
        }

        return <>
            <div className="col-12">
                <div className="block-heading-two">
                    <h3><span><i className="fa fa-fire"></i> Mis Cuponeras</span></h3>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-2">
                        <span><strong>Cupón:</strong></span>
                    </div>


                    <div className="col-6 d-block d-sm-block d-md-none d-lg-none">
                        <input autoFocus type="text" placeholder="Código..."
                               onChange={this.onChangeCodeText}
                               onKeyPress={this.onKeyPressCodeText}/>
                    </div>
                    <div className="col-2 d-none d-sm-none d-md-block d-lg-block">
                        <input autoFocus type="text" placeholder="Código..."
                               onChange={this.onChangeCodeText}
                               onKeyPress={this.onKeyPressCodeText}/>
                    </div>

                    <div className="col-2">
                        <Button onClick={() => {
                           this.loadCoupons()
                        }}>Buscar</Button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                {result}
            </div>
            <div className="col-12 mt-3 ">
                <div className="alert alert-warning" role="alert">
                    Esta herramienta está diseñada para atraer clientes directamente a su comercio, comuníquese
                    <a href={process.env.PUBLIC_URL + "/contact"}> aquí</a> para obtener una nueva cuponera.
                </div>
            </div>
        </>
    }
};

export default CouponsAdmin