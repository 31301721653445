import React from "react"
import Email from "../shared/email"
import Social from "./social"
import Header2 from "./header2"
import SliderMain from "./slidermain"

class Topbar extends React.Component {
    render() {
        return (
            <div className="top-bar">
                <div className="container">
                    <Social />
                    <div className="clearfix"></div>
                    <Header2/>
                    <SliderMain />
                </div>
            </div>
        );
    }
}

export default Topbar