import React from "react"
import {string} from "prop-types";
import CategoriesParent from "./categories-parent";

class CategoriesParentList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let result = ""

        return <>
            <CategoriesParent ref={instanceCategory => {
                this.contentCategory = instanceCategory;
            }} title={"Categorías"} showAll={1}/>
        </>
    }
}

export default CategoriesParentList