import React from "react"
import {Dropdown} from 'react-bootstrap';

class Header2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogged: false,
            userCompany: false
        }
    }

    componentDidMount() {
        fetch(process.env.PUBLIC_URL + '/v1/services/check-user', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                return response.json();})
            .then(responseData => {console.log(responseData); return responseData;})
            .then(data => {
                this.setState(
                    {"userLogged" : data.is_logged == true, "userCompany" : data.is_company == true},
                    );
            })
            .catch(err => {
                console.log("fetch error" + err);
            });
           /* .then(res => {
                    if (res.status < 400) {
                        this.state = {
                            userLogged: true,
                        }
                    } else {
                        res.text().then(function (data) {
                          //  this.processCheck(JSON.parse(data))
                        });

                    }
                },


                (error) => {
                  //  this.processCheck("Error al intentar realizar login de usuario")
                }
            )*/

    }

    render() {
        return (
            <div className="header-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="logo">
                                <h3>
                                    <a href={process.env.PUBLIC_URL + "/"}>
                                        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} alt="Logo"
                                             style={{height: '63px'}}/>
                                    </a>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-6">
                            <div className="navy">

                                <Dropdown className="pt-3 d-md-none ">
                                    <Dropdown.Toggle variant="info" className="w-100" id="dropdown-basic">
                                        SECCIONES
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="w-100">
                                        <Dropdown.Item href={process.env.PUBLIC_URL + "/"}>Inicio{this.state.userLogged}</Dropdown.Item>
                                        <Dropdown.Item
                                            href="https://capacitacion.laguiamundial.com/">Capacitación</Dropdown.Item>
                                        <Dropdown.Item
                                            href={process.env.PUBLIC_URL + "/categories"}>Categorías</Dropdown.Item>
                                        <Dropdown.Item
                                            href={process.env.PUBLIC_URL + "/packages"}>Paquetes</Dropdown.Item>
                                        <Dropdown.Item
                                            href={process.env.PUBLIC_URL + "/contact"}>Contacto</Dropdown.Item>
                                        <Dropdown.Item
                                            href={process.env.PUBLIC_URL + "/registry"}>Registro</Dropdown.Item>


                                        <GetUserProfile type="dropdown" showItem={this.state.userCompany == false && this.state.userLogged}/>


                                        <GetLoginItem type="dropdown" showItem={this.state.userLogged==false}/>
                                        <GetUserItem type="dropdown" showItem={this.state.userCompany && this.state.userLogged}/>
                                        <GetLogOutItem type="dropdown" showItem={this.state.userLogged}/>
                                    </Dropdown.Menu>

                                </Dropdown>

                                <ul className="pull-right">
                                    <li className="active">
                                        <a href={process.env.PUBLIC_URL + "/"}>Inicio</a>
                                    </li>
                                    <li className="">
                                        <a href="https://capacitacion.laguiamundial.com/" target="_blank">Capacitación</a>
                                    </li>
                                    <li className="">
                                        <a href={process.env.PUBLIC_URL + "/categories"}>Categorías</a>
                                    </li>
                                    <li className="">
                                        <a href={process.env.PUBLIC_URL + "/packages"}>Paquetes</a>
                                    </li>
                                    <li className="">
                                        <a href={process.env.PUBLIC_URL + "/contact"}>Contacto</a>
                                    </li>
                                    <li className="">
                                        <a href={process.env.PUBLIC_URL + "/registry"}>Registro</a>
                                    </li>
                                    <GetUserItem type="web" showItem={this.state.userCompany && this.state.userLogged}/>
                                    <GetUserProfile type="web" showItem={this.state.userCompany == false && this.state.userLogged}/>
                                    <GetLoginItem type="web" showItem={this.state.userLogged != true}/>
                                    <GetLogOutItem type="web" showItem={this.state.userLogged == true}/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

function GetUserProfile(props) {
    const data = props;
    return (
        data.type == "dropdown" && data.showItem ? (
                <a href={process.env.PUBLIC_URL + "/profile"} className="dropdown-item">Perfil</a>
            ) :
            (
                data.showItem ? (
                    <li className=""><a href={process.env.PUBLIC_URL + "/profile"}>Perfil</a></li>
                ) : '')
    )
}

function GetLoginItem(props) {
    const data = props;
    return (
        data.type == "dropdown" && data.showItem ? (
                <a href={process.env.PUBLIC_URL + "/login-user"} className="dropdown-item">Ingresar</a>
            ) :
            (
                data.showItem ? (
                    <li className=""><a href={process.env.PUBLIC_URL + "/login-user"}>Ingresar</a></li>
                ) : '')
    )
}

function GetLogOutItem(props) {
    const data = props;
    return (
        data.type == "dropdown" && data.showItem ? (
                <a href={process.env.PUBLIC_URL + "/logout-user"} className="dropdown-item">Salir</a>
            ) :
            (
                data.showItem  ? (
                    <li className=""><a href={process.env.PUBLIC_URL + "/logout-user"}>Salir</a></li>
                ) : '')
    )
}

function GetUserItem(props) {
    const data = props;
    return (

        data.type == "dropdown" && data.showItem ? (
                <>
                    <a href={process.env.PUBLIC_URL + "/my-offers"} className="dropdown-item"><u>Mis Ofertas</u></a>
                    <a href={process.env.PUBLIC_URL + "/user"} className="dropdown-item">Empresa</a>
                    <a href={process.env.PUBLIC_URL + "/coupon-book"} className="dropdown-item">Mis Cuponeras</a>
                    <a href={process.env.PUBLIC_URL + "/coupons-admin"} className="dropdown-item">Cupones</a>
                </>
            ) :
            (
                data.showItem  ? (
                    <>
                        <li className=""><a href={process.env.PUBLIC_URL + "/my-offers"}><u>Mis Ofertas</u></a></li>
                        <li className=""><a href={process.env.PUBLIC_URL + "/user"}>Empresa</a></li>
                        <li className=""><a href={process.env.PUBLIC_URL + "/coupons-admin"}>Mis Cupones</a></li>
                    </>
                ) : '')
    )
}

export default Header2