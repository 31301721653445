import React from "react"

import '../../../whatsapp.css'


class WhatsApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: props.phone
        };
    }

    render() {
        return <a href={"https://api.whatsapp.com/send?phone="+this.state.phone+"&text=hola la laguiamundial.com"}
           className="whatsApp-link" target="_blank">
            <i className="fab fa-whatsapp whatsApp-icon" />
        </a>
    }
}

export default WhatsApp