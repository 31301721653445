import React from "react"
import Select from 'react-select';
import { string } from "prop-types";

class CitiesFilter extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: string,
        selectedOption: props.selectedOption
      };
    }

    handleChange = selectedOption => {
      this.setState(
        { selectedOption },
        () => this.props.onChangeCity(this.state.selectedOption)
      );
    };

    componentDidMount() {
      fetch(process.env.PUBLIC_URL + `/v1/services/cities`)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }

    render() {
      const { error, isLoaded, items, selectedOption } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div>Loading...</div>;
      } else {
        const options = items.data.map(item => (
          { value: item.id, label:  item.name}
        ))             
        options.unshift({ value: null, label: "Ingrese..." })
        return <><Select placeholder="Ingrese..." options={options} value={options.find(option => option.value == selectedOption)} onChange={this.handleChange}/></>
      }
    }
}

export default CitiesFilter