import React from "react"
import {string} from "prop-types";
import Truncate from "react-truncate";
import {Collapse} from 'react-collapse';
import Button from "react-bootstrap/Button";

function loadCategoriesParent(showAll) {
    let url = process.env.PUBLIC_URL + '/v1/services/categories-parent?showAll=' + showAll
    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}


class CategoriesParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            category: null,
            city: null,
            showAll: props.showAll == 1 ? 1 : 0,
            detailOpened: false,
        };
        this.loadCategoriesParent = loadCategoriesParent.bind(this);
    }

    componentDidMount() {
        this.loadCategoriesParent(this.state.showAll)
    }

    applyFiltersCategory = (text, category, city) => {
    }


    onDetailClick(referenceID) {
        this.setState({detailOpened: !this.state.detailOpened}, function () {

            var open = true
            var button = document.getElementById(referenceID)
            if (button.firstChild.textContent == "ver [+]") {
                open = true
                button.firstChild.textContent = "ver [-]"
            }else{
                open = false
                button.firstChild.textContent = "ver [+]"
            }

            referenceID = referenceID.replace("button_", "");
            var slides = document.getElementsByClassName(referenceID);
            for (var i = 0; i < slides.length; i++) {
                open ?
                    slides.item(i).classList.remove("d-none")
                    :
                    slides.item(i).classList.add("d-none")
            }
        })
    }

    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            result = <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            result = <div>Loading...</div>;
        } else {
            result = "Sin resultados"
            if (this.state.items.data) {
                result = this.state.items.data.map((item, index) => (
                    <div key={"category-box-" + item.id}
                         className="category-box col-lg-3 col-md-6 col-sm-6 col-xs-12 icon-box-1"
                         style={{marginBottom: '0px'}}>
                        <div className="icon-box-1-item category-item-cat text-center" style={{minHeight: '310px'}}>
                            <a href={"/category?category=" + item.id + "&category_name=" + item.name}>
                                <CatIcon icon_image={item.icon_image} icon={item.icon}></CatIcon>
                            </a>
                            <div className="category-bor bg-red"></div>
                            <h4 style={{minHeight: '40px'}}><a
                                href={"/category?category=" + item.id + "&category_name=" + item.name}>

                                    {item.name}

                                </a>
                            </h4>

                            <ul className={"text-left"}>
                                <CategoryChild categories={item.categories} showAll={this.state.showAll}
                                               parentReference={"parentReference_" + item.id}/>

                                {item.categories && item.categories.length > 5 ?
                                    <div className={"w-75 text-right"}>
                                        <Button id={"button_parentReference_" + item.id} size="sm"
                                                onClick={() => this.onDetailClick("button_parentReference_" + item.id)}
                                                variant="link">
                                            ver [+]
                                        </Button>
                                    </div>
                                    : <></>}
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                ))
            }
        }

        return <>
            <div className="col-12 mt-3">
                <div className="block-heading-two">
                    <h3><span><i className="fa fa-tag"></i> {
                        this.props.title ?
                            this.props.title : "Todas las Categorías"
                    } </span></h3>
                </div>
            </div>
            {result}</>

    }
}

export default CategoriesParent

function CatIcon(props) {
    const data = props;
    return (
        data.icon_image ? (
            <div className="category-icon"><img className="img-responsive" alt="icon_image" src={data.icon_image}></img>
            </div>
        ) : <div className="category-icon"><i className={data.icon}></i></div>
    )
}

function CategoryChild(props) {
    const data = props;

    return (

        data.categories ?
            data.categories.map((item, index) => (
                <div key={"categoryItem_" + item.id} className={index > 4 ? data.parentReference + " d-none" : ""}>
                    {data.showAll == 0 ? (
                            <a href={process.env.PUBLIC_URL + "/category?category=" + item.id + "&category_name=" + item.name}>
                                <Truncate lines={1}>
                                    {item.name}
                                </Truncate></a>
                        ) :
                        <Truncate lines={1}>
                            {item.name}
                        </Truncate>
                    }
                </div>
            ))
            : <></>)


}

