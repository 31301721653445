import React from "react"
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from "react-bootstrap/Button";
import axios from 'axios';

class FileUploadCrop extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          crop: {
              src: null,
              unit: 'px',
              x: 0,
              y: 0,
              minWidth: 370,
              maxWidth: 535,
              aspect: 16/11,
              keepSelection: true,
          },
          serviceimagedestination: props.serviceimagedestination,
          imgblob: null,
      };
    }

    finishUpload = (value) => {
        this.props.notifyimageuploadresult(value)
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    handleSubmit = e => {
        e.preventDefault()

        var photo = {
            uri: this.fileUrl,
            type: 'image/jpeg',
            name: 'photo.jpg',
        };

        var formData = new FormData();

        console.log(this.state.imgblob)
        formData.append("file", this.state.imgblob);

        axios.post(
            this.state.serviceimagedestination,
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        )
            .then(res => {
                this.finishUpload(res.data.imagePath)
            })
            .catch(err => {
                console.log(err);
            })



        /*const formData = new FormData()
        formData.append('file', this.state.imgblob)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(this.state.serviceimagedestination, formData, config)*/


/*
        var photo = {
            uri: this.fileUrl,
            type: 'image/jpeg',
            name: 'photo.jpg',
        };

        var form = new FormData();
        form.append("file", photo);

        fetch(
            this.state.serviceimagedestination,
            {
                body: form,
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )*/
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.state.imgblob = blob
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }


    componentDidMount() {

    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div>
            <input type="file" accept="image/*" onChange={this.onSelectFile} />
Seleccione una imagen desde su dispositivo y marque el área a recortar, finalmente presione en "Subir y usar imagen"


            <div className="form-row">
                <div className="col-12 text-center mt-2" >
                    {src && (
                        <ReactCrop
                            minWidth={300}
                            maxWidth={535}
                            maxHeight={520}

                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}

                        />
                    )}
                </div>
                <div className="col-12">
                    <div className="form-row ">
                        <div className="col-12 ">
                            {croppedImageUrl && (
                                <img className={"mx-auto d-block img-thumbnail "} alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                            )}
                        </div>
                    </div>

                </div>

                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12">
                            {croppedImageUrl && (
                                <Button onClick={this.handleSubmit} className="btn mt-2 w-100"><i className="fa fa-upload"></i> Subir y usar imagen</Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default FileUploadCrop
