import React from "react"
import {Field, ErrorMessage, Form} from 'formik';
import CategoriesFilter from "./categories-filter";


class FieldsUser extends React.Component {
    constructor(props) {
        super(props);
        this.handleCategoryChange = this.handleCategoryChange.bind(this)
        this.categoryRefElement = React.createRef()
        this.state = {
            categorySelectedId: 0,
        }

    }

    handleCategoryChange(obj) {
        this.props.setFieldValue('categoryId', obj.value);
        this.setState({categorySelectedId:obj.value})
    }

    notifySendForm = () => {
        if (this.props.isCompany) {
            this.categoryRefElement.current.validate()
        }
    }

    render() {
        return <>
            <div className="form-row mt-3">
                <div className="col-md-4">
                    <h5><i className="fa fa-user fa-fw"></i><span> Datos de usuario</span></h5>
                </div>
            </div>


            <div className="form-row ">
                <div className="col-md-8">
                    <label htmlFor="userEmail">Ingrese su e-mail (será usado como su nombre de usuario)</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                        </div>
                        <Field type="text" name="userEmail" placeholder="" aria-describedby="inputGroupPrepend"
                               className={'form-control' + (this.props.errors.userEmail && this.props.touched.userEmail ? ' is-invalid' : '')}/>
                        <ErrorMessage name="userEmail" component="div" className="invalid-feedback"/>
                    </div>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-8">
                    <label htmlFor="userFullName">Su
                        nombre {this.props.isCompany ? " (será visible en las ofertas que publique)" : ""}</label>
                    <Field name="userFullName" type="text"
                           className={'form-control' + (this.props.errors.userFullName && this.props.touched.userFullName ? ' is-invalid' : '')}/>
                    <ErrorMessage name="userFullName" component="div" className="invalid-feedback"/>
                </div>
            </div>


            <div className="form-row mt-3">
                <div className="col-md-4">
                    <label htmlFor="userPassword">Contraseña</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend"><div
                                className="fas fa-lock prefix"></div></span>
                        </div>
                        <Field type="password" name="userPassword" placeholder="" aria-describedby="inputGroupPrepend"
                               className={'form-control' + (this.props.errors.userPassword && this.props.touched.userPassword ? ' is-invalid' : '')}/>
                        <ErrorMessage name="userPassword" component="div" className="invalid-feedback"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label htmlFor="userPasswordConfirmation">Confirmación de contraseña</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend"><div
                                className="fas fa-lock prefix"></div></span>
                        </div>
                        <Field type="password" name="userPasswordConfirmation" placeholder=""
                               className={'form-control' + (this.props.errors.userPasswordConfirmation && this.props.touched.userPasswordConfirmation ? ' is-invalid' : '')}/>
                        <ErrorMessage name="userPasswordConfirmation" component="div" className="invalid-feedback"/>
                    </div>
                </div>
            </div>
            {this.props.isCompany ?
                <>
                    <div className="form-row mt-3">
                        <div className="col-md-4">
                            <label htmlFor="userRuc">Número de CI / RUC de su empresa (sri.gob.ec)</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend"><div
                                        className="fa fas fa-building fa-fw"></div></span>
                                </div>
                                <Field type="text" maxlength="13" name="userRuc" placeholder=""
                                       aria-describedby="inputGroupPrepend"
                                       className={'form-control' + (this.props.errors.userRuc && this.props.touched.userRuc ? ' is-invalid' : '')}/>
                                <ErrorMessage name="userRuc" component="div" className="invalid-feedback"/>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="userPasswordConfirmation">Nombre de la Iglesia Cristiana a la que
                                asiste</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend"><div
                                        className="fas fa-church"></div></span>
                                </div>
                                <Field type="text" name="userChurch" placeholder=""
                                       className={'form-control' + (this.props.errors.userChurch && this.props.touched.userChurch ? ' is-invalid' : '')}/>
                                <ErrorMessage name="userPasswordConfirmation" component="div"
                                              className="invalid-feedback"/>
                            </div>
                        </div>
                    </div>

                    <div className="form-row mt-3">
                        <div className="col-md-8">
                            <label htmlFor="userRuc">Categoría de su empresa</label>
                            <CategoriesFilter ref={this.categoryRefElement} requireMessageError='Es necesario seleccionar la categoría de su Empresa' title="Seleccione una categoría" selectableBase={false} onChangeCategory={this.handleCategoryChange} showAll={true}/>
                            <Field type="hidden" value={this.state.categorySelectedId?this.state.categorySelectedId:0} id={"categorySelectedId"}/>
                        </div>
                    </div>
                </>
                : ""
            }
        </>
    }
}

export default FieldsUser