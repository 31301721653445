import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";

const LoginUser = ({
                       verifyCallback = (recaptchaToken) => {
                           document.getElementById("actionSend").disabled = false;
                       },
                       redirectToCompany = () => {
                           window.location.replace(process.env.PUBLIC_URL + "/user");
                       },
                       redirectToUserProfile = () => {
                           window.location.replace(process.env.PUBLIC_URL + "/");
                       },
                       setMessage = (message, isError) => {
                           var elm = document.getElementById("resultMessage")
                           elm.innerHTML = message
                           if (isError) {
                               elm.classList.add("text-danger");
                           } else {
                               elm.classList.remove("text-danger");
                           }
                       }
                   }) => (

    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="block-heading-two">
                    <h3><span>Ingreso</span></h3>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('El nombre de usuario es inválido.').required('Nombre de usuario es requerido.'),
                        password: Yup.string().required('La contraseña de usuario es requerida.'),
                    })}

                    render={({errors, touched}) => (
                        <Form encType="multipart/form-data">
                            <div className="form-row mt-3">
                                <div className="col-md-8">
                                    <hr className="my-4"></hr>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-4">
                                    <h5><i className="fa fa-user fa-fw"></i><span> Ingreso de usuario</span></h5>
                                </div>
                            </div>

                            <div className="form-row mt-3">
                                <div className="col-md-8">
                                    <label htmlFor="userEmail">Nombre de usuario</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                                        </div>
                                        <Field type="text" name="email" placeholder="Ingrese su e-mail"
                                               aria-describedby="inputGroupPrepend"
                                               className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row mt-3">
                                <div className="col-md-4">
                                    <label htmlFor="userPassword">Contraseña</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend"><div
                                                className="fas fa-lock prefix"></div></span>
                                        </div>
                                        <Field type="password" name="password" placeholder="Contraseña"
                                               aria-describedby="inputGroupPrepend"
                                               className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-md-4">
                                    <span><a
                                        href={process.env.PUBLIC_URL + "/forgot-password"}>¿Olvidó su Contraseña?</a></span>
                                </div>
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-md-4 mb-3">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                                        onChange={verifyCallback}/>
                                </div>
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-md-4 mb-3">
                                    <button disabled={process.env.REACT_APP_ENV != "dev" ? true : false} id="actionSend"
                                            type="submit"
                                            className="btn btn-primary mr-2">Ingresar
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                    onSubmit={
                        fields => {
                            var successfull = false
                            setMessage("Enviado datos", false)
                            fetch(process.env.PUBLIC_URL + '/v1/services/login-user', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                body: JSON.stringify(fields, null, 4),
                            })

                                .then(response => {
                                    if (response.status < 400) {
                                        successfull = true
                                    }
                                    return response.json()
                                })
                                .then(data => {
                                    if (successfull == true) {

                                        if (data.is_company == true) {
                                            redirectToCompany()
                                        } else {
                                            redirectToUserProfile()
                                        }
                                    } else {
                                        setMessage(data, true)
                                    }
                                })
                                .catch(err => {
                                    console.log("fetch error" + err);
                                });
                        }
                    }
                        />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-12">
                        <div className="text-danger" id="resultMessage"></div>
                        </div>
                        </div>
                        </div>
                        );

                        export default LoginUser