import React from "react"

import '../../../home.css'


class GoHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: props.phone
        };
    }

    render() {
        return <a href={"/"}
           className="home-link" target="_top">
            <i className="far fa-home whatsApp-icon" />
        </a>
    }
}

export default GoHome