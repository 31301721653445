import React from "react"
import {string} from "prop-types";
import Rater from 'react-rater'
import Truncate from "react-truncate";
import Button from "react-bootstrap/Button";

const qs = require('query-string');

function loadEnterprises(category) {
    console.log(this.state)
    let url = process.env.PUBLIC_URL + '/v1/services/enterprises'
    let sep = '?'
    if (this.state.text) {
        url += `${sep}text=${this.state.text}`
        sep = "&"
    }
    if (this.state.category) {
        url += `${sep}category=${this.state.category}`
        sep = "&"
    }
    if (this.state.city) {
        url += `${sep}city=${this.state.city}`
        sep = "&"
    }
    if (this.state.lastAdditions) {
        url += `${sep}last_additions=12`
        sep = "&"
    }

    url += `${sep}offset=${this.state.loadCompaniesCurrentPage * this.state.loadCompaniesItemsInPage}`
    sep = "&"
    url += `${sep}limit=${this.state.loadCompaniesItemsInPage}`

    fetch(url)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
}

class Enterprises extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadCompaniesCurrentPage: 0,
            loadCompaniesItemsInPage: 72,
            error: null,
            isLoaded: false,
            items: string,
            text: null,
            category: null,
            city: null,
            selectedOption: 0,
            loadCompaniesResult0: null,
            loadCompaniesResult1: null,
            loadCompaniesResult2: null,
            loadCompaniesResult3: null,
            loadCompaniesResult4: null,
            loadCompaniesResult5: null,
            loadCompaniesResult6: null,
            loadCompaniesResult7: null,
            loadCompaniesResult8: null,
            loadCompaniesResult9: null,
            loadCompaniesHasNext: true,
            title: props.title,
            lastAdditions: props.lastAdditions,
        };

        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.loadEnterprises = loadEnterprises.bind(this);
    }

    applyFilters = (category, city) => {
        this.state.category = category
        this.state.city = city
        this.loadEnterprises()
    }

    onChangeText(value) {
        this.setState({
            text: value
        });
    }

    onChangeCategory(option) {
        this.setState({
            category: option.value
        });
    }

    applyFiltersEnterpries = (text, category, city) => {
        this.state.text = text
        this.state.category = category
        this.state.city = city
        this.loadEnterprises()
    }

    onChangeCity(option) {
        this.setState({
            city: option.value
        });
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let category = parsed["category"]
        this.setState({
            category: category
        }, function() {
            this.loadEnterprises()
        });
    }

    loadMoreCompanies = () => {
        this.state.loadCompaniesCurrentPage = this.state.loadCompaniesCurrentPage + 1
        this.loadEnterprises()
    }

    render() {
        let result = ""
        const {error, isLoaded, items} = this.state;
        if (error) {
            result = <div id="loadMoreCompaniesAreaResult">Error: {error.message}</div>;
        } else if (!isLoaded) {
            result = <div id="loadMoreCompaniesAreaResult">Loading...</div>;
        } else {
            result = "Sin resultados"
            if (this.state.items.data) {
                result = this.state.items.data.map(item => (
                    <div key={item.name} className="col-lg-2 col-md-6 col-sm-6 col-xs-12 img-box-4 text-center">
                        <div className="img-box-4-item">
                            <div className="image-style-one enterprise-image-container vertical-center">
                                <a href={"enterprise-detail?id=" + item.id}>
                                    <Image value={item.image_1_thumbnail}/>
                                </a>
                            </div>

                            <div className="img-box-4-content">
                                <div className={"enterprise-text-container vertical-center"}>
                                    <a className="b-tooltip" title={item.name} href={"category?id=" + item.id}>
                                        <CatIcon category_icon={item.category_icon}
                                                 category_icon_image={item.category_icon_image}/>
                                    </a>
                                    <div className="cat-title">{item.category_name}</div>
                                    <a href={"enterprise-detail?id=" + item.id}>
                                        <h4 className="item-title">
                                            <Truncate lines={2}>
                                                <a href={"enterprise-detail?id=" + item.id}>{item.name}</a>
                                            </Truncate>
                                        </h4>
                                    </a>
                                    <div className="bor bg-red"></div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 info-dta info-price">
                                            <Rate value={item.rate}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 info-dta"
                                             style={{height: "28px"}}>
                                            <City value={item.city_name}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 info-dta info-phone"
                                             style={{height: "28px"}}>
                                            <Phone value={item.phone}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 info-dta"
                                             style={{height: "28px"}}>
                                            <Phone value={item.phone2}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 info-dta blink"
                                             style={{height: "28px"}}>
                                            <Coupon value={item.has_coupon_book}/>
                                        </div>
                                    </div>

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }


            if (this.state.loadCompaniesCurrentPage == 0) {
                this.state.loadCompaniesResult0 = result
            } else if (this.state.loadCompaniesCurrentPage == 1){
                this.state.loadCompaniesResult1 = result
            } else if (this.state.loadCompaniesCurrentPage == 2){
                this.state.loadCompaniesResult2 = result
            } else if (this.state.loadCompaniesCurrentPage == 3){
                this.state.loadCompaniesResult3 = result
            } else if (this.state.loadCompaniesCurrentPage == 4){
                this.state.loadCompaniesResult4 = result
            } else if (this.state.loadCompaniesCurrentPage == 5){
                this.state.loadCompaniesResult5 = result
            } else if (this.state.loadCompaniesCurrentPage == 6){
                this.state.loadCompaniesResult6 = result
            } else if (this.state.loadCompaniesCurrentPage == 7){
                this.state.loadCompaniesResult7 = result
            } else if (this.state.loadCompaniesCurrentPage == 8){
                this.state.loadCompaniesResult8 = result
            } else if (this.state.loadOffersCurrentPage == 9) {
                this.state.loadCompaniesResult9 = result
            }

            this.state.loadCompaniesHasNext = (this.state.loadCompaniesCurrentPage + 1) * this.state.loadCompaniesItemsInPage < this.state.items.total
        }

        return <>
            <div className="col-12">
                <div className="block-heading-two">
                    <h3><span><i className="fas fa-building"></i> {this.state.title}</span></h3>
                </div>
            </div>
            {this.state.loadCompaniesResult0}
            {this.state.loadCompaniesResult1}
            {this.state.loadCompaniesResult2}
            {this.state.loadCompaniesResult3}
            {this.state.loadCompaniesResult4}
            {this.state.loadCompaniesResult5}
            {this.state.loadCompaniesResult6}
            {this.state.loadCompaniesResult7}
            {this.state.loadCompaniesResult8}
            {this.state.loadCompaniesResult9}

            {this.state.loadCompaniesResult9 == null && this.state.loadCompaniesHasNext && this.state.lastAdditions != true? (
                <div className="col-12">
                    <div className="d-flex justify-content-center mt-3">
                        <Button onClick={() => { this.loadMoreCompanies() }}
                                variant="primary"><i className="fas fa-plus"></i> Cargar más Empresas!</Button>

                    </div>
                </div>
            ) : ""}
        </>
    }
}

export default Enterprises

function Image(props) {
    const data = props;
    var path = data.value;
    if (path == null) {
        path = process.env.PUBLIC_URL + "/img/default-enterprise.png"
    }
    return (
        <img className="img-responsive enterprise-image" alt="enterprise" src={path}></img>
    )
}

function Rate(props) {
    const data = props;
    return (
        data.value ? (
            <Rater rating={data.value} total={5} interactive={false}/>
        ) : ""
    )
}

function Phone(props) {
    const data = props;
    return (
        data.value ? (
            <><i className="fa fa-phone"></i> &nbsp;{data.value}</>
        ) : ""
    )
}

function Coupon(props) {
    const data = props;
    return (
        data.value ? (
            <>
                <span style={{color: "#ed5441"}} className={"d-lg-none"}><i className="fa fa-ticket-alt"></i> &nbsp;Cupón de descuento!</span>
                <span style={{color: "#ed5441"}} className={"d-none d-lg-block"}><i className="fa fa-ticket-alt "></i> &nbsp;Descuento!</span>
            </>
        ) : ""
    )
}

function City(props) {
    const data = props;
    return (
        data.value ? (
            <><i className="fa fa-city"></i> &nbsp;{data.value}</>
        ) : ""
    )
}

function CatIcon(props) {
    const data = props;
    return (
        data.category_icon_image ? (
                <img className="img-responsive" alt="icon_image" src={data.category_icon_image}></img>
            ) :
            data.category_icon ? (<i className={`category-fa-icon bg-lblue ${data.category_icon}`}></i>) : (<>ssss</>)
    )
}