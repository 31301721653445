import React from "react"

import 'react-image-crop/dist/ReactCrop.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FileUploadCrop from "./file_upload-crop";

function ModalFileUpload(props) {
    return (
        <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" dialogClassName={"modal-xl"}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h6>Editor imagen</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <FileUploadCrop serviceimagedestination={props.serviceimagedestination} notifyimageuploadresult={props.notifyimageuploadresult}/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            imageSrc: props.imageSrc,
            serviceimagedestination: props.serviceimagedestination,
        };
        this.setModalShow = this.setModalShow.bind(this)
        this.notifyimageuploadresult = this.notifyimageuploadresult.bind(this)
        this.clearImage  = this.clearImage.bind(this)
    }

    notifyimageuploadresult = (value) => {
        this.setState({
            imageSrc: value,
        })
        this.setModalShow(false)
        this.props.notifyimageuploadresultform(this.props.imageFormId , value);
    }

    clearImage = (value) => {
        this.props.notifyimageuploadresultform(this.props.imageFormId , "");
        this.setState({
            imageSrc: "",
        })
    }

    setModalShow(value) {
        this.setState({modalShow: value});
    }

    render() {
        return (
            <>
                <div>
                    <div className="col-md-12" >
                        {this.state.imageSrc && (
                            <img className={"img-thumbnail"} alt="image" style={{ maxWidth: "100%"}} src={this.state.imageSrc} />
                        )}
                        {!  this.state.imageSrc && (
                            <img className={"w-50"} src={"/img/edit-image.png"} />
                        )}

                    </div>
                    <div className="container">
                        <div className="btn-wrapper">
                            <Button onClick={() => this.setModalShow(true)} className="btn btn-primary btn-sm">
                                {this.state.imageSrc && (

                                    <><i className="fas fa-edit"></i> Editar</>
                                )}
                                {!  this.state.imageSrc && (
                                    <><i className="fas fa-plus-square"></i> Agregar</>
                                )}
                            </Button>

                            {this.state.imageSrc && (
                                <Button onClick={() => this.clearImage()} className="btn btn-primary btn-sm ml-1 btn-secondary">
                                        Limpiar
                                </Button>
                            )}

                        </div>
                    </div>
                </div>
                <ModalFileUpload show={this.state.modalShow}
                                 onHide={() => this.setModalShow(false)}
                                 serviceimagedestination={this.state.serviceimagedestination}
                                 notifyimageuploadresult={this.notifyimageuploadresult} />
            </>
        );
    }
}

export default FileUploadModal
