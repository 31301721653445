/* eslint-disable no-undef */
import React from "react";
const _ = require("lodash");
const { compose, withProps, lifecycle } = require("recompose");

const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} = require("react-google-maps");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

const MapWithASearchBox = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_MAPS_KEY+"&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    lifecycle({
        componentWillMount() {
            const refs = {}
            this.setState({
                bounds: null,
                center: {
                    lat: this.props.map_lat?this.props.map_lat: -0.209101856177046, lng: this.props.map_lng?this.props.map_lng:-78.49928155225372
                },
                markers: [],
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onBoundsChanged: () => {
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();
                    const bounds = new google.maps.LatLngBounds();


                    places.forEach((place, index) => {
                        if (index>0) {
                            return
                        }
                        this.props.notify_lat(place.geometry.location.lat())
                        this.props.notify_lng(place.geometry.location.lng())
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport)
                        } else {
                            bounds.extend(place.geometry.location)
                        }
                    });
                    let nextMarkers = places.map((place, index) => ({
                        position: place.geometry.location,
                    }));

                    if (nextMarkers.length > 0) {
                        nextMarkers = [nextMarkers[0]]
                    }
                    const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                    this.setState({
                        center: nextCenter,
                        markers: nextMarkers,
                    });

                    // refs.map.fitBounds(bounds);
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={15}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
    >
        <SearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            controlPosition={google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={props.onPlacesChanged}
        >
            <input
                type="text"
                placeholder={props.search_box_text}
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid #fb743e;`,
                    width: `240px`,
                    height: `32px`,
                    marginTop: `27px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                }}
            />
        </SearchBox>


        {props.markers.map((marker, index) =>

            <Marker key={index} position={marker.position} draggable={true}
                    onDragEnd={(e) =>
                        {
                            props.notify_lat(e.latLng.lat())
                            props.notify_lng(e.latLng.lng())
                        }
                    }
            />

        )}
        {props.markers.length==0?
            <Marker key={"fnewx21"} position={props.center} draggable={true}
                    onDragEnd={(e) =>
                    {
                        props.notify_lat(e.latLng.lat())
                        props.notify_lng(e.latLng.lng())
                    }
                    }
            />
            :""}
    </GoogleMap>


);

export default MapWithASearchBox