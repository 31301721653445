import React from "react"
import { string } from "prop-types";

function loadPackages() {
  let url = process.env.PUBLIC_URL + '/v1/services/packages'
  fetch(url)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isLoaded: true,
        items: result
      });
    },
    // Note: it's important to handle errors here
    // instead of a catch() block so that we don't swallow
    // exceptions from actual bugs in components.
    (error) => {
      this.setState({
        isLoaded: true,
        error
      });
    }
  )
}

class Packages extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: string,
      };
      this.loadPackages = loadPackages.bind(this);
    }

    componentDidMount() {
      this.loadPackages()
    }

    render() {
      let result = ""
      const { error, isLoaded, items } = this.state;
      if (error) {
        result = <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        result = <div>Loading...</div>;
      } else {
        result = "Sin resultados"
        if (this.state.items.data){
          result = this.state.items.data.map(item => (
            <div key={"category-box-"+item.id} className="category-box col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{marginBottom:'0px'}}>
                <header className={"category-header clearfix " + item.color}>
                    <a href="#">
                        <div className="category-icon"><i className={item.icon}></i></div>
                        <div className="category-title text-truncate">
                            <h5>{item.name}</h5>
                        </div>
                    </a>
                </header>
                <div className="info-this package-box-info">
                    {item.description}
                    <PackageValue value={item.months_company+ " meses"} title={"Promoción de su negocio: "} />
                    {item.months && item.offers ? (
                        <PackageValue value={item.months + " meses"} title={item.offers + " de sus ofertas:"}/>
                    ) : ""}
                    <PackagePrice value={item.price} extraValue={item.currency}/>
                    <PaypalButton value={item.paypal_button} />
                </div>
                <div className="img-box-6-item category-thumb-img">
                    <div className="image-style-one">
                        <a href={process.env.PUBLIC_URL + "/offers?category="+item.id}>
                            <img src={item.image} alt={item.image} className="img-responsive" /> 
                        </a>
                    </div>
                </div>
          </div>
          ))        
        }
      }
      return <><div className="col-12">
                  <div className="block-heading-two">
                    <h3><span><i className="fa fa-tag"></i> Paquetes</span></h3>
                  </div>
                    </div>{result}
                </>

    }
}

export default Packages

function PackageValue(props) {
  const data = props;
  return (
    data.value ? (
      <div><strong>{data.title}</strong><div className="float-right mr-2">{data.value}</div></div>
    ) : ""
  )
}

function PackagePrice(props) {
  const data = props;
  return (
    data.value ? (
      <div><strong>Precio</strong><div className="float-right mr-2">{data.extraValue} {data.value}</div></div>
    ) : ""
  )
}

function PaypalButton(props) {
  const data = props;
  return (
    data.value ? (

      <div className={"text-center"} dangerouslySetInnerHTML={createMarkup(data)} />
    ) : ""
  )
}

function createMarkup(data) {
  return {__html: data.value};
}