import React from "react"
import Offers from './directory/offers'
import Enterprises from './directory/enterprises'
import Categories from "./directory/categories"
import CategoriesParent from "./directory/categories-parent"
import CategoriesParentList from "./directory/categories-parent-list"
import OfferDetail from './directory/offer-detail'
import EnterpriseDetail from './directory/enterprise-detail'
import RegistryUserLevel from './directory/registry-user-level'
import RegistryUser from './directory/registry-user'
import Profile from './directory/profile'
import LoginUser from './directory/login-user'
import LogoutUser from './directory/logout-user'
import Contact from './directory/contact'
import Report from './directory/report'
import EmailVerification from './directory/email-verification'
import Packages from './directory/packages'
import PackagesCancel from './directory/packages-cancel'
import PackagesSuccess from './directory/packages-success'
import Filers from './directory/filters'
import Enterprise from './directory/enterprise'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ForgotPassword from './directory/forgot-password'
import ResetPassword from './directory/reset-password'
import TermsConditions from './directory/terms-conditions'
import MyOffers from './directory/my-offers'
import OfferEditor from './directory/offer-editor'
import UserQualification from './directory/user-qualification'
import OffersVisited from './directory/offers-visited'
import Testimonials from './directory/testimonials'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import CouponBook from "./directory/coupon-admin";

const qs = require('query-string');

class MainContainer extends React.Component {
    render() {
        const parsed = qs.parse(window.location.search.substring(1));
        let categoryName = parsed["category_name"]

        return (
            <div className="container main-container">
                <div className="row">
                    <Router basename={process.env.REACT_APP_BASENAME}>
                        <Switch>
                            <Route path="/category">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/">Categorías</Breadcrumb.Item>
                                        <Breadcrumb.Item active>{categoryName}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Offers/>
                                <Enterprises/>
                            </Route>
                            <Route path="/offers">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item>{categoryName}</Breadcrumb.Item>

                                        <Breadcrumb.Item active>Ofertas</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Offers/>
                            </Route>
                            <Route path="/offer-detail">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Oferta</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <OfferDetail/>
                                <OffersVisited />
                            </Route>
                            <Route path="/enterprise-detail">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Empresa</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <EnterpriseDetail/>
                            </Route>

                            <Route path="/e">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Empresa</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <EnterpriseDetail/>
                            </Route>

                            <Route path="/registry">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Registro de nuevo usuario</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <RegistryUserLevel/>
                            </Route>
                            <Route path="/registry-user">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Registro Usuario</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <RegistryUser/>
                            </Route>

                            <Route path="/email-verification">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Registro</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <EmailVerification/>
                            </Route>
                            <Route path="/packages">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Paquetes</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Packages/>
                            </Route>
                            <Route path="/packages-cancel">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Paquetes</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <PackagesCancel/>
                            </Route>
                            <Route path="/packages-success">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Paquetes</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <PackagesSuccess/>
                            </Route>
                            <Route path="/profile">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Perfil</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Profile/>
                            </Route>
                            <Route path="/login-user">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Ingreso</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <LoginUser/>
                            </Route>
                            <Route path="/forgot-password">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/login-user">Ingresar</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/" active>Recuperar contraseña</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <ForgotPassword/>
                            </Route>
                            <Route path="/reset-password">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/login-user">Ingresar</Breadcrumb.Item>
                                        <Breadcrumb.Item href="/" active>Restablecer contraseña</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <ResetPassword/>
                            </Route>
                            <Route path="/logout-user">
                                <LogoutUser/>
                            </Route>
                            <Route path="/user">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Usuario registrado</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Enterprise/>
                            </Route>
                            <Route path="/contact">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Contacto</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Contact/>
                            </Route>
                            <Route path="/report">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Reportar</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <Report/>
                            </Route>
                            <Route path="/categories">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Categorías</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <CategoriesParentList/>
                            </Route>
                            <Route path="/user-qualification">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Calificación</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <UserQualification/>
                            </Route>
                            <Route path="/terms-conditions">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Términos y condiciones</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <TermsConditions/>
                            </Route>

                            <Route path="/my-offers">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Mis Ofertas</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <MyOffers/>
                            </Route>

                            <Route path="/offer-edit">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Mis Ofertas</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <OfferEditor/>
                            </Route>

                            <Route path="/crn">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item>{categoryName}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div className="col-12">
                                    <div className="alert alert-success" role="alert">
                                        Cancelacón Satisfactoria
                                    </div>
                                </div>
                            </Route>

                            <Route path="/coupons-admin">
                                <div className="col-12">
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Cupones</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <CouponBook/>
                            </Route>

                            <Route path="/">
                                <Filers
                                    callApplyFiltersOffers={(text, category, city) => this.contentOffer.applyFiltersOffers(text, category, city)}
                                    callApplyFiltersCategories={(text, category, city) => this.contentCategory.applyFiltersCategory(text, category, city)}
                                    callApplyFiltersEnterprises={(text, category, city) => this.contentEnterprise.applyFiltersEnterpries(text, category, city)}/>

                                <Offers type={2} ref={instanceOffer => {
                                    this.contentOffer = instanceOffer;
                                }}/>
                                <Enterprises ref={instanceEnterprise => {
                                    this.contentEnterprise = instanceEnterprise;

                                }}
                                             title={"Empresas por categoría"}/>

                                <Enterprises ref={contentEnterpriseLastAdditions => {
                                    this.contentEnterpriseLastAdditions = contentEnterpriseLastAdditions;
                                }}
                                             lastAdditions={true}
                                             title={"Últimas empresas registradas"}
                                />
                                <Categories ref={instanceCategory => {
                                    this.contentCategory = instanceCategory;
                                }}/>
                                <CategoriesParent ref={instanceCategory => {
                                    this.contentCategory = instanceCategory;
                                }}/>
                                <OffersVisited />
                                <Testimonials />
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </div>
        );
    }
}

export default MainContainer