import React, {useState} from "react"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "react-bootstrap/Alert";

const qs = require('query-string');

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenIsValid: false,
            userToken: ''
        };

        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let token = parsed["token"]
        if (token == undefined || token.length != 20) {
            window.location.replace(process.env.PUBLIC_URL +"/");
        } else {
            this.setState({userToken: token})
            fetch(process.env.PUBLIC_URL + "/v1/services/reset-password?token=" + token)
                .then(res => {
                        if (res.status == 200 || res.status == 201) {
                            this.setState({tokenIsValid: true})
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        alert("ERRORxx")
                    }
                )
        }
    }

    verifyCallback = (recaptchaToken) => {
        document.getElementById("actionSend").disabled = false;
    }

    render() {
        return <>
            {this.state.tokenIsValid ?
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className="block-heading-two">
                                <h3><span>Restablecer contraseña</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Formik
                                initialValues={{
                                    userPassword: '',
                                    userPasswordConfirmation: '',
                                    userToken: this.state.userToken,
                                }}
                                validationSchema={Yup.object().shape({
                                    userPassword: Yup.string().min(6, 'La contraseña debe tener almenos 6 dígitos').required('La contraseña de usuario es requerida.'),
                                    userPasswordConfirmation: Yup.string().oneOf([Yup.ref('userPassword'), null], 'Confirmación y contraseña no son iguales.').required('Confirmación de contraseña es requerida.'),
                                })}

                                render={({errors, touched}) => (
                                    <Form encType="multipart/form-data">
                                        <div className="form-row mt-3">
                                            <div className="col-md-8">
                                                <hr className="my-4"></hr>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-4">
                                                <h5><i className="fas fa-key"></i><span> Restablecer contraseña</span>
                                                </h5>
                                            </div>
                                        </div>

                                        <div className="form-row mt-3">
                                            <div className="col-md-4">
                                                <label htmlFor="userPassword">Nueva contraseña</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend"><div
                                                className="fas fa-lock prefix"></div></span>
                                                    </div>
                                                    <Field type="password" name="userPassword"
                                                           placeholder="Nueva contraseña"
                                                           aria-describedby="inputGroupPrepend"
                                                           className={'form-control' + (errors.userPassword && touched.userPassword ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="userPassword" component="div"
                                                                  className="invalid-feedback"/>
                                                    <Field type="hidden" name="userToken"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row mt-3">
                                            <div className="col-md-4">
                                                <label htmlFor="userPasswordConfirmation">Confirmación de nueva
                                                    contraseña</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend"><div
                                                className="fas fa-lock prefix"></div></span>
                                                    </div>
                                                    <Field type="password" name="userPasswordConfirmation"
                                                           placeholder="Confirmación de nueva contraseña"
                                                           aria-describedby="inputGroupPrepend"
                                                           className={'form-control' + (errors.userPasswordConfirmation && touched.userPasswordConfirmation ? ' is-invalid' : '')}/>
                                                    <ErrorMessage name="userPasswordConfirmation" component="div"
                                                                  className="invalid-feedback"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row mt-4">
                                            <div className="col-md-4 mb-3">
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_RE_CAPTCHA_KEY}
                                                    onChange={this.verifyCallback}/>
                                            </div>
                                        </div>
                                        <div className={'form-row ' + (this.state.error == false ? '' : 'd-none')}>
                                            <div className="col-md-8">
                                                <Alert variant="success">
                                                    {this.state.resultText}
                                                    <span><a href="/login-user">Ahora diríjase Ingreso de usuario</a></span>
                                                </Alert>
                                            </div>
                                        </div>
                                        <div className={'form-row ' + (this.state.error == true ? '' : 'd-none')}>
                                            <div className="col-md-8">
                                                <Alert variant="danger">
                                                    {this.state.resultText}
                                                </Alert>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3">
                                                <button disabled id="actionSend" type="submit"
                                                        className="btn btn-primary mr-2">Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                                onSubmit={
                                    fields => {
                                        fetch(process.env.PUBLIC_URL + '/v1/services/reset-password', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            },
                                            body: JSON.stringify(fields, null, 4),
                                        })
                                            .then(res => {
                                                    if (res.status < 400) {
                                                        this.setState({
                                                            error: false,
                                                            resultText: "Cambio de contraseña exitosa. "
                                                        })
                                                    } else {
                                                        this.setState({
                                                            error: true,
                                                            resultText: "Se genero un error, por favor verifique los " +
                                                                "datos ingresados."
                                                        })
                                                    }
                                                },


                                                (error) => {
                                                    this.setState({
                                                        error: true,
                                                        resultText: "Error de conexión"
                                                    })
                                                }
                                            )
                                    }
                                }
                            />
                        </div>
                    </div>
                </div> : ""}
        </>
    }
}


export default ResetPassword