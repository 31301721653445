import React from "react"
import { string } from "prop-types";
import Carousel from 'react-bootstrap/Carousel'

function loadSliders() {
  let url = process.env.PUBLIC_URL + '/v1/services/sliders'
  fetch(url)
  .then(res => res.json())
  .then(
    (result) => {
      this.setState({
        isLoaded: true,
        items: result
      });
    },
    
    (error) => {
      this.setState({
        isLoaded: true,
        error
      });
    }
  )
}

class SliderMain extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: string
      };

      this.loadSliders = loadSliders.bind(this);
    }

    componentDidMount() {
      this.loadSliders()
    }

    render() {
      //var pagesEnables = ["/registry-user", "/"];
      var pagesEnables = ["/"];
 
      if (!pagesEnables.includes(window.location.pathname)) {
        return ""
      }
      let result = ""
      const { error, isLoaded, items } = this.state;
      if (error) {
        result = <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        result = <div>Loading...</div>;
      } else {
        result = "Sin resultados"
        if (items.data){
          result = this.state.items.data.map(item => (
            
                <Carousel.Item key={"slider-box-"+item.id} >
                    <a href={item.url}>
                      <img                    
                        className="d-block w-100  float-left"
                        src={item.image_1}
                        alt={item.title}
                        style={{maxWidth: '550px'}}
                      />

                        <img
                            className="w-100  float-left d-none d-xl-block"
                            src={item.image_2}
                            alt={item.title}
                            style={{maxWidth: '550px'}}
                        />
                    </a>
                </Carousel.Item>
          ))        
        }
      }

      return <><Carousel>{result}</Carousel></>
    }
}

/*
{item.title || item.text  ? (<Carousel.Caption>
{item.title ? (<a href={item.url}><h3>{item.title}</h3> </a>):""}
{item.text ? (<p>{item.text}</p>):""}
</Carousel.Caption>):""}*/

export default SliderMain