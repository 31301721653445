import React, { useState }  from "react"

class LogoutUser extends React.Component {
    componentDidMount() {
        fetch(process.env.PUBLIC_URL + `/v1/services/logout-user`)
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.replace(process.env.PUBLIC_URL +"/");
                },
                (error) => {
                    window.location.replace(process.env.PUBLIC_URL +"/");
                }
            )
    }

    render (){
        return <></>
    }
}
export default LogoutUser