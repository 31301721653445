import React, {useState} from "react"
import Carousel from 'react-bootstrap/Carousel'
import {string} from "prop-types";
import GoogleMapReact from 'google-map-react';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'
import Mailto from 'react-protected-mailto'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Offers from './offers'
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {CopyToClipboard} from "react-copy-to-clipboard";
import YouTube from "@u-wave/react-youtube";

const qs = require('query-string');

class EnterpriseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: string,
            enterpriseID: null,
            showQualificationModal: false,
            rateSelected: 0,
            qualificationComment: "",
            userLogged: false,
            userCompany: true,
            qualificationSended: false,
            couponAvailable: false,
        };
        this.setFacebookImage = this.setFacebookImage.bind(this)
        this.rateOnSetRate = this.rateOnSetRate.bind(this)
        this.qualificationModalShow = this.qualificationModalShow.bind(this)
        this.qualificationModalHide = this.qualificationModalHide.bind(this)
        this.handleChangeQualificationCommand = this.handleChangeQualificationCommand.bind(this)
        this.sendRate = this.sendRate.bind(this)
        this.pressSendComment = this.pressSendComment.bind(this)

    }

    setFacebookImage(url) {
        var elementMeta = document.querySelector('meta[property~="og:image"]')
        if (elementMeta) {
            elementMeta.setAttribute("content", url);
        }
    }

    rateOnSetRate({rating}) {
        this.setState({rateSelected: rating}, () => this.sendRate(true));

    }

    pressSendComment() {
        this.sendRate(false)
        this.setState({qualificationSended: true});
    }

    sendRate(showModalShow) {
        let enterpriseID = this.state.enterpriseID
        let rateSelected = this.state.rateSelected
        let qualificationComment = this.state.qualificationComment
        fetch(process.env.PUBLIC_URL + '/v1/services/qualification', {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            body: JSON.stringify({
                "rate": rateSelected,
                "entity_id": enterpriseID * 1,
                "type": "com",
                "comment": qualificationComment
            }, null, 4),
        }).then(response => {
            if (response.status < 400 && showModalShow) {
                this.qualificationModalShow(true, false)
            }
            return
        })
    }

    qualificationModalShow(showQualificationModal, qualificationSended) {
        this.setState({qualificationSended: qualificationSended});
        this.setState({showQualificationModal: showQualificationModal});
    }

    qualificationModalHide() {
        this.qualificationModalShow(false, true)
    }

    handleChangeQualificationCommand(event) {
        let fleldVal = event.target.value;
        this.setState({qualificationComment: fleldVal})
    }

    componentDidMount() {
        const parsed = qs.parse(window.location.search.substring(1));
        let enterpriseID = parsed["id"]
        this.setState({"enterpriseID": enterpriseID})
        fetch(process.env.PUBLIC_URL + `/v1/services/enterprise-detail/${enterpriseID}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        fetch(process.env.PUBLIC_URL + '/v1/services/check-user', {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                return responseData;
            })
            .then(data => {
                this.setState(
                    {"userLogged": data.is_logged == true, "userCompany": data.is_company == true},
                );
            })
            .catch(err => {
                console.log("fetch error" + err);
            });


        fetch(process.env.PUBLIC_URL + `/v1/services/coupon-available?company=${enterpriseID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
            .then(async res => {
                    if (res.status == 200) {
                        this.setState({
                            couponAvailable: true,
                        });
                    } else {
                        this.setState({
                            couponAvailable: false,
                        });
                    }
                },
                (error) => {
                    this.setState({
                        couponAvailable: false,
                    });
                }
            )


}

render()
{
    this.setFacebookImage(process.env.PUBLIC_URL + this.state.items.image_1)
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm text-center">
                    <CarouselEnterpriseDetail enterpriseDetail={this.state.items}/>
                </div>
                <div className="col-sm">
                    <EnterpriseDetailData enterpriseDetail={this.state.items}
                                          enterpriseID={this.state.enterpriseID}
                                          couponAvailable={this.state.couponAvailable}
                                          company={this.state.enterpriseID}/>
                </div>

                {this.state.userLogged == true && this.state.userCompany == false ? (
                    <div className="row w-100 100vh"
                         style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                        <div className="col-sm">
                            <p className={"rateSet"}>
                                <div className="float-right mr-2">Calificar empresa:
                                    <Rater onRate={this.rateOnSetRate} rating={0} total={5} interactive={true}/>

                                    <Modal show={this.state.showQualificationModal}
                                           onHide={this.qualificationModalHide}
                                           aria-labelledby="contained-modal-title-vcenter"
                                           dialogClassName={"modal-sm"}>
                                        <Modal.Header closeButton>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <h6>Calificación</h6>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="show-grid">
                                            {this.state.qualificationSended == false ? (
                                                    <div className="container">

                                                        <div className="row">
                                                            <div className="col-sm text-center rateSet-big">
                                                                <div className={"react-rater"}>
                                                                    <Rater onRate={this.rateOnSetRate}
                                                                           rating={this.state.rateSelected} total={5}
                                                                           interactive={true}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm text-center">
                                                                {this.state.rateSelected == 1 ? (
                                                                        <div>
                                                                            Califcación muy pobre
                                                                        </div>
                                                                    ) :
                                                                    this.state.rateSelected == 2 ? (
                                                                            <div>
                                                                                Califcación pobre
                                                                            </div>
                                                                        ) :
                                                                        this.state.rateSelected == 3 ? (
                                                                                <div>
                                                                                    Califcación aceptable
                                                                                </div>
                                                                            ) :
                                                                            this.state.rateSelected == 4 ? (
                                                                                <div>
                                                                                    Califcación buena
                                                                                </div>
                                                                            ) : this.state.rateSelected == 5 ? (
                                                                                <div>
                                                                                    Excelente
                                                                                </div>
                                                                            ) : ""

                                                                }


                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm">
                                                                <Form>
                                                                    Comentario opcional:
                                                                    <Form.Control as="textarea" rows={3} maxLength={100}
                                                                                  onChange={this.handleChangeQualificationCommand}/>
                                                                </Form>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <div className="col-sm text-center">
                                                                <Button onClick={this.pressSendComment}>Enviar
                                                                    Comentario</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) :
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            Gracias por ser parte del servicio, valoramos su
                                                            calificación.
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </p>
                        </div>
                    </div>
                ) : ""}

                {this.state.items.map_lat ? (
                    <div className="row w-100 100vh"
                         style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                        <div className="col-sm">
                            <GoogleMap map_lat={this.state.items.map_lat} map_lng={this.state.items.map_lng}
                                       draggable={false}/>
                        </div>
                    </div>
                ) : ""}

                <div className="row w-100 100vh" style={{marginRight: "0px", marginLeft: "0px", marginTop: "10px"}}>
                    <div className="col-sm">
                        <Offers/>
                    </div>
                </div>
            </div>
        </div>
    )
}
}

export default EnterpriseDetail

function CarouselEnterpriseDetail(props) {
    const data = props;

    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        setDirection(e.direction);
    };

    return (
        !data.enterpriseDetail.image_2 && !data.enterpriseDetail.video_1 ? (
            <img className="d-block w-100" src={data.enterpriseDetail.image_1} alt={data.enterpriseDetail.name}/>
        ) : (
            <Carousel activeIndex={index} direction={direction} onSelect={handleSelect}
                      interval={data.enterpriseDetail.video_1 || data.enterpriseDetail.video_2 ? 500000 : 10000}>
                {data.enterpriseDetail.video_1 &&
                <Carousel.Item>
                    <div className="embed-responsive embed-responsive-4by3">
                        <YouTube
                            id={"video1"}
                            className={"embed-responsive-item"}
                            video={getVideoID(data.enterpriseDetail.video_1)}
                            allowFullscreen={true}
                            width="100%"
                            height="100%"
                        />
                    </div>
                </Carousel.Item>
                }
                {data.enterpriseDetail.image_1 &&
                <Carousel.Item>
                    <img className="d-block w-100" src={data.enterpriseDetail.image_1}
                         alt={data.enterpriseDetail.name}/>
                </Carousel.Item>
                }
                {data.enterpriseDetail.image_2 &&
                <Carousel.Item>
                    <img className="d-block w-100" src={data.enterpriseDetail.image_2}
                         alt={data.enterpriseDetail.name}/>
                </Carousel.Item>
                }
                {data.enterpriseDetail.image_3 &&
                <Carousel.Item>
                    <img className="d-block w-100" src={data.enterpriseDetail.image_3}
                         alt={data.enterpriseDetail.name}/>
                </Carousel.Item>
                }
                {data.enterpriseDetail.image_4 &&
                <Carousel.Item>
                    <img className="d-block w-100" src={data.enterpriseDetail.image_4}
                         alt={data.enterpriseDetail.name}/>
                </Carousel.Item>
                }
                {data.enterpriseDetail.image_5 &&
                <Carousel.Item>
                    <img className="d-block w-100" src={data.enterpriseDetail.image_5}
                         alt={data.enterpriseDetail.name}/>
                </Carousel.Item>
                }
            </Carousel>
        )
    );
}

function getVideoID(path) {
    if (path == undefined || path == null) {
        return ""
    }
    var sp = path.split("v=")
    if (sp.length == 1) {
        return sp[0]
    }
    sp = sp[1].split("&")
    return sp[0]

}

function EnterpriseDetailData(props) {
    const data = props;
    return (
        <div className="info-this-detail">
            <div className="left-box w-100">
                <h4>
                    {data.enterpriseDetail.name}
                </h4>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.name} title="Empresa: " icon="fas fa-building"/>
                <EnterpriseDescription value={data.enterpriseDetail.description}/>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.category_name} title="Categoría: "
                                          icon="fa fa-tag"/>

                <EnterpriseDetailDataItem value={"EMP-" + data.enterpriseDetail.id} title="Código: "
                                          icon="fas fa-barcode-alt"/>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.address} title="Dirección: "
                                          icon="fa fa-map-marker"/>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.city_name} title="Ciudad: " icon="fa fa-city"/>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.street_view}/>
                <Phone value={data.enterpriseDetail.phone}
                       packagePremiumEnabled={data.enterpriseDetail.package_premium_enabled}/>
                <Phone value={data.enterpriseDetail.phone2}
                       packagePremiumEnabled={data.enterpriseDetail.package_premium_enabled}/>
                <Rate value={data.enterpriseDetail.rate}/>
                <Email value={data.enterpriseDetail.e_mail}/>
                <Web value={data.enterpriseDetail.web_site}/>
                <SocialNetworks facebook={data.enterpriseDetail.facebook}/>
                <EnterpriseDetailDataItem value={data.enterpriseDetail.schedule} title="Horario: " icon="fa fa-clock"/>

                <CopyLink value={process.env.PUBLIC_URL + "/enterprise-detail?id=" + data.enterpriseDetail.id}/>
                <Date value={data.enterpriseDetail.public_from_date} title="Publicado desde: "/>
                <Date value={data.enterpriseDetail.public_to_date} title="Publicado hasta: "/>
                <Coupon value={data.couponAvailable} company={data.company}/>
                <Report value={"Empresa ID: " + data.enterpriseDetail.id + ", " + data.enterpriseDetail.name}/>
                <div className="fb-share-button facebook"
                     data-href={process.env.PUBLIC_URL + "/enterprise-detail?id=" + data.enterpriseID}
                     data-layout="button_count">
                </div>
            </div>
        </div>
    )
}

function EnterpriseDetailDataItem(props) {
    const data = props;
    return (
        data.value ? (
            <div><i className={data.icon + " mr-2"}/><strong>{data.title}</strong>
                <div className="float-right mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}

function EnterpriseDescription(props) {
    const data = props;
    return (
        data.value ? (
            <div>
                <div className="mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}


function CopyLink(props) {
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa-copy mr-2"/><strong>Compartir enlace</strong>
                <div className="float-right mr-2">
                    <CopyToClipboard text={props.value}>
                        <a href="#">Copiar</a>
                    </CopyToClipboard>
                </div>
            </div>
        ) : ""
    )
}

/**
 * @return {string}
 */
function GoogleMap(props) {
    const data = props;
    const [open, setOpen] = useState(false);
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: {lat: data.map_lat, lng: data.map_lng},
            draggable: props.draggable,
            map,
            title: 'Hello World!'
        });
        return marker;
    };

    return (
        data.map_lat && data.map_lng ? (
            <>
                <Button
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}><i className="fas fa-map-marked-alt"></i> ver mapa</Button>
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        <div style={{height: '100vh', width: '100%'}}>
                            {data.lat} {data.lng}
                            {<GoogleMapReact
                                bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY}}
                                defaultCenter={{lat: data.map_lat, lng: data.map_lng}}
                                center={{lat: data.map_lat, lng: data.map_lng}}
                                defaultZoom={14}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}>
                            </GoogleMapReact>
                            }
                        </div>
                    </div>
                </Collapse>
            </>
        ) : ""
    )
}

function Rate(props) {
    const data = props;
    return (
        data.value ? (
            <p><i className="fab fa-angellist mr-2"></i><strong>Puntaje: </strong>
                <div className="float-right mr-2"><Rater rating={data.value} total={5} interactive={false}/></div>
            </p>
        ) : ""
    )
}

function Email(props) {
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa fa-envelope mr-2"/><strong>E-mail: </strong>
                <div className="float-right mr-2"><Mailto email={data.value}
                                                          headers={{subject: 'Question from laguiamundial.com'}}/></div>
            </div>
        ) : ""
    )
}

function Phone(props) {
    const data = props;
    return (
        data.value && data.value.startsWith("+5939") && data.value.length == 13 && data.packagePremiumEnabled ? (
            <div><i className="fa fa-phone mr-2"></i><strong>Teléfono:</strong>
                <div className="float-right mr-2"><a target="_blank"
                                                     href={"https://api.whatsapp.com/send?phone=" + data.value + "&text=hola%20la%20laguiamundial.com"}>{data.value}</a>
                </div>
            </div>
        ) : data.value ? (
            <div><i className="fa fa-phone mr-2"></i><strong>Teléfono:</strong>
                <div className="float-right mr-2">{data.value}</div>
            </div>
        ) : ""
    )
}

function Web(props) {
    const data = props;
    return (
        data.value ? (
            <div><i className="fa fa-link mr-2"/><strong>Web: </strong>
                <div className="float-right mr-2"><a href={data.value} target="_blank">{data.value}</a></div>
            </div>
        ) : ""
    )
}

function Report(props) {
    const data = props;
    return (
        data.value ? (
            <div><i className="fas fa-siren mr-2"/><strong>Denunciar: </strong>
                <div className="float-right mr-2"><a href={"/report?name=" + data.value} target="_blank">...</a></div>
            </div>
        ) : ""
    )
}

function Coupon(props) {
    const data = props;
    return (
        //<i class="fad fa-ticket"></i>
        data.value == true ? (
                <div style={{color: "#ed5441"}} className={"blink"}><i className="fas fa-ticket-alt mr-2"/><strong>Cupón de descuento: </strong>
                    <div className="float-right mr-2"><a href={"/v1/services/coupon?company="+data.company} target="_blank">Obtener</a></div>
                </div>
        ) : ""
    )
}

function SocialNetworks(props) {
    const data = props;
    return (
        data.facebook ? (
            <div><i className="fa fa-users mr-2"/><strong>Redes sociales: </strong>
                <div className="float-right mr-2"><a className="facebook" target="_blank" href={data.facebook}><i
                    className="fab fa-facebook-f"></i></a></div>
            </div>
        ) : ""
    )
}

function Date(props) {
    const data = props;
    return (
        data.value && data.value != '0000-00-00 00:00:00' ? (
            <div>
                <div className="fa fa-calendar mr-2"/>
                <strong>{data.title}</strong>
                <div className="float-right mr-2">{getParsedDate(data.value)}</div>
            </div>
        ) : ""
    )
}

function getParsedDate(date) {
    date = String(date).split(' ');
    var days = String(date[0]).split('-');
    var hours = String(date[1]).split(':');
    return days[2] + "-" + days[1] + "-" + days[0] + " " + hours[0] + ":" + hours[1] + ":" + hours[2]
}