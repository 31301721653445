import React from "react"

class PackagesCancel extends React.Component {
    render() {
      return <><div className="col-12">
                  <div className="block-heading-two">
                    <h3><span><i className="fa fa-tag"></i> Cancelación</span></h3>
                    <div>El proceso de compra que paquete fue cancelado.</div>
                  </div>
            </div></>

    }
}

export default PackagesCancel