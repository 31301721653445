import React, {useState} from "react"
import {Field, ErrorMessage} from 'formik';
import CitiesFilter from "./cities-filter"
import CategoriesFilter from "./categories-filter";
import GoogleMapReact from "google-map-react";
import MapWithASearchBox from "./map-with-search-box";
import QRCode from "qrcode.react";
import YouTube from "@u-wave/react-youtube";
import FileUploadPreview from "./file-upload-preview";
import {CopyToClipboard} from "react-copy-to-clipboard";

class FieldsEnterprise extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeCity = this.onChangeCity.bind(this)
        this.onChangeCategory = this.onChangeCategory.bind(this)
        this.notifyimageuploadresultform = this.notifyimageuploadresultform.bind(this)
        this.notifymaplng = this.notifymaplng.bind(this)
        this.notifymaplat = this.notifymaplat.bind(this)

        this.state = {
            name: props.name,
            city: props.city,
            category: props.category,
            video1: props.video_1,
            image1: props.image1,
            map_lng: props.map_lng,
            map_lat: props.map_lat,
            tags: props.tags,
            localCategory: props.category,
            packagePremiumEnabled: props.packagePremiumEnabled,
            alias: props.slug,
        };
        this.props.setFieldValue('video_1', props.video_1);

    }

    onChangeCity(city) {
        this.props.setFieldValue('city', city.value);
    }

    onChangeCategory(category) {
        this.props.setFieldValue('category', category.value);
        this.setState({localCategory: category.value});
    }

    notifyimageuploadresultform = (imageFormId, value) => {
        this.props.setFieldValue(imageFormId, value);
    }

    notifymaplng = (value) => {
        this.props.setFieldValue('map_lng', value);
    }

    notifymaplat = (value) => {
        this.props.setFieldValue('map_lat', value);
    }

    handleChange = (e) => {
        this.props.setFieldValue('video_1', e.target.value);
        this.setState({video1: e.target.value});
    }

    handleBlurName = (e) => {
        if (this.state.alias == "" || this.state.alias == undefined) {
            let alias = this.clearString(e.target.value)
            this.setState({"alias": alias})
            this.props.setFieldValue('slug', alias);
        }
    }

    handleChangeSlug = (e) => {
        let alias = this.clearString(e.target.value)
        this.setState({"alias": alias})
        this.props.setFieldValue('slug', alias);
    }

    handleFocusSlug = (e) => {
        console.log(e.target.value)
        /*let alias = this.clearString(e.target.value)
        this.setState({"alias": alias})
        this.props.setFieldValue('slug', alias);*/
    }

    generateSlug = (value) => {
        //var cslug = this.state.name
        console.log(value)

        //this.props.setFieldValue('cslug', this.clearString(cslug));
    }

    clearString = (value) => {
        return value.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
    }

    render() {
        return <>
            <div className="form-row">
                <div className="col-md-6">
                    <label htmlFor="name">Nombre de la empresa</label>
                    <Field name="name" type="text" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           onBlur={this.handleBlurName}
                           className={'form-control' + (this.props.errors.name && this.props.touched.name ? ' is-invalid' : '')}/>
                    <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                </div>

                <div className="col-md-6">
                    <label htmlFor="category">Alias (micro sitio web), acceso directo a los datos de su empresa y sus
                        ofertas</label>
                    <Field name="slug" type="text" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           onFocus={this.handleFocusSlug}
                           onChange={this.handleChangeSlug}
                           maxLength={50}
                           className={'form-control' + (this.props.errors.slug && this.props.touched.slug ? ' is-invalid' : '')}/>
                    <ErrorMessage name="slug" component="div" className="invalid-feedback"/>
                    <span>
                        Distribuya entre sus clientes: <strong>https://laguiamundial.com/{this.state.alias} </strong>
                        <CopyToClipboard text={"https://laguiamundial.com/"+this.state.alias}>
                            <a href="#"> Copiar</a>
                        </CopyToClipboard>
                    </span>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="name">RUC / CI (<small><a target={"_blank"}
                                                              href="https://www.sri.gob.ec/">sri.gob.ec</a></small>)</label>
                    <Field name="ruc" type="text" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           maxLength={13}
                           disabled
                           className={'form-control' + (this.props.errors.ruc && this.props.touched.ruc ? ' is-invalid' : '')}/>
                    <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                </div>
                <div className="col-md-6">
                    <label htmlFor="image1">Descripción de la empresa</label>
                    <Field component="textarea" id="description" name="description" rows="2" cols="50" maxLength={500}
                           className={'form-control' + (this.props.errors.message && this.props.touched.message ? ' is-invalid' : '')}/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="category">Categoría</label>
                    <CategoriesFilter onChangeCategory={this.onChangeCategory} showAll={1} selectableBase={false}
                                      title={"Sin especificar"} selectedOption={this.state.category}/>

                    {(this.state.localCategory == "" || this.state.localCategory == null) && (
                        <div className="alert alert-warning" role="alert">
                            Para que su empresa sea visible es necesario especificar una categoria.
                        </div>
                    )}
                    En caso de no encontrar su categoría, puede recomendar una nueva <a href={"/contact"}>aquí</a>.

                </div>

                <div className="col-md-6">
                    <label htmlFor="city">Ciudad</label>
                    <CitiesFilter selectedOption={this.state.city} onChangeCity={this.onChangeCity}
                                  className={'form-control' + (this.props.errors.city && this.props.touched.city ? ' is-invalid' : '')}/>
                    <ErrorMessage name="city" component="div" className="invalid-feedback"/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="address">Dirección</label>
                    <Field type="text" name="address" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           className={'form-control' + (this.props.errors.address && this.props.touched.address ? ' is-invalid' : '')}/>
                    <ErrorMessage name="companyAddress" component="div" className="invalid-feedback"/>
                </div>
                <div className="col-md-6">
                    <label htmlFor="postalCode">Código Postal</label>
                    <Field type="text" name="postal_code" placeholder=""
                           className={'form-control' + (this.props.errors.postal_code && this.props.touched.postal_code ? ' is-invalid' : '')}/>
                    <ErrorMessage name="postalCode" component="div" className="invalid-feedback"/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="e_mail">E-mail de su empresa</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                        </div>
                        <Field type="text" name="e_mail" placeholder=""
                               aria-describedby="inputGroupPrepend"
                               className={'form-control' + (this.props.errors.e_mail && this.props.touched.e_mail ? ' is-invalid' : '')}/>
                        <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="webSite">Sitio Web</label>
                    <Field type="text" name="web_site" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           className={'form-control' + (this.props.errors.web_site && this.props.touched.web_site ? ' is-invalid' : '')}/>
                    <ErrorMessage name="webSite" component="div" className="invalid-feedback"/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="phone">Teléfono</label>
                    <Field type="text" name="phone" placeholder=""
                           className={'form-control' + (this.props.errors.phone && this.props.touched.phone ? ' is-invalid' : '')}/>
                    <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                    ej: +593998588595
                </div>
                <div className="col-md-6">
                    <label htmlFor="phone2">Otro teléfono</label>
                    <Field type="text" name="phone2" placeholder=""
                           className={'form-control' + (this.props.errors.phone2 && this.props.touched.phone2 ? ' is-invalid' : '')}/>
                    <ErrorMessage name="phone2" component="div" className="invalid-feedback"/>
                    ej: +59343731700
                </div>
            </div>
            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="facebook">Facebook</label>

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend">f</span>
                        </div>
                        <Field type="text" name="facebook" placeholder=""
                               className={'form-control' + (this.props.errors.facebook && this.props.touched.facebook ? ' is-invalid' : '')}/>
                        <ErrorMessage name="facebook" component="div" className="invalid-feedback"/>
                    </div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="schedule">Horarios</label>
                    <Field type="text" name="schedule" placeholder=""
                           aria-describedby="inputGroupPrepend"
                           className={'form-control' + (this.props.errors.schedule && this.props.touched.schedule ? ' is-invalid' : '')}/>
                    <ErrorMessage name="schedule" component="div" className="invalid-feedback"/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6">
                    <label htmlFor="tags">
                        Herramientas de SEO - Tags
                        <br/><br/>
                        Los motores de búsqueda como google.com, yahoo.com pueden posicionar mejor a su empresa
                        en sus resultados. Para lograrlo, escriba palabras claves sobre su negocio separadas por comas.
                        ej: Autos, Venta de autos, venta de autos financiados.
                    </label>
                </div>
                <div className="col-md-6">
                    <label htmlFor="postalCode">Tags</label>

                    <Field component="textarea" id="tags" name="tags" rows="2" cols="50" maxLength={150}
                           className={'form-control' + (this.props.errors.tags && this.props.touched.tags ? ' is-invalid' : '')}/>
                </div>
            </div>

            <div className="form-row mt-3">
                <div className="col-md-6 border text-center">
                    <label htmlFor="image1">Imagen de la empresa</label>

                    <FileUploadPreview
                        imageFormId="image_1"
                        imageSrc={this.props.image1}
                        serviceimagedestination={process.env.PUBLIC_URL + "/v1/services/images/enterprise"}
                        notifyimageuploadresultform={this.notifyimageuploadresultform}/>

                </div>

                {this.state.packagePremiumEnabled == true && (
                    <div className="col-md-6">
                        <div className="embed-responsive embed-responsive-4by3">
                            packagePremiumEnabled: {this.state.packagePremiumEnabled}
                            <YouTube
                                className={"embed-responsive-item"}
                                video={getVideoID(this.state.video1)}
                                allowFullscreen={true}
                                width="100%"
                                height="100%"
                            />
                        </div>
                        <div className=" mt-1">
                            <div className="row">
                                <div className="col-2 mt-2">
                                    Youtube:
                                </div>
                                <div className="col-10">
                                    <Field onChange={this.handleChange} value={this.state.video1} name="video_1"
                                           type="text" className={'form-control w-100'} style={{display: "inline"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
            <div className="form-row mt-3">
                <div className="col-md-6 ">
                    <div className="form-row">
                        <div className="col-md-6">
                            <label htmlFor="qrode">
                                QR de su empresa
                                <br/><br/>
                                Puede descargar e imprimir el siguiente QR en sus publicaciones de revistas, tarjetas
                                personales, folletería, etc.
                                <br/>Los usuarios podrán escanear este código desde sus celulares y acceder directamente
                                a los datos de su empresa, junto con los detalles de sus ofertas!!</label>
                        </div>
                        <div className="col-md-6 text-center">

                            <div>
                                <QRCode
                                    id="mi_empresa_en_qr"
                                    value={process.env.PUBLIC_URL + "/enterprise-detail?id=" + this.props.enterpriseID}
                                    size={290}
                                    level={"L"}
                                    includeMargin={true}
                                />

                                <button onClick={downloadQR} className="btn btn-primary btn-sm btn btn-primary"><i
                                    className="fas fa-download"></i> Descargar QR
                                </button>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="col-md border text-center">
                    <MapWithASearchBox
                        notify_lng={this.notifymaplng}
                        notify_lat={this.notifymaplat}
                        map_lng={this.state.map_lng}
                        map_lat={this.state.map_lat}
                        search_box_text={"Búsqueda de punto greográfico"}/>
                </div>
            </div>





       </>
    }
}

function getVideoID(path) {
    if (path == undefined || path == null) {
        return ""
    }
    var sp = path.split("v=")
    if (sp.length == 1) {
        return sp[0]
    }
    sp = sp[1].split("&")
    return sp[0]

}

function getQueryVariable(path, variable) {
    if (path == undefined) {
        return ""
    }
    var query = path;
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

function GoogleMap(props) {
    const data = props;
    const [open, setOpen] = useState(false);
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: {lat: data.map_lat, lng: data.map_lng},
            draggable: props.draggable,
            map,
            title: 'Ubicación de la empresa'
        });

        maps.event.addListener(marker, 'dragend', function () {
            data.notifymaplat(this.getPosition().lat())
            data.notifymaplng(this.getPosition().lng())
        });

        return marker;
    };

    let map_lat = -0.209101856177046
    let map_lng = -78.49928155225372
    if (data.map_lat) {
        map_lat = data.map_lat
        map_lng = data.map_lng
    }

    return (
        map_lat && map_lng ? (
            <GoogleMapReact
                bootstrapURLKeys={{key: process.env.REACT_APP_MAPS_KEY}}
                defaultCenter={{lat: map_lat, lng: map_lng}}
                center={{lat: map_lat, lng: map_lng}}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}>
            </GoogleMapReact>
        ) : ""
    )
}

const downloadQR = () => {
    const canvas = document.getElementById("mi_empresa_en_qr");
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "mi_empresa_en_qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export default FieldsEnterprise